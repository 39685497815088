// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { Title, Text } from 'shared/typography';
import { PrimaryButton, ColumnedFlex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

const Wrapper = styled.div`
  margin-bottom: 300px;

  @media ${devices.mobile} {
    margin-bottom: 80px;
  }
`;

const Heading = styled(Title)`
  margin-bottom: 35px;
  line-height: 1;
  cursor: pointer;

  @media ${devices.mobile} {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 23px;
  }
`;

const TextBlock = styled(ColumnedFlex)`
  margin-bottom: 45px;
  max-width: 555px;
  width: 100%;

  @media ${devices.mobile} {
    margin-bottom: 40px;
    max-width: 290px;
  }
`;

const StyledText = styled(Text)`
  :not(:last-of-type) {
    margin-bottom: 30px;
  }
`;

const JoinButton = styled(PrimaryButton)`
  @media ${devices.mobile} {
    padding: 15px 16px;
    font-size: 10px;
    line-height: 13px;
  }
`;

const PrivateProviderNetwork = (): JSX.Element => (
  <Wrapper>
    <Heading>What is Robyn Provider Community?</Heading>
    <TextBlock>
      <StyledText>
        The goal of this private community is to connect you with other providers who specialize in fertility through
        parenthood.
      </StyledText>
      <StyledText>
        While the Robyn platform will continue to be a megaphone for your practice, we hope this online private
        community will inspire collaboration, idea sharing, and support amongst the provider community.
      </StyledText>
      <StyledText>To access the Robyn provider community:</StyledText>
    </TextBlock>
    <JoinButton
      as="a"
      href="https://www.facebook.com/groups/1389322598106054"
      target="_blank"
      rel="noopener noreferrer"
      size="small"
    >
      LOG IN OR JOIN
    </JoinButton>
  </Wrapper>
);

export default PrivateProviderNetwork;
