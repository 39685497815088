// libraries
import React from 'react';

// custom components
import ClientDashboardLayout from 'pages/ClientDashboard/ClientDashboardLayout';
import Settings from 'modules/Dashboard/ClientDashboard/Settings';

const SettingsPage = (): JSX.Element => (
  <ClientDashboardLayout>
    <Settings />
  </ClientDashboardLayout>
);

export default SettingsPage;
