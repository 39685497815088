// libraries
import React from 'react';
import styled from 'styled-components';
import { FormRenderProps } from 'react-final-form';
import { Link as ReactRouterLink } from 'react-router-dom';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';

// styled components
import { ColumnedFlex, SubmitButton, Error, Flex, Label } from 'shared/styledComponents';
import { TextStyles } from 'shared/styles';
import { Text } from 'shared/typography';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const FormContentWrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const InputsWrapper = styled(ColumnedFlex)`
  width: 100%;
  max-width: 275px;
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  margin-bottom: 10px;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
`;

const StyledEmailInput = styled(StyledInput)`
  cursor: not-allowed;
`;

const CheckboxWrapper = styled(Flex)`
  align-items: flex-start;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const CheckboxLabel = styled(Label)`
  font-size: 10px;
  line-height: 1.4;
  margin-left: 30px;
`;

const StyledLink = styled(Text)`
  font-size: 10px;
  line-height: 1;
  text-decoration: underline;
`;

const NextButton = styled(SubmitButton)`
  padding: 19px 27px;
`;

interface CreateProviderAccountFormProps {
  initialValues: any;
  onSubmit: (values: any) => Promise<void>;
  formValidation: (values: any) => any;
}

const CreateProviderAccountForm = ({
  onSubmit,
  initialValues,
  formValidation,
}: CreateProviderAccountFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} initialValues={initialValues} validate={formValidation}>
    {({ submitting, submitFailed, submitErrors, hasValidationErrors, errors }: FormRenderProps): JSX.Element => (
      <FormContentWrapper>
        <InputsWrapper>
          <StyledEmailInput name="email" type="email" placeholder="Email" size="small" disabled />
          <StyledInput name="password" type="password" placeholder="Create a Password" size="small" />
          <StyledInput name="confirmPassword" type="password" placeholder="Confirm Password" size="small" />
          <CheckboxWrapper>
            <Checkbox name="termsAccepted" />
            <CheckboxLabel>
              I have read and agree to the&nbsp;
              <StyledLink as={ReactRouterLink} to="/privacy-policy" size="small" target="_blank">
                Privacy Policy
              </StyledLink>
              ,&nbsp;
              <StyledLink as={ReactRouterLink} to="/terms-of-use" size="small" target="_blank">
                Terms of Use
              </StyledLink>
              ,&nbsp;and&nbsp;
              <StyledLink as={ReactRouterLink} to="/provider-agreement" size="small" target="_blank">
                Provider Agreement.
              </StyledLink>
              <br />I acknowledge the Provider Agreement contains binding arbitration, class action waiver and choice of
              law provisions.
            </CheckboxLabel>
          </CheckboxWrapper>
        </InputsWrapper>
        {submitFailed && hasValidationErrors && (
          <Error size="small">
            {!!Object.keys(errors).length &&
              `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
          </Error>
        )}
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <NextButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
          Next
        </NextButton>
      </FormContentWrapper>
    )}
  </Form>
);
export default CreateProviderAccountForm;
