export const DISTANCE_OPTIONS = [
  {
    label: 'within 2 miles',
    value: 2,
  },
  {
    label: 'within 5 miles',
    value: 5,
  },
  {
    label: 'within 10 miles',
    value: 10,
  },
  {
    label: 'within 30 miles',
    value: 30,
  },
  {
    label: 'within 50 miles',
    value: 50,
  },
  {
    label: 'within 100 miles',
    value: 100,
  },
];

export const DEFAULT_ZOOM_LEVEL = 10;

export const PROVIDERS_AMOUNT = 10;
