// libraries
import { Dispatch } from 'redux';

// api
import api from './api';

// actions
import { providerCriteriaRequest, providerCriteriaSuccesed, providerCriteriaFailed } from './actions';

const providerCriteriaThunk = () => async (dispatch: Dispatch): Promise<void> => {
  dispatch(providerCriteriaRequest());

  try {
    const criteria = await api.getProviderCriteria();

    dispatch(providerCriteriaSuccesed(criteria));
  } catch (err) {
    dispatch(providerCriteriaFailed());
  }
};

export default providerCriteriaThunk;
