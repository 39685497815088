// libraries
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import styled from 'styled-components';

// styled components
import { Title } from 'shared/typography';
import { SubmitButton, Error, ColumnedFlex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';
import { TextStyles } from 'shared/styles';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import ChangePasswordSuccess from './ChangePasswordSuccess';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const ChangePasswordWrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: fit-content;
  padding: 19px 30px;

  @media ${devices.mobile} {
    margin-bottom: 0;
    padding: 16px 21px;
  }
`;

const Heading = styled(Title)`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const InputsWrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  max-width: 275px;
  width: 100%;
  margin-bottom: 30px;
  padding: 13px 15px;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
`;

const FormContentWrapper = styled(ColumnedFlex)`
  justify-content: center;
  align-items: flex-start;
  width: 100%;
`;

interface ChangePasswordProps {
  formValidation: (values: any) => void;
  onSubmit: any;
}

const ChangePassword = ({ onSubmit, formValidation }: ChangePasswordProps): JSX.Element => (
  <ChangePasswordWrapper>
    <Heading>Change Password</Heading>
    <Form onSubmit={onSubmit} validate={formValidation}>
      {({
        form: { reset },
        submitting,
        submitSucceeded,
        submitFailed,
        submitErrors,
        hasValidationErrors,
        errors,
      }: FormRenderProps): JSX.Element => (
        <FormContentWrapper>
          {submitSucceeded ? (
            <ChangePasswordSuccess onClose={reset} />
          ) : (
            <>
              <InputsWrapper>
                <StyledInput name="oldPassword" type="password" placeholder="Old Password" size="small" />
                <StyledInput name="password" type="password" placeholder="New Password" size="small" />
                <StyledInput name="confirmPassword" type="password" placeholder="Confirmation Password" size="small" />
              </InputsWrapper>
              {submitFailed && hasValidationErrors && (
                <Error size="small">
                  {!!Object.keys(errors).length &&
                    `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
                </Error>
              )}
              {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
              <StyledSubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
                update
              </StyledSubmitButton>
            </>
          )}
        </FormContentWrapper>
      )}
    </Form>
  </ChangePasswordWrapper>
);

export default ChangePassword;
