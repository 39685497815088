// libraries
import React, { FC } from 'react';
import styled from 'styled-components';

// custom components
import Icon from '../../components/Icon';

// hooks
import useModal from 'hooks/useModal';

// assets
import parentbirthDesktop from 'assets/images/parentbirth-popup.png';

// styles
import { H2, Text } from 'shared/typography';
import { PrimaryButton } from 'shared/styledComponents';
import devices from 'theme/devices';

const PopupContainer = styled.div`
  width: 650px;
  height: 300px;
  background-color: #f9f2e8;
  position: relative;
  @media ${devices.mobile} {
    height: auto;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const ContentContainer = styled.div`
  display: flex;
  height: 100%;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
  }
`;

const Image = styled.img`
  width: 40%;
  height: 100%;
  object-fit: contain;
  object-position: bottom;
  @media ${devices.mobile} {
    width: 100%;
  }
`;

const Content = styled.div`
  padding: 40px 20px 20px 20px;
  @media ${devices.mobile} {
    padding: 40px 20px 40px 20px;
  }
`;

const PopupTitle = styled(H2)`
  font-size: 26px;
`;

const PopupText = styled(Text)`
  margin-bottom: 20px;
`;

const ParentbirthPopup: FC = () => {
  const { handleModalClose } = useModal();

  const onClose = (): void => {
    window.localStorage.setItem('hasPromoModalBeenShown', 'true');
    handleModalClose();
  };

  return (
    <PopupContainer>
      <IconContainer>
        <Icon type="close" color="#516E85" onClick={onClose} />
      </IconContainer>
      <ContentContainer>
        <Image src={parentbirthDesktop} />
        <Content>
          <PopupTitle>Your Virtual Doula has arrived!</PopupTitle>
          <PopupText>
            Expecting & new parents can now text with experts whenever you want, and as often as you want, directly from
            your phone.
          </PopupText>
          <a href="https://www.parentbirth.com/" target="_blank">
            <PrimaryButton>LEARN MORE</PrimaryButton>
          </a>
        </Content>
      </ContentContainer>
    </PopupContainer>
  );
};

export default ParentbirthPopup;
