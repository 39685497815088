// libraries
import React from 'react';

// custom components
import ClientDashboardLayout from 'pages/ClientDashboard/ClientDashboardLayout';
import PersonalDetails from 'modules/Dashboard/ClientDashboard/PersonalDetails';

const PersonalDetailsPage = (): JSX.Element => (
  <ClientDashboardLayout>
    <PersonalDetails />
  </ClientDashboardLayout>
);

export default PersonalDetailsPage;
