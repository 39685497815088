// types
import { BaseTimeOption } from './types';

export const TIME_OPTIONS: BaseTimeOption[] = [
  { value: 0, label: '12:00 AM' },
  { value: 1, label: '1:00 AM' },
  { value: 2, label: '2:00 AM' },
  { value: 3, label: '3:00 AM' },
  { value: 4, label: '4:00 AM' },
  { value: 5, label: '5:00 AM' },
  { value: 6, label: '6:00 AM' },
  { value: 7, label: '7:00 AM' },
  { value: 8, label: '8:00 AM' },
  { value: 9, label: '9:00 AM' },
  { value: 10, label: '10:00 AM' },
  { value: 11, label: '11:00 AM' },
  { value: 12, label: '12:00 PM' },
  { value: 13, label: '1:00 PM' },
  { value: 14, label: '2:00 PM' },
  { value: 15, label: '3:00 PM' },
  { value: 16, label: '4:00 PM' },
  { value: 17, label: '5:00 PM' },
  { value: 18, label: '6:00 PM' },
  { value: 19, label: '7:00 PM' },
  { value: 20, label: '8:00 PM' },
  { value: 21, label: '9:00 PM' },
  { value: 22, label: '10:00 PM' },
  { value: 23, label: '11:00 PM' },
  { value: 24, label: '12:00 AM' },
];

export const timeSlotSelectTypes = Object.freeze({
  FROM: 'From',
  TO: 'To',
});

export const TIME_ZONE_OPTIONS = [
  { value: 'US/Hawaii', label: 'Hawaii Standard Time' },
  { value: 'US/Alaska', label: 'Alaska Standard Time' },
  { value: 'US/Pacific', label: 'Pacific Standard Time' },
  { value: 'US/Mountain', label: 'Mountain Standard Time' },
  { value: 'US/Central', label: 'Central Standard Time' },
  { value: 'US/Eastern', label: 'Eastern Standard Time' },
];
