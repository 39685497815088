// libraries
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

// custom components
import Form from 'components/Form';
import Select from 'components/Form/Select/Select';

// styled components
import { SubmitButton } from 'shared/styledComponents';
import { Error } from 'shared/styledComponents';
import Loader from 'components/Loader';

// styles
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import devices from 'theme/devices';
import { Title } from 'shared/typography';

const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.lightBrown};
`;

const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    max-width: 270px;
    width: 100%;
    border: 1px solid ${colors.alto};
    border-radius: 4px;

    @media ${devices.mobile} {
      max-width: 310px;
    }
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    padding: 0 14px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
    cursor: pointer;
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 40px;
  margin-bottom: 20px;
  padding: 19px 30px;

  @media ${devices.mobile} {
    margin-top: 45px;
    margin-bottom: 0;
    padding: 16px 21px;
  }
`;

const Heading = styled(Title)`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

interface StageOfJourneyProps {
  stageOptions: any[];
  initialValue: any;
  iPersonalDetailUpdating: boolean;
  onSubmit: (values: any) => void;
}

const StageOfJourney = ({
  stageOptions,
  onSubmit,
  initialValue,
  iPersonalDetailUpdating,
}: StageOfJourneyProps): JSX.Element => (
  <>
    <Heading>Stage of Journey</Heading>
    {iPersonalDetailUpdating ? (
      <Loader />
    ) : (
      <Form onSubmit={onSubmit} initialValues={{ journeyStage: initialValue }}>
        {({ submitting, submitFailed, submitErrors }: FormRenderProps): JSX.Element => (
          <>
            <Select
              name="journeyStage"
              options={stageOptions}
              customStyles={SelectStyles}
              dropdownIndicatorColor={colors.primary}
              placeholder={<div>Stage</div>}
            />
            <StyledSubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
              update
            </StyledSubmitButton>
            {submitFailed && <Error size="small">{submitErrors.error}</Error>}
          </>
        )}
      </Form>
    )}
  </>
);

export default StageOfJourney;
