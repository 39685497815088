// libraries
import axios from 'axios';

// store
import { store } from 'store';

// actions
import { setUserToken, clearUser } from 'modules/User/actions';
import { setModal } from 'components/Modals/actions';

const apiRequest = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
  responseType: 'json',
});

const interceptor = apiRequest.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status !== 401) {
      return Promise.reject(error);
    }

    axios.interceptors.response.eject(interceptor);

    if (store.getState().user.isLoggedIn) {
      return apiRequest
        .post('token/refresh', {
          accessToken: store.getState().user.token.accessToken,
          refreshToken: store.getState().user.token.refreshToken,
        })
        .then((response) => {
          const { accessToken, refreshToken } = response.data;

          store.dispatch(setUserToken({ accessToken, refreshToken }));

          error.config.headers['Authorization'] = `Bearer ${accessToken}`;
          return axios.request(error.response.config);
        })
        .catch((error) => {
          if (error.response.status === 403) {
            store.dispatch(clearUser());
            store.dispatch(setModal({ title: 'logInOrCreateAccount' }));
          }
          return Promise.reject(error);
        });
    }
  },
);

export default apiRequest;
