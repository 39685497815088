// libraries
import { useState, useEffect } from 'react';

// constants
import { DESKTOP_SIZE } from '../constants';

interface UseResize {
  isDesktop: boolean;
}

const useResize = (): UseResize => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > DESKTOP_SIZE);

  useEffect(() => {
    const updateSize = (): void => {
      if (window.innerWidth > DESKTOP_SIZE) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    };

    window.addEventListener('resize', updateSize);

    return (): any => window.removeEventListener('resize', updateSize);
  }, []);

  return { isDesktop };
};

export default useResize;
