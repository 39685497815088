// libraries
import api from './api';

// types
import { AxiosResponse } from 'axios';
import { OfferedProduct } from 'pages/ProviderProfile/types';

export const getProvidersProfileInfo = async (providerId: string, userToken: string): Promise<AxiosResponse<any>> =>
  await api.getProvidersProfileInfo(providerId, userToken);

export const getCredentials = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.getCredentials(userToken);

export const postProvidersProfileInfo = async (
  providerProfileInfo: any,
  productsOffered: any,
  formValues: any,
  userToken: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    id: providerProfileInfo.id,
    address: {
      address: formValues.address,
      city: formValues.city,
      state: formValues.state?.value,
      zipcode: formValues.zipcode,
    },
    biography: formValues.biography,
    facebookHandle: formValues.facebookHandle,
    linkedInHandle: formValues.linkedInHandle,
    firstName: formValues.firstName[0].toUpperCase() + formValues.firstName.slice(1),
    imageURL: undefined,
    instagramHandle: formValues.instagramHandle,
    lastName: formValues.lastName[0].toUpperCase() + formValues.lastName.slice(1),
    services: !!providerProfileInfo.services.length ? providerProfileInfo.services : undefined,
    specializations: !!providerProfileInfo.specializations.length ? providerProfileInfo.specializations : undefined,
    stages: formValues.stages
      ? formValues.stages.map((stage: any) => ({ id: stage.value, value: stage.label }))
      : undefined,
    credentials: formValues.credentials ? formValues.credentials.map((credential: any) => credential.value) : undefined,
    website: formValues.website,
    productsOffered: !!productsOffered.length
      ? productsOffered.map(({ id, productName, consultationType, sortOrder }: OfferedProduct) => ({
          id,
          productName,
          consultationType,
          sortOrder,
          pricing: {
            price: '0',
            duration: '1:00:00',
          },
        }))
      : [],
  };

  return await api.postProvidersProfileInfo(data, userToken);
};

export const convertProviderProfileInfoToViewLayer = (data: any): any => ({
  address: data.address || undefined,
  biography: data.biography || undefined,
  facebookHandle: data.facebookHandle || undefined,
  linkedInHandle: data.linkedInHandle || undefined,
  firstName: data.firstName || undefined,
  slug: data.slug || undefined,
  id: data.id || undefined,
  imageURL: data.imageURL || undefined,
  instagramHandle: data.instagramHandle || undefined,
  lastName: data.lastName || undefined,
  services: data.services ? [...data.services] : [],
  specializations: data.specializations ? [...data.specializations] : [],
  stages: data.stages ? [...data.stages] : [],
  credentials: data.credentials ? [...data.credentials] : [],
  website: data.website || undefined,
});
