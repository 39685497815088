export enum ProviderOnboardingStepTypes {
  CreateAccount,
  Hipaa,
  BaaAgreement,
  Subscription,
  Payment,
}

export interface ProviderOnboardingStatusData {
  isCoveredUnderHIPAA: boolean | undefined;
  productSubscription: {
    id: string | undefined;
    name: string | undefined;
    price: number | undefined;
    pricing: string | undefined;
  };
  signedDate: string | undefined;
}
