// libraries
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectUser } from 'modules/User/selectors';

// api
import api from './api';

// custom components
import Loader from 'components/Loader';

// modules
import PersonalDetails from './PersonalDetails';

const PersonalDetailsContainer = (): JSX.Element => {
  const [isPersonalDetailsLoading, setIsPersonalDetailsLoading] = useState(false);
  const [stageDetails, setStageDetails] = useState(null);
  const [interestDetails, setInterestDetails] = useState([]);

  const {
    userId,
    token: { accessToken },
  } = useSelector(selectUser);

  const fetchPersonalDetails = useCallback(async () => {
    setIsPersonalDetailsLoading(true);
    try {
      const fetchedPersonalDetails = await api.getPersonalDetails(userId, accessToken);

      setStageDetails(fetchedPersonalDetails.data.stage);

      setInterestDetails(fetchedPersonalDetails.data.interests || []);
    } catch (error) {
      console.log(error);
    }
    setIsPersonalDetailsLoading(false);
  }, [accessToken, userId]);

  useEffect(() => {
    fetchPersonalDetails();
  }, [fetchPersonalDetails]);

  return (
    <>
      {isPersonalDetailsLoading ? (
        <Loader />
      ) : (
        <PersonalDetails
          interests={interestDetails}
          stage={stageDetails}
          setStageDetails={setStageDetails}
          setInterestDetails={setInterestDetails}
          userId={userId}
          accessToken={accessToken}
        />
      )}
    </>
  );
};

export default PersonalDetailsContainer;
