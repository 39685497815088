// libraries
import api from '../api';

// types
import { AxiosResponse } from 'axios';

export const createStripeClientCustomer = async (
  userToken: string,
  paymentMethodId?: string,
): Promise<AxiosResponse<any>> => await api.postStripeClientCustomer(userToken, paymentMethodId);

export const payAppointment = async (
  providerId: string,
  time: string,
  timeZone: string,
  service: { id: string; value: string },
  price: string,
  paymentMethodId: string,
  coupon: string,
  userToken: string,
): Promise<void> => {
  const data = {
    providerId,
    slot: { startDate: time, timeZone, service: { id: service?.id, value: service?.value } },
    price,
    paymentMethodId,
    coupon,
  };
  await api.postPayAppointment(data, userToken);
};

export const attachNewPaymentMethod = async (paymentMethodId: string, paymentType: string, userToken: string) => {
  const data = {
    paymentMethodId,
    paymentType,
  };
  return await api.postAttachNewPaymentMethod(data, userToken);
};
