// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import AppointmentsDay from './AppointmentsDay';
import Loader from 'components/Loader';
import PassedAppointmentsDay from './PassedAppointmentsDay';

// styled components
import { ColumnedFlex } from 'shared/styledComponents';
import { Title } from 'shared/typography';

// types
import { Appointment } from '../types';
import { ConsultationTypes } from 'types';

// styles
import devices from 'theme/devices';

// constants
import { UserRoles } from 'constants/user';

const Heading = styled(Title)`
  margin-bottom: 80px;

  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;

const LoaderWrapper = styled.div`
  margin-bottom: 70px;

  @media ${devices.mobile} {
    margin-bottom: 50px;
  }
`;

const NoAppointmentsTitleTitle = styled(Title)`
  margin-bottom: 70px;

  @media ${devices.mobile} {
    margin-bottom: 50px;
  }
`;

interface UpcomingAppointmentsProps {
  isUpcomingAppointmentsLoading: boolean;
  isDesktop: boolean;
  role: any;
  upcomingAppointments: Appointment[] | undefined;
  isPassedAppointmentsLoading: boolean;
  passedAppointments: Appointment[] | undefined;
  handleModalOpen: (title: string, data: any) => void;
  onAppointmentCancel: (
    clientId: string,
    day: string,
    time: string,
    timeZone: string,
    consultationType: ConsultationTypes,
  ) => () => void;
}

const UpcomingAppointments = ({
  isUpcomingAppointmentsLoading,
  upcomingAppointments,
  isPassedAppointmentsLoading,
  passedAppointments,
  handleModalOpen,
  onAppointmentCancel,
  role,
  isDesktop,
}: UpcomingAppointmentsProps): JSX.Element => (
  <ColumnedFlex>
    {isUpcomingAppointmentsLoading ? (
      <LoaderWrapper>
        <Loader />
      </LoaderWrapper>
    ) : !upcomingAppointments ? (
      <NoAppointmentsTitleTitle>Something went wrong.</NoAppointmentsTitleTitle>
    ) : !!upcomingAppointments.length ? (
      <>
        {isDesktop && <Heading>Upcoming Appointments</Heading>}
        {upcomingAppointments.map(({ startDate, consultations }: any) => (
          <AppointmentsDay
            key={startDate}
            day={startDate}
            consultations={consultations}
            handleModalOpen={handleModalOpen}
            onAppointmentCancel={onAppointmentCancel}
            role={role}
          />
        ))}
      </>
    ) : (
      <NoAppointmentsTitleTitle>You do not have any upcoming appointments.</NoAppointmentsTitleTitle>
    )}

    {role !== UserRoles.CLIENT &&
      (isPassedAppointmentsLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : !passedAppointments ? (
        <Title>Something went wrong.</Title>
      ) : !!passedAppointments.length ? (
        <>
          {<Heading>Passed Appointments</Heading>}
          {passedAppointments.map(({ startDate, consultations }: any) => (
            <PassedAppointmentsDay key={startDate} day={startDate} consultations={consultations} role={role} />
          ))}
        </>
      ) : (
        <Title>You do not have any previous appointments.</Title>
      ))}
  </ColumnedFlex>
);

export default UpcomingAppointments;
