// libraries
import React from 'react';
import styled from 'styled-components';

// types
import { ProviderCriteria } from 'modules/ProviderCriteria/types';

// styled components
import { Title } from 'shared/typography';
import { SubmitButton, ColumnedFlex, Flex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

// custom components
import Loader from 'components/Loader';

// styles
import colors from 'theme/colors';

const StyledSubmitButton = styled(SubmitButton)`
  width: fit-content;
  margin-top: 40px;
  padding: 19px 30px;

  @media ${devices.mobile} {
    margin-bottom: 0;
    padding: 16px 21px;
  }
`;

const InterestsWrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const InterestsList = styled(Flex)`
  flex-wrap: wrap;
  max-width: 640px;
  width: 100%;
`;

const InterestItem = styled(Title)<any>(
  ({ isSelected }) => `
    margin-bottom: 15px;
    margin-right: 10px;
    padding: 12px;
    background-color: ${isSelected ? colors.bismark : colors.pampas};
    color: ${isSelected ? colors.pampas : colors.bismark};
    border-radius: 20px;
    list-style: none;
    cursor: pointer;

    :hover {
      background-color: ${isSelected ? colors.bismark : colors.pampas};
      opacity: ${isSelected ? 1 : 0.5};
      color: ${isSelected ? colors.pampas : colors.bismark};
    }
  `,
);

const Heading = styled(Title)`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

interface InterestsProps {
  services: ProviderCriteria[];
  interests: any[];
  isPersonalDetailsLoading: boolean;
  selectInterest: (selectedInterestId: string) => () => void;
  onSubmit: VoidFunction;
}

const Interests = ({
  services,
  interests,
  selectInterest,
  onSubmit,
  isPersonalDetailsLoading,
}: InterestsProps): JSX.Element => (
  <InterestsWrapper>
    {isPersonalDetailsLoading ? (
      <Loader />
    ) : (
      <>
        <Heading>Interests</Heading>
        <InterestsList as="ul">
          {!!services.length &&
            services.map((service: any) => (
              <InterestItem
                size="small"
                as="li"
                key={service.id}
                isSelected={!!interests && !!interests.length && !!interests.find(({ id }: any) => id === service.id)}
                onClick={selectInterest(service)}
              >
                {service.value}
              </InterestItem>
            ))}
        </InterestsList>
        <StyledSubmitButton onClick={onSubmit} size="small">
          update
        </StyledSubmitButton>
      </>
    )}
  </InterestsWrapper>
);

export default Interests;
