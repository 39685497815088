// libraries
import React, { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// custom components
import ManualBankAccountConnectionForm from './ManualBankAccountConnectionFormContainer';

// selectors
import { selectUser } from 'modules/User/selectors';

// api
import api from 'modules/BookingFlow/PaymentInformation/Stripe/api';
import { useSelector } from 'react-redux';

const StripePayment = (): JSX.Element | null => {
  const {
    token: { accessToken },
  } = useSelector(selectUser);

  const [stripePublishableKey, setStripePublishableKey] = useState('');

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const stripeKey = await api.getStripeKey(accessToken);

        setStripePublishableKey(stripeKey.data.publishableKey);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken]);

  let stripePromise: any;

  if (stripePublishableKey) {
    stripePromise = loadStripe(stripePublishableKey);
  }

  return stripePublishableKey ? (
    <Elements stripe={stripePromise}>
      <ManualBankAccountConnectionForm userToken={accessToken} />
    </Elements>
  ) : null;
};

export default StripePayment;
