// libraries
import { useState, useEffect } from 'react';

// services
import { getProviderPaymentInfo } from 'modules/Dashboard/ProviderDashboard/Appointments/Payments/services';

interface UseProviderPaymentInfo {
  providerPaymentInfo: any;
}

const useProviderPaymentInfo = (accessToken: string): UseProviderPaymentInfo => {
  const [providerPaymentInfo, setProviderPaymentInfo] = useState({
    stripeAccountId: undefined,
    payoutsEnabled: undefined,
    transfersEnabled: undefined,
  });

  useEffect(() => {
    (async (): Promise<any> => {
      try {
        const providerPaymentInfo = await getProviderPaymentInfo(accessToken);

        setProviderPaymentInfo(providerPaymentInfo.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken]);

  return { providerPaymentInfo };
};

export default useProviderPaymentInfo;
