// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

const getPaymentInfo = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/payment-info', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postPaymentAccount = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/provider-account',
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

const postGenerateAccountVerificationLink = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/generate-account-verification-link',
    {},
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

// const getPlaidPublicKey = (userToken: string): Promise<AxiosResponse<any>> =>
//   apiRequest.get('payment/plaid-key', {
//     headers: {
//       Authorization: `Bearer ${userToken}`,
//     },
//   });

const getPlaidLinkToken = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('payment/plaid-link-token', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const deleteBankAccount = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/delete-provider-account',
    { paymentType: 'Stripe' },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

const postBankAccount = (publicToken: string, accountId: string, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/set-bank-account',
    { publicToken, accountId },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

export default {
  getPaymentInfo,
  postPaymentAccount,
  postGenerateAccountVerificationLink,
  getPlaidLinkToken,
  deleteBankAccount,
  postBankAccount,
};
