// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { CreateAccountData } from './types';

const postCreateAccount = (data: CreateAccountData): Promise<AxiosResponse<any>> =>
  apiRequest.post(`client/create-account`, data);

export default { postCreateAccount };
