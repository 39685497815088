// libraries
import styled from 'styled-components';
import { TitleStyles } from './styles';

export const H1 = styled.h1(
  ({ theme: { colors, fonts, devices } }) => `
    font-family: ${fonts.canelaThin};
    font-size: 78px;
    font-weight: normal;
    line-height: 92px;
    color: ${colors.primary};

    @media ${devices.mobile} {
      font-size: 45px;
      line-height: 50px;
    }
  `,
);

export const H2 = styled.h2(
  ({ theme: { colors, fonts, devices } }) => `
    font-family: ${fonts.canelaThin};
    font-size: 54px;
    font-weight: normal;
    line-height: 63px;
    color: ${colors.primary};

    @media ${devices.mobile} {
      font-size: 36px;
      line-height: 42px;
    }
  `,
);
export const H3 = styled.h3(
  ({ theme: { colors, fonts, devices } }) => `
    font-family: ${fonts.canelaThin};
    font-size: 40px;
    font-weight: normal;
    line-height: 48px;
    color: ${colors.primary};

    @media ${devices.mobile} {
      font-size: 30px;
      line-height: 36px;
    }
  `,
);

export const H4 = styled.h4<any>(
  ({ theme: { colors, fonts, devices } }) => `
    font-family: ${fonts.wigrumMedium};
    font-size: 34px;
    font-weight: normal;
    color: ${colors.primary};

    @media ${devices.mobile} {
      font-size: 28px;
    }
  `,
);

export const Title = styled.p<any>`
  ${TitleStyles}
`;

export const Text = styled.p<any>(({ size, theme: { colors, fonts } }) => {
  switch (size) {
    case 'small':
      return `
        font-family: ${fonts.wigrumLight};
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: ${colors.lightBrown};
      `;
    default:
      return `
        font-family: ${fonts.wigrumLight};
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: ${colors.lightBrown};
      `;
  }
});

export const Eyebrow = styled.p(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    font-size: 12px;
    font-weight: normal;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    line-height: 14px;
    color: ${colors.lightGray};
  `,
);
