// libraries
import { Dispatch } from 'redux';

// api
import { postLogOut } from './api';

// actions
import { clearUser } from './actions';

const logOutThunk = (token: string) => async (dispatch: Dispatch): Promise<void> => {
  try {
    await postLogOut(token);

    dispatch(clearUser());
  } catch (err) {
    console.log(err);
  }
};

export default logOutThunk;
