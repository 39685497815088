// libraries
import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// hooks
import useModal from 'hooks/useModal';

// selectors
import { selectUser } from 'modules/User/selectors';

// modules
import Settings from './Settings';

// api
import api from './api';

// thunk
import logOutThunk from 'modules/User/thunk';

const SettingsContainer = (): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleModalOpen } = useModal();

  const {
    userId,
    token: { accessToken },
  } = useSelector(selectUser);

  const handleDeleteAccount = useCallback(async () => {
    try {
      await api.deleteClientAccount(userId, accessToken);

      dispatch(logOutThunk(accessToken));

      history.push('/');
    } catch (err) {
      console.log(err);
    }
  }, [userId, accessToken, dispatch, history]);

  return (
    <Settings
      accessToken={accessToken}
      handleModalOpen={handleModalOpen}
      onDeleteAccountButtonClick={handleDeleteAccount}
    />
  );
};

export default SettingsContainer;
