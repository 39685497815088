// libraries
import React from 'react';

// pages
import Subscribe from './Subscribe';

const SubscribeContainer = (): JSX.Element => {
  return (
    <>
      <Subscribe />
    </>
  );
};

export default SubscribeContainer;
