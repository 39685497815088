// libraries
import React, { useCallback, useMemo, useState } from 'react';

// custom components
import StageOfJourney from './StageOfJourney';

// api
import api from '../api';

// selectors
import useConvertedProviderCriteria from 'hooks/useConvertedProviderCriteria';

// utils
import { convertCriteriaToDataLayer } from 'utils/mappers';

interface StageOfJourneyContainerProps {
  stage: {
    id: string;
    value: string;
  } | null;
  setStageDetails: any;
  userId: string;
  accessToken: string;
}

const StageOfJourneyContainer = ({
  stage,
  setStageDetails,
  userId,
  accessToken,
}: StageOfJourneyContainerProps): JSX.Element => {
  const { stageOptions } = useConvertedProviderCriteria(true);
  const [isPersonalDetailUpdating, setIsPersonalDetailUpdating] = useState(false);

  const initialValue = useMemo(
    () =>
      stage && {
        label: stage.value,
        value: stage.id,
      },
    [stage],
  );

  const onSubmit = useCallback(
    async (values: any) => {
      setIsPersonalDetailUpdating(true);
      try {
        const convertedStage = convertCriteriaToDataLayer(values.journeyStage);

        setStageDetails(convertedStage as any);

        await api.postPersonalDetailStage(userId, convertedStage, accessToken);
      } catch (err) {
        console.log('error', err);
      }
      setIsPersonalDetailUpdating(false);
    },
    [accessToken, setStageDetails, userId],
  );

  return (
    <StageOfJourney
      stageOptions={stageOptions}
      onSubmit={onSubmit}
      initialValue={initialValue}
      iPersonalDetailUpdating={isPersonalDetailUpdating}
    />
  );
};

export default StageOfJourneyContainer;
