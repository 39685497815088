// libraries
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import qs from 'qs';

// custom components
import ResetPasswordForm from './ResetPasswordForm';

// hooks
import { useLocation } from 'react-router-dom';

// services
import { convertResetPasswordValuesToDataModel } from './services';

// api
import api from './api';

// types
import { ResetPasswordData } from './types';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

interface ResetPasswordFormContainerProps {
  handleClose: VoidFunction;
}

const ResetPasswordFormContainer = ({ handleClose }: ResetPasswordFormContainerProps): JSX.Element => {
  const location = useLocation();

  const [isTokenInfoLoading, setIsTokenInfoLoading] = useState(false);

  const [isTokenExpired, setIsTokenExpired] = useState(true);

  const { email, token } = qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c });

  const validationSchema = useMemo(
    () => ({
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_EQUALITY]: true,
      },
      confirmPassword: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_CONFIRMATION]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const getIfTokenExpired = useCallback(async () => {
    setIsTokenInfoLoading(true);
    try {
      const isTokenExpired = await api.getIfResetTokenExpired(email, token);

      setIsTokenExpired(isTokenExpired.data.result);
    } catch (err) {
      console.log(err);
    }

    setIsTokenInfoLoading(false);
  }, [email, token]);

  useEffect(() => {
    getIfTokenExpired();
  }, [getIfTokenExpired]);

  const handleSubmit = useCallback(
    async (values: ResetPasswordData) => {
      const convertedValues = convertResetPasswordValuesToDataModel(email, token, values);

      await api.postResetPassword(convertedValues);
    },
    [email, token],
  );

  return (
    <ResetPasswordForm
      onSubmit={handleSubmit}
      handleModalClose={handleClose}
      formValidation={formValidation}
      isTokenExpired={isTokenExpired}
      isTokenInfoLoading={isTokenInfoLoading}
    />
  );
};

export default ResetPasswordFormContainer;
