// libraries
import { useCallback, useRef } from 'react';

interface UseRecaptcha {
  recaptcha: any;
  handleRecaptchaExecute: VoidFunction;
}

const useRecaptcha = (): UseRecaptcha => {
  const recaptcha: any = useRef(null);

  const handleRecaptchaExecute = useCallback(() => recaptcha.current.execute(), []);

  return { recaptcha, handleRecaptchaExecute };
};

export default useRecaptcha;
