// libraries
import React, { useCallback, useMemo, Dispatch, SetStateAction } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import qs from 'qs';

// custom components
import CreateProviderAccountForm from './CreateProviderAccountForm';

// hooks
import useTerms from 'hooks/useTerms';

// actions
import { setUser } from 'modules/User/actions';

// services
import { convertAccountValuesToDataLayer } from '../services';
import { convertUserDataToViewModel } from 'modules/LogInOrCreateAccount/LogIn/services';

// api
import api from '../api';

// types
import { ProviderOnboardingStepTypes } from 'pages/ProviderOnboarding/types';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

interface CreateProviderAccountFormContainerProps {
  setOpenedStepType: Dispatch<SetStateAction<ProviderOnboardingStepTypes>>;
}

const CreateProviderAccountFormContainer = ({
  setOpenedStepType,
}: CreateProviderAccountFormContainerProps): JSX.Element => {
  const location = useLocation();
  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c });

  const dispatch = useDispatch();

  const { termsOfUse, privacyPolicy, providerAgreement } = useTerms({
    isPrivacyPolicy: true,
    isTermsOfUse: true,
    isProviderAgreement: true,
  });

  const initialValues = useMemo(() => ({ email: queryParams.email }), [queryParams.email]);

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_EQUALITY]: true,
      },
      confirmPassword: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_CONFIRMATION]: true,
      },
      termsAccepted: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const handleSubmit = useCallback(
    async (values: any) => {
      const convertedData = convertAccountValuesToDataLayer(
        queryParams,
        values,
        termsOfUse,
        privacyPolicy,
        providerAgreement,
      );

      const createdProvider = await api.postCreateProviderAccount(convertedData);

      const convertedProvider = convertUserDataToViewModel(createdProvider.data);

      dispatch(setUser(convertedProvider));

      setOpenedStepType(ProviderOnboardingStepTypes.Hipaa);
    },
    [queryParams, setOpenedStepType, dispatch, termsOfUse, privacyPolicy, providerAgreement],
  );

  return (
    <CreateProviderAccountForm onSubmit={handleSubmit} initialValues={initialValues} formValidation={formValidation} />
  );
};

export default CreateProviderAccountFormContainer;
