import { ProviderCriteria, ConvertedProviderCriteria } from 'modules/ProviderCriteria/types';

export const mapProviderCriteriaToViewLayer = (
  providerCriteria: ProviderCriteria[],
  defaultText: string,
  isMultiForm: boolean,
): ConvertedProviderCriteria[] =>
  !isMultiForm
    ? [
        { value: '', label: defaultText },
        ...providerCriteria.map((criteria: ProviderCriteria) => ({ value: criteria.id, label: criteria.value })),
      ]
    : providerCriteria.map((criteria: ProviderCriteria) => ({ value: criteria.id, label: criteria.value }));

export const convertCriteriaToDataLayer = (criteria: any) => ({ id: criteria.value, value: criteria.label });
