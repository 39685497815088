// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { CentredFlex, PrimaryButton, ColumnedFlex } from 'shared/styledComponents';
import { Text, Title } from 'shared/typography';

const Wrapper = styled(CentredFlex)`
  width: 100%;
  height: 100%;
  justify-content: flex-start;
`;

const ContentWrapper = styled(ColumnedFlex)`
  align-items: center;
`;

const Heading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
`;

const StyledTitle = styled(Text)`
  margin-bottom: 20px;
`;

const CloseButton = styled(PrimaryButton)<any>`
  background-color: ${({ theme: { colors } }): string => colors.blueBayoux};
  color: ${({ theme: { colors } }): string => colors.white};
  padding: 10px 25px;
`;

interface ChangeEmailSuccessProps {
  onClose: VoidFunction;
}

const ChangeEmailSuccess = ({ onClose }: ChangeEmailSuccessProps): JSX.Element => (
  <Wrapper>
    <ContentWrapper>
      <Heading size="small">Your email has been successfully changed</Heading>
      <StyledTitle size="small">Check your email for verification</StyledTitle>
      <CloseButton size="small" onClick={onClose}>
        ok
      </CloseButton>
    </ContentWrapper>
  </Wrapper>
);

export default ChangeEmailSuccess;
