// libraries
import moment from 'moment';

// types
import { ConsultationTypes } from 'types';
import { DataModelTimeSlot } from './types';
// import api from './api';

// export const getAvailableTime = async (providerId: string, token: string) =>
//   await api.getAvailableTime(providerId, token);

export const convertTimeSlotsToDataModel = (selectedDates: Date[], formValues: any): DataModelTimeSlot[] => {
  const formValuesKeys = Object.keys(formValues);

  const convertTimeSlots: DataModelTimeSlot[] = [];

  formValuesKeys.forEach((timeSlotKey: string) => {
    const timeSlot = formValues[timeSlotKey];

    if (timeSlot?.value.isFromRange) {
      const start = timeSlot?.value.time;
      const finish = formValues[`${timeSlot?.value.consultationType}-To-${timeSlot?.value.id}`]?.value.time;

      for (let time = start; time < finish; time++) {
        selectedDates.forEach((selectedDate: Date) => {
          const selectedDateWithTime = moment(selectedDate)
            .startOf('day')
            .add(time, 'h')
            .format('YYYY-MM-DD[T]HH:mm:ss');

          convertTimeSlots.push({
            timeZone: formValues?.timeZone ? formValues?.timeZone.value : undefined,
            startDate: selectedDateWithTime,
            range: '01:00:00',
            consultationType: ConsultationTypes[timeSlot?.value.consultationType],
          });
        });
      }
    }
  });
  return convertTimeSlots;
};
