// libraries
import React, { useEffect, useMemo } from 'react';
import { Switch, useLocation, Redirect, Route } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

// custom components
import Modal from 'components/Modals';

// selectors
import { selectModalTitle } from '../components/Modals/selectors';
import { selectUser } from 'modules/User/selectors';

// assets
import providerCriteriaThunk from 'modules/ProviderCriteria/thunk';

// constants
import { REDIRECTS } from 'constants/redirects';
import { UserRoles } from 'constants/user';

// pages
import Appointments from 'pages/ProviderDashboard/SubPages/Appointments/Availability';
import EditProfile from 'pages/ProviderDashboard/SubPages/EditProfile';
import Analytics from 'pages/ProviderDashboard/SubPages/Analytics';
import PrivateProviderNetwork from 'pages/ProviderDashboard/SubPages/PrivateProviderNetwork';
import Settings from 'pages/ProviderDashboard/SubPages/Settings';
import ProductsOffered from 'pages/ProviderDashboard/SubPages/Appointments/ProductsOffered';
import UpcomingAppointments from 'pages/ProviderDashboard/SubPages/Appointments/UpcomingAppointments';
import PaymentInfo from 'pages/ProviderDashboard/SubPages/Appointments/PaymentInfo';
import AvailabilitySuccess from 'pages/ProviderDashboard/SubPages/Appointments/AvailabilitySuccess';
import ProviderOnboarding from 'pages/ProviderOnboarding';
import PersonalDetailsContainer from 'pages/ClientDashboard/SubPages/PersonalDetails';
import ClientUpcomingAppointments from 'pages/ClientDashboard/SubPages/UpcomingAppointments';
import PaymentMethod from 'pages/ClientDashboard/SubPages/PaymentMethod';
import ClientSettings from 'pages/ClientDashboard/SubPages/Settings';
import Subscribe from 'pages/Subscribe';
const Home = React.lazy(() => import('pages/Home'));
const Login = React.lazy(() => import('pages/Login'));
const Service = React.lazy(() => import('pages/Service'));
const Booking = React.lazy(() => import('pages/Booking'));
const ProviderOnboardingComplete = React.lazy(() => import('pages/ProviderOnboardingComplete'));
const ClientOnboarding = React.lazy(() => import('pages/ClientOnboarding'));
const ProviderProfile = React.lazy(() => import('pages/ProviderProfile'));
const ForProvidersLanding = React.lazy(() => import('pages/ForProvidersLanding'));
const FindProviders = React.lazy(() => import('pages/FindProviders'));
const Article = React.lazy(() => import('pages/Articles'));
const CreateStory = React.lazy(() => import('pages/RealStory/components/CreateStory'));
const RealStory = React.lazy(() => import('pages/RealStory'));
const DmcaCopyrightPolicy = React.lazy(() => import('pages/DmcaCopyrightPolicy'));
const NonCoveredEntityCertification = React.lazy(() => import('pages/NonCoveredEntityCertification'));
const HipaaAuthorization = React.lazy(() => import('pages/HipaaAuthorization'));
const VerifyClientEmail = React.lazy(() => import('pages/VerifyClientEmail'));
const ResetPassword = React.lazy(() => import('pages/ResetPassword'));
const PrivacyPolicy = React.lazy(() => import('pages/PrivacyPolicy'));
const TermsOfUse = React.lazy(() => import('pages/TermsOfUse'));
const ProviderAgreement = React.lazy(() => import('pages/ProviderAgreement'));
const RealStories = React.lazy(() => import('pages/RealStories'));
const Classes = React.lazy(() => import('pages/Classes'));
const EducationalArticles = React.lazy(() => import('pages/EducationalArticles'));
const NotFound = React.lazy(() => import('pages/NotFound'));

const PROVIDER_URLS = [
  '/provider/dashboard/edit-profile',
  '/provider/dashboard/settings',
  '/provider/dashboard/private-provider-network',
  '/provider/dashboard/analytics',
  '/provider/dashboard/appointments/products-offered',
  '/provider/dashboard/appointments/availability',
  '/provider/dashboard/appointments/availability/complete',
  '/provider/dashboard/appointments/upcoming-appointments',
  '/provider/dashboard/appointments/payment-info',
];

const CLIENT_URLS = [
  '/client/dashboard/personal-details',
  '/client/dashboard/upcoming-appointments',
  '/client/dashboard/settings',
];

const Routes = (): JSX.Element => {
  const location = useLocation();

  const dispatch = useDispatch();

  const modal = useSelector(selectModalTitle);

  const { isLoggedIn, role } = useSelector(selectUser);

  useEffect(() => {
    dispatch(providerCriteriaThunk());
  }, [dispatch]);

  const urlFromRedirects = useMemo(
    () =>
      REDIRECTS.find((redirect: { currentURL: string; newURL: string }) => redirect.currentURL === location.pathname),
    [location.pathname],
  );

  if (urlFromRedirects?.newURL.includes('https://classes.wearerobyn.co')) {
    window.location.href = urlFromRedirects?.newURL;
  }

  return (
    <>
      <Switch>
        {urlFromRedirects && !urlFromRedirects?.newURL.includes('https://classes.wearerobyn.co') && (
          <Redirect from={urlFromRedirects?.currentURL} to={urlFromRedirects?.newURL} />
        )}
        {/* common routes */}
        <Route path="/login" component={Login} />
        <Route path="/verify-email" component={VerifyClientEmail} />
        <Route path="/reset-password" component={ResetPassword} />

        <Route path="/privacy-policy" component={PrivacyPolicy} />
        <Route path="/terms-of-use" component={TermsOfUse} />
        <Route path="/provider-agreement" component={ProviderAgreement} />
        <Route path="/hipaa-authorization" component={HipaaAuthorization} />
        <Route path="/non-covered-entity-certification" component={NonCoveredEntityCertification} />
        <Route path="/dmca-copyright-policy" component={DmcaCopyrightPolicy} />

        <Route exact path="/" component={Home} />
        <Route path="/journey/:url" component={RealStory} />
        <Route path="/share-your-journey" component={CreateStory} />
        <Route path="/learn/:url" component={Article} />
        <Route path="/for-providers" component={ForProvidersLanding} />
        <Route path="/journeys" component={RealStories} />
        <Route path="/classes" component={Classes} />
        <Route path="/learn" component={EducationalArticles} />

        <Route path="/create-provider-account/complete" component={ProviderOnboardingComplete} />
        <Route path="/create-new-password" component={ClientOnboarding} />
        <Route path="/find-providers" component={FindProviders} />
        <Route path="/maternal-specialist/:slug" component={ProviderProfile} />
        <Route path="/service/:name" component={Service} />
        <Route path="/booking" component={Booking} />

        <Route path="/subscribe" component={Subscribe} />

        {/* provider, basic, or pro routes */}
        {!isLoggedIn && PROVIDER_URLS.includes(location.pathname) && (
          <Redirect
            to={{
              pathname: '/login',
              state: { locationRedirectPath: location.pathname, locationRedirectSearch: location.search },
            }}
          />
        )}
        {(role === UserRoles.PROVIDER_APPLICANT || !isLoggedIn) && (
          <Route path="/create-provider-account" component={ProviderOnboarding} />
        )}
        {isLoggedIn && role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
          <Route path="/provider/dashboard/settings" component={Settings} />
        )}
        {isLoggedIn &&
          role === UserRoles.PROVIDER_BASIC && [
            <Route path="/provider/dashboard/edit-profile" component={EditProfile} />,
            <Route path="/provider/dashboard/settings" component={Settings} />,
            <Route path="/provider/dashboard/private-provider-network" component={PrivateProviderNetwork} />,
          ]}
        {isLoggedIn &&
          role === UserRoles.PROVIDER_PRO && [
            <Route path="/provider/dashboard/edit-profile" component={EditProfile} />,
            <Route path="/provider/dashboard/analytics" component={Analytics} />,
            <Route path="/provider/dashboard/private-provider-network" component={PrivateProviderNetwork} />,
            <Route path="/provider/dashboard/appointments/products-offered" component={ProductsOffered} />,
            <Route exact path="/provider/dashboard/appointments/availability" component={Appointments} />,
            <Route
              exact
              path="/provider/dashboard/appointments/availability/complete"
              component={AvailabilitySuccess}
            />,
            <Route path="/provider/dashboard/appointments/upcoming-appointments" component={UpcomingAppointments} />,
            <Route path="/provider/dashboard/appointments/payment-info" component={PaymentInfo} />,
            <Route path="/provider/dashboard/settings" component={Settings} />,
          ]}

        {/* client routes */}
        {!isLoggedIn && CLIENT_URLS.includes(location.pathname) && (
          <Redirect
            to={{
              pathname: '/login',
              state: { locationRedirectPath: location.pathname, locationRedirectSearch: location.search },
            }}
          />
        )}
        <Route path="/client/dashboard/personal-details" component={PersonalDetailsContainer} />
        <Route path="/client/dashboard/upcoming-appointments" component={ClientUpcomingAppointments} />
        <Route path="/client/dashboard/payment-method" component={PaymentMethod} />
        <Route path="/client/dashboard/settings" component={ClientSettings} />

        {/* not found route */}
        <Route component={NotFound} />
      </Switch>
      {modal && <Modal />}
    </>
  );
};

export default Routes;
