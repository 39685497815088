// libraries
import React, { useCallback, Dispatch, SetStateAction, useMemo } from 'react';
import { useSelector } from 'react-redux';

// custom components
import HipaaForm from './HipaaForm';

// hooks
import useTerms from 'hooks/useTerms';

// selectors
import { selectUser } from 'modules/User/selectors';

// types
import { ProviderOnboardingStepTypes } from 'pages/ProviderOnboarding/types';

// services
import { setHipaaElegibility } from './services';

interface HipaaFormContainerProps {
  setOpenedStepType: Dispatch<SetStateAction<ProviderOnboardingStepTypes>>;
  setIsHipaaStepSucceeded: Dispatch<SetStateAction<any>>;
  isCoveredUnderHIPAA: boolean | undefined;
}

const HipaaFormContainer = ({
  setOpenedStepType,
  setIsHipaaStepSucceeded,
  isCoveredUnderHIPAA,
}: HipaaFormContainerProps): JSX.Element => {
  const {
    token: { accessToken },
  } = useSelector(selectUser);

  const initialValues = useMemo(() => ({ hipaa: isCoveredUnderHIPAA || isCoveredUnderHIPAA === null ? 'yes' : 'no' }), [
    isCoveredUnderHIPAA,
  ]);

  const { nonCoveredEntityCertification } = useTerms({
    isNonCoveredEntityCertification: true,
  });

  const handleSubmit = useCallback(
    async (values) => {
      if (values.hipaa === 'no' && !values.certificationAccepted) {
        return { error: 'Non-Covered Entity Certification is required' };
      }

      await setHipaaElegibility(values, nonCoveredEntityCertification, accessToken);

      if (values.hipaa === 'yes') {
        setIsHipaaStepSucceeded(true);
        setOpenedStepType(ProviderOnboardingStepTypes.BaaAgreement);
      } else {
        setIsHipaaStepSucceeded(false);
        setOpenedStepType(ProviderOnboardingStepTypes.Subscription);
      }
    },
    [setOpenedStepType, setIsHipaaStepSucceeded, nonCoveredEntityCertification, accessToken],
  );

  return <HipaaForm onSubmit={handleSubmit} initialValues={initialValues} />;
};

export default HipaaFormContainer;
