// libraries
import React, { Dispatch, SetStateAction, useMemo } from 'react';

// custom components
import SubscriptionsForm from './SubscriptionsForm';

// hooks
import useProductSubscriptions from 'hooks/useProductSubscriptions';

// types
import { ProductSubscription } from './types';
import { ProviderOnboardingStepTypes } from 'pages/ProviderOnboarding/types';

// constants
import { DEFAULT_PROVIDER_SUBSCRIPTION_ID } from './constants';

interface SubscriptionsFormContainerProps {
  setOpenedStepType: Dispatch<SetStateAction<ProviderOnboardingStepTypes>>;
  productSubscriptionInitialId: string | undefined;
}

const SubscriptionsFormContainer = ({
  productSubscriptionInitialId,
}: SubscriptionsFormContainerProps): JSX.Element | null => {
  const { productSubscriptions } = useProductSubscriptions();

  const initialValues = useMemo(
    () => ({
      productSubscription: productSubscriptionInitialId
        ? productSubscriptionInitialId
        : !!productSubscriptions.length &&
          productSubscriptions.find(
            (subscription: ProductSubscription) => subscription.id === DEFAULT_PROVIDER_SUBSCRIPTION_ID,
          )?.id,
    }),
    [productSubscriptionInitialId, productSubscriptions],
  );

  return <SubscriptionsForm productSubscriptions={productSubscriptions} initialValues={initialValues} />;
};

export default SubscriptionsFormContainer;
