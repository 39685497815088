// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { CentredFlex, PrimaryButton, ColumnedFlex } from 'shared/styledComponents';
import { Title } from 'shared/typography';

const Wrapper = styled(CentredFlex)`
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const ContentWrapper = styled(ColumnedFlex)`
  align-items: center;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const CloseButton = styled(PrimaryButton)<any>`
  padding: 10px 25px;
  background-color: ${({ theme: { colors } }): string => colors.blueBayoux};
  color: ${({ theme: { colors } }): string => colors.white};
`;

interface ChangePasswordSuccessProps {
  onClose: VoidFunction;
}

const ChangePasswordSuccess = ({ onClose }: ChangePasswordSuccessProps): JSX.Element => (
  <Wrapper>
    <ContentWrapper>
      <StyledTitle size="small">Your password has been successfully changed</StyledTitle>
      <CloseButton size="small" onClick={onClose}>
        ok
      </CloseButton>
    </ContentWrapper>
  </Wrapper>
);

export default ChangePasswordSuccess;
