// libraries
import React, { ReactNode, Suspense } from 'react';
import { useSelector } from 'react-redux';

// selectors
import { selectUser } from 'modules/User/selectors';

// custom components
import Header from './Header';

const Footer = React.lazy(() => import('./Footer/index'));

const SignUpWithFooterContactForms = React.lazy(() => import('modules/SignUpWithFooterContactForms'));

interface LayoutProps {
  children: ReactNode;
  isContactFormShown?: boolean;
  instagramMedia?: any;
}

const Layout = ({ children, isContactFormShown = true, instagramMedia = [] }: LayoutProps): JSX.Element => {
  const { isLoggedIn } = useSelector(selectUser);

  return (
    <>
      <Header istransparent={false} />
      {children}
      {isContactFormShown && !isLoggedIn && (
        <Suspense fallback={<div>Loading...</div>}>
          <SignUpWithFooterContactForms />
        </Suspense>
      )}
      <Suspense fallback={<div>Loading...</div>}>
        <Footer isAfterForms={isContactFormShown && !isLoggedIn} instagramMedia={instagramMedia} />
      </Suspense>
    </>
  );
};

export default Layout;
