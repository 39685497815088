export enum AppointmentSectionTypes {
  ProductsOffered,
  InputAvailability,
  UpcomingAppointments,
  Payment,
}

export const typeOfAppointmentSections = {
  'products-offered': AppointmentSectionTypes.ProductsOffered,
  availability: AppointmentSectionTypes.InputAvailability,
  'upcoming-appointments': AppointmentSectionTypes.UpcomingAppointments,
  'payment-info': AppointmentSectionTypes.Payment,
};
