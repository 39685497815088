// libraies
import moment from 'moment';

// constants
import { TIME_OPTIONS } from './constants';

export const setPrevTimeSlotsByConsultationType = (
  consulationSlots: any,
  onFormChange: any,
  consultationType: string,
): void => {
  const startTime: any = [moment(consulationSlots[0].startDate).hour()];

  onFormChange(`${consultationType}-From-0`, {
    value: {
      id: 0,
      time: startTime[startTime.length - 1],
      consultationType,
      isFromRange: true,
    },
    label: TIME_OPTIONS.find(({ value }) => startTime[startTime.length - 1] === value)?.label,
  });

  let id = 0;

  consulationSlots.forEach(({ startDate }: any, index: number) => {
    const nextTime = consulationSlots[index + 1];

    if (nextTime) {
      if (moment(startDate).hour() === moment(nextTime?.startDate).hour() - 1) {
        return;
      } else {
        startTime.push(moment(nextTime.startDate).hour());

        onFormChange(`${consultationType}-From-${id + 1}`, {
          value: {
            id: id + 1,
            time: startTime[startTime.length - 1],
            consultationType,
            isFromRange: true,
          },
          label: TIME_OPTIONS.find(({ value }) => startTime[startTime.length - 1] === value)?.label,
        });

        onFormChange(`${consultationType}-To-${id}`, {
          value: {
            id,
            time: moment(startDate).hour() + 1,
            consultationType,
            isFromRange: false,
          },
          label: TIME_OPTIONS.find(({ value }) => moment(startDate).hour() + 1 === value)?.label,
        });

        id++;
      }
    }

    onFormChange(`${consultationType}-To-${id}`, {
      value: {
        id,
        time: moment(startDate).hour() + 1,
        consultationType,
        isFromRange: false,
      },
      label: TIME_OPTIONS.find(({ value }) => moment(startDate).hour() + 1 === value)?.label,
    });
  });
};

export const getNumberOfPrevTimeSlots = (consulationSlots: any): any => {
  const startTime: any = [moment(consulationSlots[0].startDate).hour()];

  consulationSlots.forEach(({ startDate }: any, index: number) => {
    const nextTime = consulationSlots[index + 1];

    if (nextTime) {
      if (moment(startDate).hour() === moment(nextTime?.startDate).hour() - 1) {
        return;
      } else {
        startTime.push(moment(nextTime.startDate).hour);
      }
    }
  });

  return startTime;
};
