// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { ColumnedFlex } from 'shared/styledComponents';

// modules
import StageOfJourney from 'modules/Dashboard/ClientDashboard/PersonalDetails/StageOfJourney';
import Interests from 'modules/Dashboard/ClientDashboard/PersonalDetails/Interests';
import EditProfile from './EditProfile';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';

const PersonalDetailsWrapper = styled(ColumnedFlex)`
  width: 100%;

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

const EditProfileWrapper = styled.div`
  width: 100%;
  padding-bottom: 70px;
  border-bottom: 1px solid ${colors.alto};

  @media ${devices.mobile} {
    padding-bottom: 45px;
  }
`;

const StageJourneyWrapper = styled.div`
  width: 100%;
  margin-top: 55px;
  padding-bottom: 70px;
  border-bottom: 1px solid ${colors.alto};

  @media ${devices.mobile} {
    margin-top: 35px;
    padding-bottom: 45px;
  }
`;

const InterestsWrapper = styled(ColumnedFlex)`
  margin-top: 55px;
  margin-bottom: 150px;

  @media ${devices.mobile} {
    margin-top: 35px;
    margin-bottom: 10px;
  }
`;

interface PersonalDetailsProps {
  stage: {
    id: string;
    value: string;
  } | null;
  interests: any[];
  setInterestDetails: any;
  setStageDetails: any;
  userId: string;
  accessToken: string;
}

const PersonalDetails = ({
  setInterestDetails,
  interests,
  stage,
  setStageDetails,
  userId,
  accessToken,
}: PersonalDetailsProps): JSX.Element => (
  <PersonalDetailsWrapper>
    <EditProfileWrapper>
      <EditProfile />
    </EditProfileWrapper>
    <StageJourneyWrapper>
      <StageOfJourney stage={stage} userId={userId} accessToken={accessToken} setStageDetails={setStageDetails} />
    </StageJourneyWrapper>
    <InterestsWrapper>
      <Interests
        interests={interests}
        userId={userId}
        accessToken={accessToken}
        setInterestDetails={setInterestDetails}
      />
    </InterestsWrapper>
  </PersonalDetailsWrapper>
);

export default PersonalDetails;
