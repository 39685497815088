// libraries
import React from 'react';
import { useSelector } from 'react-redux';

// custom components
import Modals from './Modals';

// selectors
import { selectModalTitle, selectModalData } from 'components/Modals/selectors';

// hooks
import useModal from 'hooks/useModal';

const ModalsContainer = (): JSX.Element => {
  const title = useSelector(selectModalTitle);
  const data = useSelector(selectModalData);
  const { handleModalClose } = useModal();

  const closeModalOutside = (id: string): void => {
    if (id === 'modal-wrapper') {
      handleModalClose();
      if (title === 'emailPopupModal') {
        window.localStorage.setItem('hasPromoModalBeenShown', 'true');
      }
    }
  };

  return <Modals title={title} data={data} closeModalOutside={closeModalOutside} />;
};

export default ModalsContainer;
