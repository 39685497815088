/* eslint-disable @typescript-eslint/ban-ts-ignore */

// libraries
import React, { useCallback, useMemo } from 'react';

// custom components
import CreateAccountForm from './CreateAccountForm';

// hooks
import useModal from 'hooks/useModal';
import useTerms from 'hooks/useTerms';

// services
import { convertCreateAccountValuesToDataModel } from './services';

// api
import api from './api';

// types
import { CreateAccountData } from './types';

// utils
import { validate } from 'utils/validation';
import { VALIDATION_TYPES } from 'utils/validation/types';

interface CreateAccountFormContainerProps {
  providerFirstName: string;
  providerLastName: string;
}

const CreateAccountFormContainer = ({
  providerFirstName,
  providerLastName,
}: CreateAccountFormContainerProps): JSX.Element => {
  const { handleModalClose } = useModal();

  const validationSchema = useMemo(
    () => ({
      firstName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      lastName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_EQUALITY]: true,
      },
      termsAccepted: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const { termsOfUse, privacyPolicy, hipaaAuthorization } = useTerms({
    isPrivacyPolicy: true,
    isTermsOfUse: true,
    isHipaaAuthorization: true,
  });

  const handleSubmit = useCallback(
    async (values: CreateAccountData) => {
      const convertedValues = convertCreateAccountValuesToDataModel(
        values,
        providerFirstName,
        providerLastName,
        termsOfUse,
        privacyPolicy,
        hipaaAuthorization,
      );

      await api.postCreateAccount(convertedValues);
    },
    [providerFirstName, providerLastName, termsOfUse, privacyPolicy, hipaaAuthorization],
  );

  const handleSuccessComponentButtonClick = useCallback(
    (formResetHandler) => (): void => {
      // @ts-ignore
      fbq('track', 'CompleteRegistration');
      gtag('event', 'Client Sign Up Form Finish', {
        event_category: 'Modal',
        event_label: 'Form Submit',
      });
      formResetHandler();
      handleModalClose();
    },
    [handleModalClose],
  );

  return (
    <CreateAccountForm
      onSubmit={handleSubmit}
      onSuccessComponentButtonClick={handleSuccessComponentButtonClick}
      formValidation={formValidation}
    />
  );
};

export default CreateAccountFormContainer;
