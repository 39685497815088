/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/ban-ts-ignore */

// libraries
import React, { useCallback, useMemo } from 'react';
import { CardNumberElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

// custom components
import StripePaymentForm from './StripePaymentForm';

// hooks
import useModal from 'hooks/useModal';

// selectors
import { selectUser } from 'modules/User/selectors';

// services
import {
  attachNewPaymentMethod,
  createStripeClientCustomer,
} from 'modules/BookingFlow/PaymentInformation/Stripe/StripePaymentForm/services';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';
import api from './api';

interface StripePaymentFormContainerProps {
  cards: any[];
  stripeСustomerId: string;
  onCardsSet: any;
}

const StripePaymentFormContainer = ({
  cards,
  stripeСustomerId,
  onCardsSet,
}: StripePaymentFormContainerProps): JSX.Element => {
  const { handleModalOpen } = useModal();

  const stripe = useStripe();
  const elements = useElements();

  const {
    token: { accessToken },
  } = useSelector(selectUser);

  const handleSubmit = useCallback(
    async (values) => {
      if (!stripe || !elements) {
        return;
      }

      if (!cards?.length || values.card === 'new') {
        const cardNumberElement = elements.getElement(CardNumberElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardNumberElement,
          billing_details: {
            name: `${values.firstName} ${values.lastName}`,
          },
        } as any);

        if (error) {
          throw new Error(error.message);
        }

        if (paymentMethod) {
          if (!stripeСustomerId) {
            await createStripeClientCustomer(accessToken, paymentMethod.id);
          }

          const newPaymentMethod = await attachNewPaymentMethod(paymentMethod.id, 'Stripe', accessToken);

          onCardsSet((state: any) => [...state, newPaymentMethod.data.result]);
        }
      }
    },
    [stripe, elements, cards, stripeСustomerId, accessToken, onCardsSet],
  );

  const initialValues = useMemo(
    () => ({ paymentType: 'Stripe', card: !!cards && !!cards.length && cards[0].paymentMethodId }),
    [cards],
  );

  const handleCardDelete = useCallback(
    (paymentMethodId) => async (): Promise<any> => {
      try {
        await api.postDeletePaymentMethod(paymentMethodId, accessToken);

        onCardsSet((state: any) =>
          state.filter((card: { paymentMethodId: string }) => card.paymentMethodId !== paymentMethodId),
        );
      } catch (err) {
        console.log(err);
      }
    },
    [accessToken, onCardsSet],
  );

  const validationSchema = useMemo(
    () => ({
      firstName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      lastName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback(
    (values: any) => {
      if (values.card === 'new' || !cards || !cards.length) {
        return validate(values, validationSchema);
      }
    },
    [cards, validationSchema],
  );

  return (
    <StripePaymentForm
      onSubmit={handleSubmit}
      stripe={stripe}
      initialValues={initialValues}
      cards={cards}
      formValidation={formValidation}
      handleModalOpen={handleModalOpen}
      onCardDelete={handleCardDelete}
    />
  );
};

export default StripePaymentFormContainer;
