// libraries
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// custom components
import EditProfileForm from './EditProfileForm';

// services
import {
  getProvidersProfileInfo,
  convertProviderProfileInfoToViewLayer,
  postProvidersProfileInfo,
  getCredentials,
} from './services';
import { getProductsOffered } from '../Appointments/ProductsOffered/services';

// selectors
import { selectUser } from 'modules/User/selectors';
import { selectProviderCriteria } from 'modules/ProviderCriteria/selectors';
import { ProviderCriteria } from 'modules/ProviderCriteria/types';

// api
import api from 'pages/ProviderDashboard/ProviderDashboardLayout/api';

// constants
import { STATES_OPTIONS } from 'modules/SignUpWithFooterContactForms/ProviderSignUp/constants';
import { UserRoles } from 'constants/user';

// hooks
import useUploadImage from 'hooks/useUploadImage';
import useConvertedProviderCriteria from 'hooks/useConvertedProviderCriteria';

// actions
import { setUserImage, setUserName } from 'modules/User/actions';

// utils
import { validate } from 'utils/validation';

// types
import { VALIDATION_TYPES } from 'utils/validation/types';

import { OfferedProduct } from 'pages/ProviderProfile/types';

const providerProfileInfoDefaultState = {
  id: undefined,
  address: { address: undefined, city: undefined, state: undefined, zipcode: undefined },
  biography: undefined,
  facebookHandle: undefined,
  linkedInHandle: undefined,
  firstName: undefined,
  slug: undefined,
  imageURL: undefined,
  instagramHandle: undefined,
  lastName: undefined,
  services: [],
  specializations: [],
  credentials: [],
  stages: [],
  website: undefined,
};

const EditProfileContainer = (): JSX.Element => {
  const [isProviderProfileInfoLoading, setIsProviderProfileInfoLoading] = useState(false);

  const [providerProfileInfo, setProviderProfileInfo] = useState(providerProfileInfoDefaultState);

  const [credentialsOptions, setCredentialsOptions] = useState([]);

  const { imageData, onChangeUploadInput } = useUploadImage();

  const dispatch = useDispatch();

  const providerCriteria = useSelector(selectProviderCriteria);

  const { stageOptions } = useConvertedProviderCriteria(true);

  const [isProductsOfferedLoading, setIsProductsOfferedLoading] = useState(false);

  const [productsOffered, setProductsOffered] = useState([]);

  const [servicesRequired, setServicesRequired] = useState(false);

  const [specializationsRequired, setSpecializationsRequired] = useState(false);

  const {
    role,
    userId,
    token: { accessToken },
  } = useSelector(selectUser);

  useEffect(() => {
    (async (): Promise<any> => {
      setIsProviderProfileInfoLoading(true);
      try {
        const providersProfileInfo = await getProvidersProfileInfo(userId, accessToken);

        const convertedProviderProfileInfo = convertProviderProfileInfoToViewLayer(providersProfileInfo.data);

        setProviderProfileInfo(convertedProviderProfileInfo);

        const credentials = await getCredentials(accessToken);

        const convertedCredentialsToOptions = credentials?.data.map((credential: any) => ({
          value: credential.value,
          label: credential.value,
        }));

        setCredentialsOptions(convertedCredentialsToOptions);
      } catch (err) {
        console.log(err);
      }
      setIsProviderProfileInfoLoading(false);
    })();
  }, [accessToken, userId]);

  useEffect(() => {
    (async (): Promise<any> => {
      setIsProductsOfferedLoading(true);
      try {
        const providersProfileInfo = await getProductsOffered(accessToken);

        setProductsOffered(providersProfileInfo.data);
      } catch (err) {
        console.log(err);
      }
      setIsProductsOfferedLoading(false);
    })();
  }, [accessToken, userId]);

  const {
    firstName,
    lastName,
    slug,
    address,
    biography,
    website,
    instagramHandle,
    facebookHandle,
    linkedInHandle,
    services,
    specializations,
    credentials,
    stages,
  } = providerProfileInfo;

  const initialValues = useMemo(
    () => ({
      firstName,
      lastName,
      slug,
      address: address && address.address,
      city: address && address.city,
      state: address && STATES_OPTIONS.find((state: { value: string; label: string }) => state.value === address.state),
      zipcode: address && address.zipcode,
      credentials: credentials.map((credential: any) => ({
        value: credential,
        label: credential,
      })),
      stages:
        stages &&
        stages.map((stage: any) => ({
          value: stage.id,
          label: stage.value,
        })),
      biography,
      website,
      instagramHandle,
      facebookHandle,
      linkedInHandle,
    }),
    [
      firstName,
      lastName,
      slug,
      address,
      biography,
      website,
      instagramHandle,
      facebookHandle,
      linkedInHandle,
      credentials,
      stages,
    ],
  );

  const handleServiceItemClick = useCallback(
    (selectedService: ProviderCriteria) => () => {
      setServicesRequired(false);
      const isServiceSelected = !!services.find((service: any) => service.id === selectedService.id);

      if (!isServiceSelected && services.length >= 3) {
        return;
      } else {
        setProviderProfileInfo((state: any): any => {
          if (isServiceSelected) {
            return { ...state, services: state.services.filter((service: any) => service.id !== selectedService.id) };
          }
          return { ...state, services: [...state.services, selectedService] };
        });
      }
    },
    [services],
  );

  const handleSpecializationItemClick = useCallback(
    (selectedSpecialization: ProviderCriteria) => () => {
      setSpecializationsRequired(false);
      const isSpecializationSelected = !!specializations.find(
        (specialization: any) => specialization.id === selectedSpecialization.id,
      );
      const maxSpecializationsCount = 10;

      if (!isSpecializationSelected && specializations.length >= maxSpecializationsCount) {
        return;
      } else {
        setProviderProfileInfo((state: any): any => {
          if (isSpecializationSelected) {
            return {
              ...state,
              specializations: state.specializations.filter((service: any) => service.id !== selectedSpecialization.id),
            };
          }
          return { ...state, specializations: [...state.specializations, selectedSpecialization] };
        });
      }
    },
    [specializations],
  );

  const handleProductOfferedChange = useCallback(
    (selectedProduct: OfferedProduct) => () => {
      setProductsOffered((state: any): any =>
        state.map((product: any) =>
          product.id === selectedProduct.id ? { ...product, checked: !product.checked } : { ...product },
        ),
      );
    },
    [],
  );

  const onSubmit = useCallback(
    async (values: any) => {
      if (providerProfileInfo.services.length === 0 || !providerProfileInfo.services) {
        setServicesRequired(true);
      } else if (providerProfileInfo.specializations.length === 0 || !providerProfileInfo.specializations) {
        setSpecializationsRequired(true);
      } else {
        const checkedProductsOffered = productsOffered.filter((propduct: any) => propduct.checked);

        if (role === UserRoles.PROVIDER_BASIC) {
          if (checkedProductsOffered.length === 0) {
            throw new Error('Products Offered is Required');
          } else {
            await postProvidersProfileInfo(providerProfileInfo, checkedProductsOffered, values, accessToken);
          }
        } else {
          await postProvidersProfileInfo(providerProfileInfo, [], values, accessToken);
        }

        if (imageData.convertedImage) {
          const image = await api.postUploadProviderImage({ imageData: imageData.convertedImage }, accessToken);

          dispatch(setUserImage(image.data?.url));
        }

        if (values.firstName && values.lastName) {
          dispatch(setUserName({ firstName: values.firstName, lastName: values.lastName }));
        }
      }
    },
    [productsOffered, role, imageData.convertedImage, providerProfileInfo, accessToken, dispatch],
  );

  const validationSchema = useMemo(
    () => ({
      firstName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      lastName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      biography: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      facebookHandle: {
        [VALIDATION_TYPES.FACEBOOK_LINK_FORMAT]: true,
      },
      instagramHandle: {
        [VALIDATION_TYPES.INSTAGRAM_LINK_FORMAT]: true,
      },
      linkedInHandle: {
        [VALIDATION_TYPES.LINKEDIN_LINK_FORMAT]: true,
      },
      website: {
        [VALIDATION_TYPES.WEBSITE_LINK_FORMAT]: true,
      },
      zipcode: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback(
    (values: any) => {
      if (!imageData.isCorrectFileType) {
        return { fileType: 'Allowed: PNG, JPG' };
      }

      if (!imageData.isCorrectFileSize) {
        return { fileSize: `too large: ${imageData.uploadedImageName}` };
      }

      if (!values.stages || values.stages.length === 0) {
        return { stages: 'required' };
      }

      return validate(values, validationSchema);
    },
    [imageData, validationSchema, providerProfileInfo],
  );

  return (
    <EditProfileForm
      onSubmit={onSubmit}
      formValidation={formValidation}
      initialValues={initialValues}
      isProviderProfileInfoLoading={isProviderProfileInfoLoading}
      providerProfileInfo={providerProfileInfo}
      services={providerCriteria.services}
      specializations={providerCriteria.specializations}
      stageOptions={stageOptions}
      credentialsOptions={credentialsOptions}
      onServiceItemClick={handleServiceItemClick}
      onSpecializationItemClick={handleSpecializationItemClick}
      onChangeUploadInput={onChangeUploadInput}
      uploadedImageName={imageData.uploadedImageName}
      isProductsOfferedLoading={isProductsOfferedLoading}
      productsOffered={productsOffered}
      onProductOfferedChange={handleProductOfferedChange}
      useRole={role}
      specializationsRequired={specializationsRequired}
      servicesRequired={servicesRequired}
    />
  );
};

export default EditProfileContainer;
