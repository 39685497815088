// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { CentredFlex } from 'shared/styledComponents';
import { H2 } from 'shared/typography';

const ThankTextContainer = styled(CentredFlex)`
  width: 100%;
  height: 100%;
`;

const ThankText = styled(H2)`
  font-size: 35px;
  line-height: 50px;
  color: #fff;
  text-align: center;
`;

const EmailFormSuccess = (): JSX.Element => (
  <ThankTextContainer>
    <ThankText>Thank you! We’ve got good things coming your way.</ThankText>
  </ThankTextContainer>
);

export default EmailFormSuccess;
