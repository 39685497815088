// libraries
import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import styled from 'styled-components';

// custom components
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';

// styled components
import { Flex, ColumnedFlex } from 'shared/styledComponents';
import { TextStyles } from 'shared/styles';
import { Text } from 'shared/typography';

// styles
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import '../index.css';

export const NewCardWrapper = styled(ColumnedFlex)``;

export const InputsWrapper = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const StyledInput = styled(Input)`
  ${TextStyles};
  width: calc(50% - 10px);
  border: 1px solid ${colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  outline: none;

  ::placeholder {
    color: ${colors.lightBrown};
  }
`;

export const CheckboxWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 20px;
`;

export const CheckboxLabel = styled(Text)`
  color: ${({ theme: { colors } }): string => colors.bismark};
  margin-left: 14px;
`;

export const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: colors.lightBrown,
      fontFamily: fonts.wigrumLight,
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      lineHeight: '22px',

      '::placeholder': {
        color: colors.lightBrown,
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const NewCard = (): JSX.Element => (
  <NewCardWrapper>
    <InputsWrapper>
      <StyledInput name="firstName" type="text" placeholder="First Name" size="small" />
      <StyledInput name="lastName" type="text" placeholder="Last Name" size="small" />
    </InputsWrapper>
    <InputsWrapper>
      <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
    </InputsWrapper>
    <InputsWrapper>
      <CardExpiryElement className="Stripe-card-expiry-element" options={CARD_ELEMENT_OPTIONS} />
      <CardCvcElement className="Stripe-card-cvc-element" options={CARD_ELEMENT_OPTIONS} />
    </InputsWrapper>
    <CheckboxWrapper>
      <Checkbox name="saveCard" />
      <CheckboxLabel>Save Card</CheckboxLabel>
    </CheckboxWrapper>
  </NewCardWrapper>
);

export default NewCard;
