// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

// types
import { ProductSubscriptionData, PaySubscriptionData } from './types';

const getProductSubscriptions = (): Promise<AxiosResponse<any>> => apiRequest.get('referencedata/product-subscription');

const postProductSubscription = (data: ProductSubscriptionData, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/product-subscription', data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const postStripePaySubscription = (data: PaySubscriptionData, accessToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('payment/pay-subscription', data, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

export default { getProductSubscriptions, postProductSubscription, postStripePaySubscription };
