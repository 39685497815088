// libraries
import { useSelector } from 'react-redux';

// actions
import { selectProviderCriteria } from 'modules/ProviderCriteria/selectors';
import { mapProviderCriteriaToViewLayer } from 'utils/mappers';

const useConvertedProviderCriteria = (isMultiForm = false): any => {
  const { stages, services, specializations, identities } = useSelector(selectProviderCriteria);

  const serviceOptions = mapProviderCriteriaToViewLayer(services, 'All Services', isMultiForm);

  const stageOptions = mapProviderCriteriaToViewLayer(stages, 'All Stages', isMultiForm);

  const specializationOptions = mapProviderCriteriaToViewLayer(specializations, 'All Specializations', isMultiForm);

  const identityOptions = mapProviderCriteriaToViewLayer(identities, 'All Identities', isMultiForm);

  return { serviceOptions, stageOptions, specializationOptions, identityOptions };
};

export default useConvertedProviderCriteria;
