// libraries
import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import UpdatedTermsNotification from 'components/UpdatedTermsNotification';

// custom components
import Routes from 'Routes';

// styles
import theme from '../theme';

// store
import { store, persistor } from 'store';

import 'shared/main.css';

const App = (): JSX.Element => (
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<div />}>
          <Router>
            <Routes />
          </Router>

          <UpdatedTermsNotification />
        </Suspense>
      </PersistGate>
    </Provider>
  </ThemeProvider>
);

export default App;
