// libraries
import React, { FC } from 'react';
import styled from 'styled-components';

// custom components
import Icon from '../../components/Icon';
import EmailFormContainer from './EmailFormContainer';

// hooks
import useModal from 'hooks/useModal';

const PopupContainer = styled.div`
  max-width: 475px;
  min-height: 514px;
  background-color: rgba(227, 189, 110, 0.95);
  padding: 35px 40px 15px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 767px) {
    padding: 40px 20px 15px 20px;
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const EmailPopupForm: FC = () => {
  const { handleModalClose } = useModal();

  const onClose = (): void => {
    window.localStorage.setItem('hasPromoModalBeenShown', 'true');
    handleModalClose();
  };

  return (
    <PopupContainer>
      <IconContainer>
        <Icon type="close" color="#fff" onClick={onClose} />
      </IconContainer>
      <EmailFormContainer />
    </PopupContainer>
  );
};

export default EmailPopupForm;
