// libraries
import React from 'react';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import AppointmentsLayout from './AppointmentsLayout';

// modules
import UpcomingAppointments from 'modules/Dashboard/ProviderDashboard/Appointments/UpcomingAppointments';

const UpcomingAppointmentsPage = (): JSX.Element => {
  return (
    <ProviderDashboardLayout>
      <AppointmentsLayout>
        <UpcomingAppointments />
      </AppointmentsLayout>
    </ProviderDashboardLayout>
  );
};

export default UpcomingAppointmentsPage;
