// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import AppointmentsLayout from './AppointmentsLayout';

// styled components
import { Title } from 'shared/typography';

// modules
import ProductsOfferedContainer from 'modules/Dashboard/ProviderDashboard/Appointments/ProductsOffered';

// hooks
import useResize from 'hooks/useResize';

const Heading = styled(Title)`
  margin-bottom: 50px;
`;

const ProductsOfferedPage = (): JSX.Element => {
  const { isDesktop } = useResize();

  return (
    <ProviderDashboardLayout>
      <AppointmentsLayout>
        {isDesktop && <Heading>Products Offered</Heading>}
        <ProductsOfferedContainer />
      </AppointmentsLayout>
    </ProviderDashboardLayout>
  );
};

export default ProductsOfferedPage;
