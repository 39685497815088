// libraries
import React from 'react';
import DayPicker from 'react-day-picker';

// styles;
import './index.css';

interface CalendarProps {
  month?: Date;
  selectedDays: Date | Date[];
  onDayClick: (day: Date, modifiers: any) => void;
  disabledDays?: any;
  numberOfMonths?: number;
  showOutsideDays?: boolean;
  pagedNavigation?: boolean;
  modifiers?: any;
  modifiersStyles?: any;
}

const Calendar = ({
  month,
  selectedDays,
  onDayClick,
  disabledDays,
  numberOfMonths,
  showOutsideDays,
  modifiers,
  modifiersStyles,
}: CalendarProps): JSX.Element => (
  <DayPicker
    month={month}
    onDayClick={onDayClick}
    selectedDays={selectedDays}
    disabledDays={disabledDays}
    numberOfMonths={numberOfMonths}
    showOutsideDays={showOutsideDays}
    modifiers={modifiers}
    modifiersStyles={modifiersStyles}
  />
);

export default Calendar;
