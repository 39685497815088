export const handleSubmissionErrors = (func: any) => async (...args: any) => {
  try {
    return await func(...args);
  } catch (error) {
    console.log(error);
    if (error.response) {
      if (error.response.data) {
        if (error.response.data.errors && Object.keys(error.response.data.errors).length) {
          return { error: error.response.data.errors[Object.keys(error.response.data.errors)[0]] };
        }
        return { error: error.response.data.message };
      }
    }

    return error.message ? { error: error.message } : { error: 'Something went wrong.' };
  }
};
