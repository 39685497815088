/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/camelcase */

// libraries
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

// custom components
import ProviderOnboarding from './ProviderOnboarding';

// hooks
import useModal from 'hooks/useModal';

// types
import { ProviderOnboardingStepTypes } from './types';

// selectors
import { selectUser } from 'modules/User/selectors';

// actions
import { setUser } from 'modules/User/actions';

// services
import { convertUserDataToViewModel, convertLogInValuesToDataModel } from 'modules/LogInOrCreateAccount/LogIn/services';

// api
import api from './api';
import { postLogIn } from 'modules/User/api';
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

const ProviderOnboardingContainer = (): JSX.Element => {
  const location = useLocation();

  const queryParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c }), [
    location.search,
  ]);

  const {
    userId,
    isLoggedIn,
    token: { accessToken },
  } = useSelector(selectUser);

  const dispatch = useDispatch();

  const { handleModalOpen } = useModal();

  let openedStepTypeDefultvalue: any;

  if (isLoggedIn && queryParams.hasOwnProperty('envelopeId')) {
    openedStepTypeDefultvalue = ProviderOnboardingStepTypes.BaaAgreement;
  } else if (isLoggedIn && !queryParams.hasOwnProperty('envelopeId')) {
    openedStepTypeDefultvalue = ProviderOnboardingStepTypes.Hipaa;
  } else {
    openedStepTypeDefultvalue = ProviderOnboardingStepTypes.CreateAccount;
  }

  const [openedStepType, setOpenedStepType] = useState(openedStepTypeDefultvalue);

  const [isHipaaStepSucceeded, setIsHipaaStepSucceeded] = useState(false);

  const [providerOnboardingStatus, setProviderOnboardingStatus] = useState({
    isCoveredUnderHIPAA: undefined,
    productSubscription: { id: undefined, name: undefined, price: undefined, pricing: undefined },
    signedDate: undefined,
  });

  const [tokenStatus, setTokenStatus] = useState({ isExpired: false, isPasswordCreated: false });

  const [isTokenStatusLoading, setTokenStatusIsLoading] = useState(false);

  const [isResendEmailLoading, setIsResendEmailLoading] = useState(false);

  const [isResendEmailSuccess, setIsResendEmailSuccess] = useState(false);

  useEffect(() => {
    if (isLoggedIn && queryParams.hasOwnProperty('envelopeId')) {
      setIsHipaaStepSucceeded(true);
    }

    return () => {
      if (userId && isLoggedIn) {
        (async (): Promise<any> => {
          try {
            await api.postDropProviderOnboarding(userId);
          } catch (err) {
            console.log(err);
          }
        })();
      }
    };
  }, [isLoggedIn, userId, queryParams]);

  useEffect(() => {
    (async (): Promise<any> => {
      try {
        if (isLoggedIn) {
          const providerOnboardingStatus = await api.getProviderOnboardingStatus(accessToken);

          setProviderOnboardingStatus(providerOnboardingStatus.data);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken, isLoggedIn]);

  useEffect(() => {
    setTokenStatusIsLoading(true);

    (async () => {
      try {
        const tokenStatus = await api.getOnboardingTokenStatus(queryParams?.email, queryParams?.token);

        setTokenStatus(tokenStatus.data);
      } catch (err) {
        console.log(err);
      }
      setTokenStatusIsLoading(false);
    })();
  }, [accessToken, queryParams]);

  const handleStepClick = useCallback(
    (stepType: ProviderOnboardingStepTypes) => (): void => {
      setOpenedStepType(stepType);
    },
    [],
  );

  useEffect(() => {
    let step;
    switch (openedStepType) {
      case 0:
        step = 'Create Account';
        // @ts-ignore
        fbq('track', 'Lead');
        break;
      case 1:
        step = 'Hipaa';
        break;
      case 2:
        step = 'Agreement';
        break;
      case 3:
        step = 'Subscription';
        break;
      case 4:
        step = 'Payment';
    }
    if (step) {
      // @ts-ignore
      gtag('event', `Provider ${step}`, {
        event_category: 'Provider Onboarding',
        event_label: 'Step',
      });
    }
  }, [openedStepType]);

  const handleLoginSubmit = useCallback(
    async (values) => {
      const convertedValues = convertLogInValuesToDataModel(values);

      const user = await postLogIn(convertedValues);

      const convertedUser = convertUserDataToViewModel(user.data);

      dispatch(setUser(convertedUser));

      setOpenedStepType(ProviderOnboardingStepTypes.Hipaa);
    },
    [dispatch],
  );

  const handleResendEmailButtonClick = useCallback(async () => {
    setIsResendEmailLoading(true);

    try {
      await api.postProviderApprovedEmail(queryParams?.email, accessToken);
      setIsResendEmailSuccess(true);
    } catch (err) {
      console.log(err);
    }

    setIsResendEmailLoading(false);
  }, [accessToken, queryParams]);

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const loginFormValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  return (
    <ProviderOnboarding
      tokenStatus={tokenStatus}
      isTokenStatusLoading={isTokenStatusLoading}
      providerOnboardingStatus={providerOnboardingStatus}
      openedStepType={openedStepType}
      setOpenedStepType={setOpenedStepType}
      onStepClick={handleStepClick}
      isHipaaStepSucceeded={isHipaaStepSucceeded}
      setIsHipaaStepSucceeded={setIsHipaaStepSucceeded}
      isLoggedIn={isLoggedIn}
      handleModalOpen={handleModalOpen}
      handleLoginSubmit={handleLoginSubmit}
      loginFormValidation={loginFormValidation}
      isResendEmailLoading={isResendEmailLoading}
      isResendEmailSuccess={isResendEmailSuccess}
      onResendEmailButtonClick={handleResendEmailButtonClick}
    />
  );
};

export default ProviderOnboardingContainer;
