//libraries
import React, { useCallback } from 'react';
import { Form as FinalForm, FormRenderProps } from 'react-final-form';
import Recaptcha from 'react-google-invisible-recaptcha';
import styled from 'styled-components';

// utils
import { handleSubmissionErrors } from 'utils/handleSubmissionErrors';

// hooks
import useRecaptcha from 'hooks/useRecaptcha';

import './index.css';

interface FormProps {
  children: (props: FormRenderProps) => JSX.Element;
  onSubmit: (values: any) => void;
  initialValues?: any;
  validate?: any;
}

const StyledForm = styled.form`
  width: 100%;
`;

const Form = ({
  children,
  onSubmit,
  initialValues,
  validate,

  ...restProps
}: FormProps): JSX.Element => {
  const { recaptcha, handleRecaptchaExecute } = useRecaptcha();

  const handleSubmit = useCallback(
    (values) => {
      handleRecaptchaExecute();
      return handleSubmissionErrors(onSubmit)(values);
    },
    [handleRecaptchaExecute, onSubmit],
  );
  return (
    <FinalForm validate={validate} onSubmit={handleSubmit} initialValues={initialValues} {...restProps}>
      {(props: FormRenderProps): JSX.Element => (
        <StyledForm onSubmit={props.handleSubmit}>
          <>
            {children(props)}
            <Recaptcha ref={recaptcha} sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} />
          </>
        </StyledForm>
      )}
    </FinalForm>
  );
};

export default Form;
