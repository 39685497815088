// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import PaymentStep from './PaymentStep';

// styled components
import { SubmitButton, Flex, ColumnedFlex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

// shared styles
import { Title, Text } from 'shared/typography';

// types
import { ProviderPaymentInfo } from '../types';
import Icon from 'components/Icon';

export const StepNumber = styled(Title)<any>`
  width: 28px;
  height: 28px;
  border: 2px solid ${({ theme: { colors } }): string => colors.bismark};
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  padding-top: 4px;
  padding-left: 1px;
  margin-right: 15px;
  line-height: 1;

  @media ${devices.mobile} {
    padding-top: 2px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 15px;
`;

export const Heading = styled(Title)<any>(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
    margin-bottom: 15px;
  `,
);

export const SubHeading = styled(Text)`
  margin-bottom: 25px;
`;

export const StyledSubmitButton = styled(SubmitButton)`
  max-width: 300px;
`;

interface CreatePaymentAccountProps {
  providerPaymentInfo: ProviderPaymentInfo;
  onCreatePaymentAccountButtonClick: VoidFunction;
  onVerifyPaymentAccountButtonClick: VoidFunction;
}

const CreatePaymentAccount = ({
  providerPaymentInfo,
  onCreatePaymentAccountButtonClick,
  onVerifyPaymentAccountButtonClick,
}: CreatePaymentAccountProps): JSX.Element => {
  const { stripeAccountId, transfersEnabled } = providerPaymentInfo;

  if (stripeAccountId === null) {
    return (
      <PaymentStep
        stepNumber="1"
        heading="Create Account"
        subHeading="First you need to create your payment account."
        submitButtonName="Create Payment Account"
        onSubmitButtonClick={onCreatePaymentAccountButtonClick}
      />
    );
  }

  if (stripeAccountId && !transfersEnabled) {
    return (
      <PaymentStep
        stepNumber="1"
        heading="Verify Account"
        subHeading="First you need to verify your payment account."
        submitButtonName="Verify Payment Account"
        onSubmitButtonClick={onVerifyPaymentAccountButtonClick}
      />
    );
  }

  return (
    <Flex>
      <StyledIcon type="ListIcon" width="28px" height="28px" />
      <ColumnedFlex>
        <Heading size="medium">Verify Account</Heading>
        <Text>Your account has been successfully verified.</Text>
      </ColumnedFlex>
    </Flex>
  );
};
export default CreatePaymentAccount;
