export const convertPopUpInfoToDataLayer = (values: any) => ({
  firstName: values.firstName,
  lastName: values.lastName,
  email: values.email,
  stage: values.stage ? { id: values.stage.value, value: values.stage.label } : undefined,
  interests: !!values.interests?.length
    ? values.interests.map((interest: any) => ({ id: interest.value, value: interest.label }))
    : undefined,
  termsAccepted: true,
  signedDocuments: [
    { name: 'Terms of Use', version: '1' },
    { name: 'Privacy Policy', version: '1' },
  ],
});
