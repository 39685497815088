// libraries
import React from 'react';
import styled from 'styled-components';
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';

// custom components
import Form from 'components/Form';
import RadioButton from 'components/Form/RadioButton';

// styled components
import { Flex, SubmitButton, Label, Error, ColumnedFlex } from 'shared/styledComponents';
import { Text } from 'shared/typography';

// styles
import devices from 'theme/devices';
import {
  NewCardWrapper,
  InputsWrapper,
  StyledInput,
  CARD_ELEMENT_OPTIONS,
} from 'modules/BookingFlow/PaymentInformation/Stripe/StripePaymentForm/components/NewCard';

// utils
import { getValue } from 'utils/getValue';

// types
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';
import Icon from 'components/Icon';

const CardsBlock = styled.div`
  max-width: 370px;
`;

const CardsWrapper = styled(ColumnedFlex)`
  margin-left: 35px;

  @media ${devices.mobile} {
    margin-left: 6px;
  }
`;

const RadioButtonWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 20px;
`;

const RadioButtonLabel = styled(Label)(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
  `,
);

export const StyledText = styled(Text)`
  max-width: 815px;
  width: 100%;
  margin: 40px 0;

  @media ${devices.mobile} {
    margin-top: 30px;
  }
`;

export const DeleteIcon = styled(Icon)`
  margin-top: -3px;
  margin-left: 10px;
  cursor: pointer;
  @media ${devices.mobile} {
  }
`;

const NewCard = ({ stripe, submitting, submitFailed, submitErrors, hasValidationErrors, errors }: any): JSX.Element => (
  <NewCardWrapper>
    <InputsWrapper>
      <StyledInput name="firstName" type="text" placeholder="First Name" size="small" />
      <StyledInput name="lastName" type="text" placeholder="Last Name" size="small" />
    </InputsWrapper>
    <InputsWrapper>
      <CardNumberElement options={CARD_ELEMENT_OPTIONS} />
    </InputsWrapper>
    <InputsWrapper>
      <CardExpiryElement className="Stripe-card-expiry-element" options={CARD_ELEMENT_OPTIONS} />
      <CardCvcElement className="Stripe-card-cvc-element" options={CARD_ELEMENT_OPTIONS} />
    </InputsWrapper>
    {submitFailed && hasValidationErrors && (
      <Error size="small">
        {!!Object.keys(errors).length &&
          `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
      </Error>
    )}
    {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
    <SubmitButton type="submit" disabled={!stripe} isLoading={submitting}>
      Submit
    </SubmitButton>
  </NewCardWrapper>
);

interface Card {
  cardLast4: string;
  paymentMethodId: string;
}

interface StripePaymentFormProps {
  onSubmit: (values: any) => void;
  stripe: any;
  initialValues: any;
  cards: Card[];
  formValidation: (values: any) => any;
  handleModalOpen: (title: string, data?: object) => () => void;
  onCardDelete: (paymentMethodId: string) => () => void;
}

const StripePaymentForm = ({
  onSubmit,
  stripe,
  initialValues,
  cards,
  formValidation,
  handleModalOpen,
  onCardDelete,
}: StripePaymentFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} initialValues={initialValues} validate={formValidation}>
    {({
      form: { reset },
      values,
      submitting,
      submitFailed,
      submitSucceeded,
      submitErrors,
      hasValidationErrors,
      errors,
    }): JSX.Element => {
      if (submitSucceeded) {
        reset();
      }
      return (
        <>
          <RadioButtonWrapper>
            <RadioButton name="paymentType" value="Stripe" />
            <RadioButtonLabel>Credit Card (Stripe)</RadioButtonLabel>
          </RadioButtonWrapper>
          <CardsBlock>
            {!!cards && !!cards.length ? (
              <CardsWrapper>
                {cards.map(({ cardLast4, paymentMethodId }: Card) => (
                  <RadioButtonWrapper key={paymentMethodId}>
                    <RadioButtonLabel>**** {cardLast4}</RadioButtonLabel>
                    <DeleteIcon
                      type="close"
                      width="12px"
                      height="12px"
                      onClick={handleModalOpen('conformationModal', {
                        title: 'Delete Card',
                        description: 'Are you sure you want to delete card? ',
                        cancelButtonLabel: 'Cancel',
                        confirmButtonLabel: 'Yes',
                        onConfirmButtonClick: onCardDelete(paymentMethodId),
                      })}
                    />
                  </RadioButtonWrapper>
                ))}
                <RadioButtonWrapper>
                  <RadioButton name="card" value="new" size="small" />
                  <RadioButtonLabel>Add New Credit Card</RadioButtonLabel>
                </RadioButtonWrapper>
                {values.card === 'new' && (
                  <NewCard
                    stripe={stripe}
                    submitting={submitting}
                    submitFailed={submitFailed}
                    submitErrors={submitErrors}
                    hasValidationErrors={hasValidationErrors}
                    errors={errors}
                  />
                )}
              </CardsWrapper>
            ) : (
              <NewCard
                stripe={stripe}
                submitting={submitting}
                submitFailed={submitFailed}
                submitErrors={submitErrors}
                hasValidationErrors={hasValidationErrors}
                errors={errors}
              />
            )}
          </CardsBlock>
        </>
      );
    }}
  </Form>
);

export default StripePaymentForm;
