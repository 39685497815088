// libraries
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// custom components
import StripePaymentForm from './StripePaymentFormContainer';

// api
import bookingFlowApi from 'modules/BookingFlow/PaymentInformation/Stripe/api';
import api from '../../api';

// selectors
import { selectUser } from 'modules/User/selectors';

const PaymentMethodContainer = (): JSX.Element | null => {
  const [stripePublishableKey, setStripePublishableKey] = useState('');

  const [stripeСustomerId, setStripeСustomerId] = useState('');

  const [cards, setCards] = useState([]);

  const {
    token: { accessToken },
  } = useSelector(selectUser);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const stripeKey = await bookingFlowApi.getStripeKey(accessToken);

        const paymentMethods = await api.getPaymentMethods(accessToken);

        const stripeСustomerId = await api.getStripeСustomerId(accessToken);

        setStripeСustomerId(stripeСustomerId.data.stripeCustomerId);

        setStripePublishableKey(stripeKey.data.publishableKey);

        setCards(paymentMethods.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, [accessToken]);

  let stripePromise: any;

  if (stripePublishableKey) {
    stripePromise = loadStripe(stripePublishableKey);
  }

  return stripePublishableKey ? (
    <Elements stripe={stripePromise}>
      <StripePaymentForm cards={cards} stripeСustomerId={stripeСustomerId} onCardsSet={setCards} />
    </Elements>
  ) : null;
};

export default PaymentMethodContainer;
