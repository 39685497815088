// libraries
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import styled from 'styled-components';

// styled components
import { Title, Text } from 'shared/typography';
import { SubmitButton, Error, ColumnedFlex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';
import { TextStyles } from 'shared/styles';

// custom components
import Loader from 'components/Loader';
import Form from 'components/Form';
import Input from 'components/Form/Input';
import ChangeEmailSuccess from './ChangeEmailSuccess';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const EmailWrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const StyledSubmitButton = styled(SubmitButton)`
  width: fit-content;
  padding: 19px 30px;

  @media ${devices.mobile} {
    margin-bottom: 0;
    padding: 16px 21px;
  }
`;

const Heading = styled.div`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const StyledTitle = styled(Title)`
  margin-bottom: 15px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 23px;
  }
`;

const StyledText = styled(Text)`
  @media ${devices.mobile} {
    max-width: 270px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  max-width: 275px;
  width: 100%;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  margin-bottom: 30px;
  padding: 13px 15px;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
`;

const FormBlock = styled(ColumnedFlex)`
  width: 100%;
`;

const FormContentWrapper = styled(ColumnedFlex)`
  justify-content: center;
  align-items: flex-start;
`;

interface ChangeEmailProps {
  isEmailLoading: boolean;
  onSubmit: any;
  formValidation: any;
  initialValues: {
    email: string | undefined;
  };
}

const ChangeEmail = ({ onSubmit, isEmailLoading, initialValues, formValidation }: ChangeEmailProps): JSX.Element => (
  <EmailWrapper>
    <Heading>
      <StyledTitle>Change Email</StyledTitle>
      <StyledText> After you change the email, you will have to confirm it once again</StyledText>
    </Heading>
    {isEmailLoading ? (
      <Loader />
    ) : (
      <Form onSubmit={onSubmit} initialValues={initialValues} validate={formValidation}>
        {({
          form: { reset },
          submitting,
          submitSucceeded,
          submitFailed,
          submitErrors,
          hasValidationErrors,
          errors,
        }: FormRenderProps): JSX.Element => (
          <FormContentWrapper>
            {submitSucceeded ? (
              <ChangeEmailSuccess onClose={reset} />
            ) : (
              <FormBlock>
                <StyledInput name="email" type="email" placeholder="Email" size="small" />
                {submitFailed && hasValidationErrors && (
                  <Error size="small">
                    {!!Object.keys(errors).length &&
                      `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
                  </Error>
                )}
                {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
                <StyledSubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
                  update
                </StyledSubmitButton>
              </FormBlock>
            )}
          </FormContentWrapper>
        )}
      </Form>
    )}
  </EmailWrapper>
);

export default ChangeEmail;
