//libraries
import { createSelector } from 'reselect';

// types
import { ProviderCriteria } from './types';

export const selectProviderCriteria = (state: any) => state.providerCriteria;

export const selectIsProviderCriteriaLoading = createSelector(
  selectProviderCriteria,
  ({ isProviderCriteriaLoading }) => isProviderCriteriaLoading,
);

export const selectIsProviderCriteriaSuccesed = createSelector(
  selectProviderCriteria,
  ({ isProviderCriteriaSuccesed }) => isProviderCriteriaSuccesed,
);

export const selectStages = createSelector(selectProviderCriteria, ({ stages }): ProviderCriteria[] => stages);

export const selectServices = createSelector(selectProviderCriteria, ({ services }): ProviderCriteria[] => services);

export const selectIdentities = createSelector(
  selectProviderCriteria,
  ({ identities }): ProviderCriteria[] => identities,
);

export const selectSpecializations = createSelector(
  selectProviderCriteria,
  ({ specializations }): ProviderCriteria[] => specializations,
);
