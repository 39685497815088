// libraries
import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

// hooks
import useDropdown from '../../hooks/useDropdown';

// custom components
import ServicesDropdown from './ServicesDropdown';

import { ProviderCriteria } from 'modules/ProviderCriteria/types';

interface ServicesDropdownProps {
  services: ProviderCriteria[];
  iswhitecolor?: boolean;
}

const ServicesDropdownContainer = ({ services, iswhitecolor }: ServicesDropdownProps): JSX.Element => {
  const { isOpened, handleDropDownOpen, handleDropDownClose } = useDropdown();
  const [servicesListHeight, setServicesListHeight] = useState(0);

  const history = useHistory();

  const setServicesListHeightOnMount = useCallback((element: HTMLElement) => {
    if (element) {
      setServicesListHeight(element.offsetHeight);
    }
  }, []);

  const handleRedirectToServiceLanding = useCallback(
    ({ value }) => (): void => {
      handleDropDownClose();
      history.push(`/service/${value.split(' ').join('-').toLowerCase()}`);
    },
    [handleDropDownClose, history],
  );

  return (
    <ServicesDropdown
      isOpened={isOpened}
      onDropdownOpen={handleDropDownOpen}
      onDropdownClose={handleDropDownClose}
      setServicesListHeightOnMount={setServicesListHeightOnMount}
      servicesListHeight={servicesListHeight}
      services={services}
      handleRedirectToServiceLanding={handleRedirectToServiceLanding}
      iswhitecolor={iswhitecolor}
    />
  );
};

export default ServicesDropdownContainer;
