// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

export interface ClientSignUpData {
  firstName: string;
  lastName: string;
  email: string;
  stage: any;
  interests: string[];
}

const postSignUp = (data: ClientSignUpData): Promise<AxiosResponse<any>> => apiRequest.post(`client/sign-up`, data);

export default { postSignUp };
