/* eslint-disable @typescript-eslint/camelcase */

// libraries
import React, { useCallback } from 'react';
import { useStripe } from '@stripe/react-stripe-js';

// custom components
import ManualBankAccountConnectionForm from './ManualBankAccountConnectionForm';

// api
import api from './api';

const ManualBankAccountConnectionFormContainer = ({ userToken }: any): JSX.Element => {
  const stripe = useStripe();

  const handleSubmit = useCallback(
    async (values) => {
      if (!stripe) {
        return;
      }

      const { error, token } = await stripe?.createToken('bank_account', {
        country: 'US',
        currency: 'usd',
        routing_number: values?.routingNumber,
        account_number: values?.accountNumber,
        account_holder_name: values?.accountHolderName,
        account_holder_type: 'individual',
      });

      if (error) {
        throw new Error(error.message);
      }

      if (token) {
        await api.postAddExternalAccount(token?.id, userToken);

        window.location.reload(false);
      }
    },
    [stripe, userToken],
  );

  return <ManualBankAccountConnectionForm onSubmit={handleSubmit} />;
};

export default ManualBankAccountConnectionFormContainer;
