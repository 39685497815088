// libraries
import React from 'react';
import { useSelector } from 'react-redux';

// custom components
import ChangeEmail from './ChangeEmail';
import ChangePassword from './ChangePassword';
import AccountSettingsForm from './ChangeSubscription';
import ChangePayment from './ChangePayment';

// selectors
import { selectUser } from 'modules/User/selectors';

// utils
import styled from 'styled-components';

const StepWrapper = styled.div<any>(
  ({ theme: { colors, devices } }) => `
    width: 100%;
    padding-bottom: 50px;
    border-bottom: 1px solid ${colors.alto};
    margin-bottom: 50px;

    @media ${devices.mobile} {
      padding-bottom: 45px;
    }
  `,
);

const AccountSettingsContainer = (): JSX.Element => {
  const {
    role,
    token: { accessToken },
  } = useSelector(selectUser);

  return (
    <>
      <StepWrapper>
        <ChangeEmail accessToken={accessToken} />
      </StepWrapper>
      <StepWrapper>
        <ChangePassword accessToken={accessToken} />
      </StepWrapper>
      {/* <StepWrapper>
        <ChangePayment accessToken={accessToken} />
      </StepWrapper> */}
      {/* <StepWrapper>
        <AccountSettingsForm accessToken={accessToken} useRole={role} />
      </StepWrapper> */}
    </>
  );
};
export default AccountSettingsContainer;
