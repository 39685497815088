// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';
import ForgotPasswordForm from './ForgotPasswordForm';

// hooks
import useModal from 'hooks/useModal';

// styled components
import { Flex, ColumnedFlex, Frame } from 'shared/styledComponents';
import { H2 } from 'shared/typography';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';

const Wrapper = styled(Frame)`
  max-width: 50%;
  background-color: ${colors.white};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);

  @media ${devices.mobile} {
    max-width: 100%;
    align-self: center;
  }
`;

const HeadingWrapper = styled(ColumnedFlex)`
  background-color: ${colors.secondary};
  padding: 30px 30px 40px 90px;
`;

const CloseIconWrapper = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 15px;
`;

const CloseIcon = styled(Icon)<any>`
  cursor: pointer;
`;

const FormWrapper = styled(Flex)`
  padding: 60px 90px;
`;

const ForgotPasswordModal = (): JSX.Element => {
  const { handleModalClose } = useModal();
  return (
    <Wrapper>
      <HeadingWrapper>
        <CloseIconWrapper>
          <CloseIcon type="close" width="20px" height="20px" color={colors.primary} onClick={handleModalClose} />
        </CloseIconWrapper>
        <H2>Forgot Password?</H2>
      </HeadingWrapper>
      <FormWrapper>
        <ForgotPasswordForm />
      </FormWrapper>
    </Wrapper>
  );
};

export default ForgotPasswordModal;
