// Lib
import React, { useEffect, useCallback, useRef, ReactNode } from 'react';

interface ClickOutsideProps {
  children: ReactNode;
  onOutsideClick: VoidFunction;
}

const ClickOutside = ({ children, onOutsideClick }: ClickOutsideProps): JSX.Element => {
  const node: any = useRef();

  const handleOutsideClick = useCallback(
    (event: Event) => {
      if (!node.current.contains(event.target)) {
        onOutsideClick();
      }
    },
    [onOutsideClick],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return (): any => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return <div ref={node}>{children}</div>;
};

export default ClickOutside;
