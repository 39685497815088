// libraries
import React, { useCallback, useMemo } from 'react';

// custom components
import ChangePassword from './ChangePassword';

// api
import api from '../api';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

interface PasswordsContainerProps {
  accessToken: string;
}

const PasswordsContainer = ({ accessToken }: PasswordsContainerProps): JSX.Element => {
  const validationSchema = useMemo(
    () => ({
      oldPassword: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_EQUALITY]: true,
      },
      confirmPassword: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.PASSWORD_CONFIRMATION]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const onSubmit = useCallback(
    async (values: any) => {
      await api.postPassword(values.oldPassword, values.password, accessToken);
    },
    [accessToken],
  );

  return <ChangePassword onSubmit={onSubmit} formValidation={formValidation} />;
};

export default PasswordsContainer;
