//libraries
import React from 'react';
import styled from 'styled-components';
import devices from 'theme/devices';

import { H3, H4 } from 'shared/typography';

const StyledBox = styled.div``;

const StyledH3 = styled(H3)`
  font-size: 26px;
`;

const Success = (): JSX.Element => {
  return (
    <StyledBox>
      <StyledH3>Check your inbox, you'll start receiving emails from us shortly.</StyledH3>
    </StyledBox>
  );
};

export default Success;
