// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import ClientDashboardLayout from '../ClientDashboardLayout';
import UpcomingAppointments from 'modules/Dashboard/ProviderDashboard/Appointments/UpcomingAppointments';

// styled components
import { Title } from 'shared/typography';
import { ColumnedFlex } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

const Heading = styled(Title)`
  margin-bottom: 65px;

  @media ${devices.mobile} {
    margin-bottom: 60px;
  }
`;

const UpcomingAppointmentsWrapper = styled(ColumnedFlex)`
  width: 100%;

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

const UpcomingAppointmentsPage = (): JSX.Element => (
  <ClientDashboardLayout>
    <UpcomingAppointmentsWrapper>
      <Heading> Your Upcoming Appointments</Heading>
      <UpcomingAppointments />
    </UpcomingAppointmentsWrapper>
  </ClientDashboardLayout>
);

export default UpcomingAppointmentsPage;
