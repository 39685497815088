// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const getPaymentMethods = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('/payment/payment-methods', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const getStripeСustomerId = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('payment/stripe-customer-id', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { getPaymentMethods, getStripeСustomerId };
