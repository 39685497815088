// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

const postDropProviderOnboarding = (userId: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(`provider/${userId}/drop-onboarding`, undefined);

const postProviderApprovedEmail = (userEmail: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'provider/approved-email',
    { email: userEmail },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

const getProviderOnboardingStatus = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/onboarding-status', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const getOnboardingTokenStatus = (userEmail: any, userToken: any): Promise<AxiosResponse<any>> =>
  apiRequest.get(`provider/check-onboarding-token?email=${userEmail}&token=${userToken}`);

export default {
  postProviderApprovedEmail,
  postDropProviderOnboarding,
  getProviderOnboardingStatus,
  getOnboardingTokenStatus,
};
