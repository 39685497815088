import { useState, useCallback } from 'react';

interface UseDopdown {
  isOpened: boolean;
  handleDropDownOpen: VoidFunction;
  handleDropDownClose: VoidFunction;
  handleDropDownToggle: VoidFunction;
}

const useDropdown = (): UseDopdown => {
  const [isOpened, setIsOpened] = useState(false);

  const handleDropDownToggle = useCallback(() => {
    setIsOpened((state) => !state);
  }, []);

  const handleDropDownOpen = useCallback(() => {
    setIsOpened(true);
  }, []);

  const handleDropDownClose = useCallback(() => {
    setIsOpened(false);
  }, []);

  return { isOpened, handleDropDownOpen, handleDropDownClose, handleDropDownToggle };
};

export default useDropdown;
