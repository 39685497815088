// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';
import LogInForm from 'modules/LogInOrCreateAccount/LogIn';
import CreateAccountForm from 'modules/LogInOrCreateAccount/CreateAccount';

// hooks
import useModal from 'hooks/useModal';

// styled components
import { Flex, Frame } from 'shared/styledComponents';
import { H2 } from 'shared/typography';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';

const Wrapper = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const HeadingWrapper = styled.div`
  background-color: ${colors.secondary};

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const Heading = styled(Frame)`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 40px 90px;

  @media ${devices.mobile} {
    padding: 30px 0;
  }
`;

const CloseIconWrapper = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 15px;

  @media ${devices.mobile} {
    margin-bottom: 45px;
  }
`;

const CloseIcon = styled(Icon)<any>`
  cursor: pointer;
`;

const FormsWrapper = styled.div`
  padding: 95px 90px;

  @media ${devices.mobile} {
    padding: 45px 0px;
  }
`;

const Forms = styled(Frame)`
  display: flex;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const LogInFormWrapper = styled(Flex)`
  width: 100%;
  max-width: 330px;
  border-right: 1px solid ${colors.alto};

  @media ${devices.mobile} {
    border-right: 0;
  }
`;

const CreateAccountFormWrapper = styled(Flex)`
  width: 100%;
  max-width: 560px;
  padding-left: 60px;

  @media ${devices.mobile} {
    padding-left: 0;
  }
`;

const LogInOrCreateAccountModal = ({
  fromRedirect,
  locationRedirectPath,
  locationRedirectSearch,
  providerFirstName,
  providerLastName,
  onCloseIconClick,
}: any): JSX.Element => {
  const { handleModalClose } = useModal();

  return (
    <Wrapper>
      <HeadingWrapper>
        <Heading>
          <CloseIconWrapper>
            <CloseIcon
              type="close"
              width="20px"
              height="20px"
              color={colors.primary}
              onClick={onCloseIconClick || handleModalClose}
            />
          </CloseIconWrapper>
          <H2>Sign In / Sign Up</H2>
        </Heading>
      </HeadingWrapper>
      <FormsWrapper>
        <Forms>
          <LogInFormWrapper>
            <LogInForm
              fromRedirect={fromRedirect}
              locationRedirectPath={locationRedirectPath}
              locationRedirectSearch={locationRedirectSearch}
            />
          </LogInFormWrapper>
          <CreateAccountFormWrapper>
            <CreateAccountForm providerFirstName={providerFirstName} providerLastName={providerLastName} />
          </CreateAccountFormWrapper>
        </Forms>
      </FormsWrapper>
    </Wrapper>
  );
};

export default LogInOrCreateAccountModal;
