// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import ProviderDashboardLayout from '../ProviderDashboardLayout';

// modules
import EditProfile from 'modules/Dashboard/ProviderDashboard/EditProfile';

// styled components
import { ColumnedFlex } from 'shared/styledComponents';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
  margin-bottom: 40px;
`;

const EditProfilePage = (): JSX.Element => (
  <ProviderDashboardLayout>
    <Wrapper>
      <EditProfile />
    </Wrapper>
  </ProviderDashboardLayout>
);

export default EditProfilePage;
