// libraries
import React from 'react';

// custom components
import ClientDashboardLayout from 'pages/ClientDashboard/ClientDashboardLayout';
import PaymentMethod from 'modules/Dashboard/ClientDashboard/PaymentMethod';

const PaymentMethodPage = (): JSX.Element => (
  <ClientDashboardLayout>
    <PaymentMethod />
  </ClientDashboardLayout>
);

export default PaymentMethodPage;
