// libraries
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

// custom components
import Input from 'components/Form/Input';

// styled components
import { ColumnedFlex, Flex } from 'shared/styledComponents';
import { H3 } from 'shared/typography';
import { TextStyles } from 'shared/styles';

// styles
import fonts from 'theme/fonts';
import colors from 'theme/colors';
import devices from 'theme/devices';

// selectors styles
const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.white};
`;

export const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: calc(50% - 10px);
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    min-height: 48px;
    padding: 0 14px;
    box-shadow: none;
    cursor: pointer;
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      background-color: unset;
    `,
  multiValueLabel: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValueRemove: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    :hover {
      background-color: unset;
      color: currentColor;
    }
  `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};

export const FullWidthSelectStyles = {
  ...SelectStyles,
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: 100%;
  `,
};

export const SelectPlaceholder = styled.div``;

// other styles
export const Heading = styled(H3)`
  max-width: 450px;
  width: 100%;
  color: ${({ theme: { colors } }): string => colors.white};
  margin-bottom: 35px;

  @media ${devices.mobile} {
    max-width: 310px;
  }
`;

export const InputsWrapper = styled(ColumnedFlex)`
  width: 370px;
  margin-bottom: 30px;

  @media ${devices.mobile} {
    max-width: 310px;
  }
`;

export const PairedInputs = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const SingleInput = styled(Flex)`
  margin-bottom: 10px;
`;

export const StyledInput = styled(Input)`
  ${TextStyles};
  width: calc(50% - 10px);
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.1);
  color: ${({ theme: { colors } }): string => colors.white};
  padding: 13px 15px;
  border: none;
  outline: none;
  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.white};
  }
`;
