// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { ForgotPasswordData } from './types';

const postForgotPassword = (data: ForgotPasswordData): Promise<AxiosResponse<any>> =>
  apiRequest.post(`user/forgot-password`, data);

export default { postForgotPassword };
