// libraries
import React from 'react';
import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const StyledLoader = styled.div`
  border: 5px solid ${({ theme: { colors } }): string => colors.pampas};
  border-radius: 50%;
  border-top: 5px solid ${({ theme: { colors } }): string => colors.bismark};
  width: 40px;
  height: 40px;
  -webkit-animation: ${spin} 2s linear infinite; /* Safari */
  animation: ${spin} 2s linear infinite;
`;

const Loader = (): JSX.Element => <StyledLoader />;

export default Loader;
