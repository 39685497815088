// libraries
import React from 'react';
import { useSelector } from 'react-redux';

// custom components
import Footer from './Footer';

// selectors
import { selectServices } from 'modules/ProviderCriteria/selectors';

const FooterContainer = ({ isAfterForms = false, instagramMedia = [] }: { isAfterForms?: boolean, instagramMedia?: any }): JSX.Element => {
  const services = useSelector(selectServices);

  return <Footer services={services} isAfterForms={isAfterForms} instagramMedia={instagramMedia}/>;
};

export default React.memo(FooterContainer);
