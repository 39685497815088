import TagManager from 'react-gtm-module';

const submitToGADataLayer = (event: string): void => {
  const tagManagerArgs = {
    gtmId: 'GTM-MJ9XZB',
    dataLayer: {
      event,
    },
  };
  TagManager.initialize(tagManagerArgs);
};

export default submitToGADataLayer;
