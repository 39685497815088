// styles
import styled from 'styled-components';
import { TitleStyles, ButtonBaseStyles, TextStyles } from './styles';
import devices from 'theme/devices';

export const Flex = styled.div`
  display: flex;
`;

export const CentredFlex = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const ColumnedFlex = styled(Flex)`
  flex-direction: column;
`;

export const Frame = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  @media ${devices.mobile} {
    max-width: 310px;
  }
`;

export const PrimaryButton = styled.button<any>(({ size, theme: { colors, fonts } }) => {
  switch (size) {
    case 'small':
      return `
        font-family: ${fonts.wigrumRegular};
        font-size: 12px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: ${colors.white};
        padding: 17px 19px;
        ${ButtonBaseStyles};
        background-color: ${colors.bismark};
      `;
    default:
      return `
        font-family: ${fonts.wigrumRegular};
        font-size: 14px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: ${colors.white};
        padding: 17px 19px;
        ${ButtonBaseStyles};
        background-color: ${colors.bismark};
      `;
  }
});

export const SecondaryButton = styled(PrimaryButton)(
  ({ theme: { colors } }) => `
    color: ${colors.primary};
    background-color: ${colors.transparent};
    padding: 0;
  `,
);

export const SubmitButton = styled(PrimaryButton)(
  ({ isLoading }) => `
  cursor: ${isLoading ? 'wait' : 'pointer'};
`,
);

export const DropdownButton = styled.button<any>`
  ${TitleStyles};
  ${ButtonBaseStyles};
`;

export const Link = styled.a<any>`
  ${TitleStyles};
  text-decoration: none;
  cursor: pointer;
`;

export const Label = styled.label<any>`
  ${TextStyles};
`;

export const Error = styled.p<any>`
  ${TitleStyles};
  width: 100%;
  color: ${({ theme: { colors } }): string => colors.red};
  margin-bottom: 20px;
`;
