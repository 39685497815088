// libraries
import React, { useCallback } from 'react';

// custom components
import NotificationModal from './NotificationModal';

// hooks
import useModal from 'hooks/useModal';

interface NotificationModalProps {
  description: string;
  buttonLabel: string;
  onButtonClick?: VoidFunction;
}

const NotificationModalContainer = ({
  description,
  buttonLabel,
  onButtonClick,
}: NotificationModalProps): JSX.Element => {
  const { handleModalClose } = useModal();

  const handleButtonClick = useCallback(() => {
    if (onButtonClick) {
      onButtonClick();
    }
    handleModalClose();
  }, [onButtonClick, handleModalClose]);

  return (
    <NotificationModal
      description={description}
      buttonLabel={buttonLabel}
      onCloseIconClick={handleModalClose}
      onButtonClick={handleButtonClick}
    />
  );
};

export default NotificationModalContainer;
