// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { ConsultationTypes } from 'types';
import { AddAppointmentData } from './types';

export const postCancelBooking = (
  clientId: string,
  providerId: string,
  time: string,
  timeZone: string,
  consultationType: ConsultationTypes,
  token: string,
): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'booking/cancel-appointment',
    { clientId, providerId, slot: { startDate: time, timeZone, range: '01:00:00', consultationType } },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const postAddAppointment = (data: AddAppointmentData, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(`booking/add-appointment`, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default { postAddAppointment };
