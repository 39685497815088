// types
import { CreateAccountData } from './types';

export const convertCreateAccountValuesToDataModel = (
  values: CreateAccountData,
  providerFirstName: string,
  providerLastName: string,
  termsOfUse: any,
  privacyPolicy: any,
  hipaaAuthorization: any,
): any => ({
  email: values.email,
  firstName: values.firstName,
  lastName: values.lastName,
  password: values.password,
  termsAccepted: values.termsAccepted,
  providerFirstName,
  providerLastName,
  signedDocuments: values.termsAccepted
    ? [
        {
          name: termsOfUse && termsOfUse?.name,
          version: termsOfUse && termsOfUse?.version,
        },
        {
          name: privacyPolicy && privacyPolicy?.name,
          version: privacyPolicy && privacyPolicy?.version,
        },
        {
          name: hipaaAuthorization && hipaaAuthorization?.name,
          version: hipaaAuthorization && hipaaAuthorization?.version,
        },
      ]
    : undefined,
});
