// libraries
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// custom components
import Form from 'components/Form';
import Select from 'components/Form/Select/Select';

// styled components
import { H2 } from 'shared/typography';
import { SubmitButton, Error } from 'shared/styledComponents';
import { FullWidthSelectStyles, PairedInputs, StyledInput } from '../../SignUpWithFooterContactForms/sharedStyles';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const Heading = styled(H2)`
  font-size: 40px;
  line-height: 50px;
  color: #fff;
  margin-bottom: 20px;
`;

const InputWrapper = styled.div`
  margin-bottom: 10px;
`;

const FullWidthInput = styled(StyledInput)`
  width: 100%;
`;

const PairedInputsMargin = styled(PairedInputs)`
  margin-bottom: 20px;
`;

const PrivacyText = styled.p(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.canelaThin};
    color: ${colors.primary};
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    margin-top: 7px;
  `,
);

const PrivacyTextLink = styled(PrivacyText)`
  text-decoration: underline;
  cursor: pointer;
`;

const StyledInputContainer = styled.div`
  width: calc(50% - 10px);
`;

const InputError = styled(Error)`
  margin-top: 10px;
  margin-bottom: 0;
  padding-left: 10px;
  font-size: 12px;
  line-height: 12px;
`;

interface EmailFormProps {
  stageOptions?: any;
  serviceOptions?: any;
  onSubmit: (values: any) => void;
  formValidation: (values: any) => any;
  submitError: string | null;
}

const EmailForm = ({
  stageOptions,
  serviceOptions,
  onSubmit,
  formValidation,
  submitError,
}: EmailFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} validate={formValidation}>
    {({ submitting, submitFailed, submitErrors, hasValidationErrors, errors }: FormRenderProps): JSX.Element => (
      <div>
        <Heading>Join our community to receive 10% off your first purchase!</Heading>
        <InputWrapper>
          <Select
            name="interests"
            customStyles={FullWidthSelectStyles}
            options={serviceOptions}
            placeholder={<div>Interested in</div>}
            isMulti
          />
        </InputWrapper>
        <InputWrapper>
          <Select
            name="stage"
            customStyles={FullWidthSelectStyles}
            options={stageOptions}
            placeholder={<div>Stage</div>}
          />
        </InputWrapper>
        <InputWrapper>
          <FullWidthInput name="email" type="email" placeholder="Email Address" size="small" />
          {submitFailed && hasValidationErrors && !!errors.email && (
            <InputError size="small">{`${getValue('email', validationMessages)} ${errors['email']}`}</InputError>
          )}
        </InputWrapper>
        <PairedInputsMargin>
          <StyledInputContainer>
            <FullWidthInput name="firstName" type="text" placeholder="First Name" size="small" />
            {submitFailed && hasValidationErrors && !!errors.firstName && (
              <InputError size="small">{`${getValue('firstName', validationMessages)} ${
                errors['firstName']
              }`}</InputError>
            )}
          </StyledInputContainer>
          <StyledInputContainer>
            <FullWidthInput name="lastName" type="text" placeholder="Last Name" size="small" />
            {submitFailed && hasValidationErrors && !!errors.lastName && (
              <InputError size="small">{`${getValue('lastName', validationMessages)} ${
                errors['lastName']
              }`}</InputError>
            )}
          </StyledInputContainer>
        </PairedInputsMargin>
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <SubmitButton isLoading={submitting} type="submit" size="small">
          Submit
        </SubmitButton>
        {submitError && <InputError>{submitError}</InputError>}
        <PrivacyText>
          By providing your email address you agree to our&nbsp;
          <PrivacyTextLink as={Link} to="/privacy-policy" target="_blank">
            Privacy Policy
          </PrivacyTextLink>
          &nbsp;and&nbsp;
          <PrivacyTextLink as={Link} to="/terms-of-use" target="_blank">
            Terms of Service.
          </PrivacyTextLink>
        </PrivacyText>
      </div>
    )}
  </Form>
);

export default EmailForm;
