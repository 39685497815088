// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { PrimaryButton, Flex } from 'shared/styledComponents';
import { Title } from 'shared/typography';

// custom components
import Icon from 'components/Icon';

// styles
import devices from 'theme/devices';

const Wrapper = styled.div`
  max-width: 50%;
  background-color: ${({ theme: { colors } }): string => colors.white};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  padding: 30px 50px 70px;

  @media ${devices.mobile} {
    max-width: 100%;
    align-self: center;
  }
`;

const Description = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-bottom: 20px;
  text-align: center;
`;

const IconWrapper = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 60px;
`;

const CloseIcon = styled(Icon)<any>`
  cursor: pointer;
`;

const ButtonWrapper = styled(Flex)`
  justify-content: center;
`;

interface NotificationModalProps {
  description: string;
  buttonLabel: string;
  onCloseIconClick: VoidFunction;
  onButtonClick: VoidFunction;
}

const NotificationModal = ({
  description,
  buttonLabel,
  onButtonClick,
  onCloseIconClick,
}: NotificationModalProps): JSX.Element => (
  <Wrapper>
    <IconWrapper>
      <CloseIcon type="close" width="20px" height="20px" onClick={onCloseIconClick} />
    </IconWrapper>
    <Description>{description}</Description>
    <ButtonWrapper>
      <PrimaryButton size="small" onClick={onButtonClick}>
        {buttonLabel}
      </PrimaryButton>
    </ButtonWrapper>
  </Wrapper>
);

export default NotificationModal;
