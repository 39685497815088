// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

export const getStripeKey = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('payment/stripe-key', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postStripeClientCustomer = (userToken: string, paymentMethodId?: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/client-customer',
    { paymentMethodId },
    {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    },
  );

const postPayAppointment = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('payment/appointment-payment', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postAttachNewPaymentMethod = (
  data: { paymentMethodId: string; paymentType: string },
  userToken: string,
): Promise<AxiosResponse<any>> =>
  apiRequest.post('payment/attach-payment-method', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { getStripeKey, postStripeClientCustomer, postPayAppointment, postAttachNewPaymentMethod };
