import { css } from 'styled-components';

export const TitleStyles = css<any>(({ size, iswhitecolor, theme: { colors, fonts, devices } }) => {
  switch (size) {
    case 'small':
      return `
          font-family: ${fonts.wigrumLight};
          font-size: 16px;
          font-weight: normal;
          line-height: 19px;
          color: ${iswhitecolor ? colors.white : colors.primary};
          @media ${devices.mobile} {
            font-size: 14px;
            line-height: 18px;
          }
        `;
    case 'medium':
      return `
          font-family: ${fonts.wigrumLight};
          font-size: 20px;
          font-weight: normal;
          line-height: 26px;
          color: ${colors.primary};
          @media ${devices.mobile} {
            font-size: 16px;
            line-height: 24px;
          }
        `;
    default:
      return `
          font-family: ${fonts.wigrumLight};
          font-size: 22px;
          font-weight: normal;
          line-height: 37px;
          color: ${colors.primary};
          @media ${devices.mobile} {
            font-size: 16px;
            line-height: 24px;
          }
        `;
  }
});

export const TextStyles = css<any>(({ size, theme: { colors, fonts } }) => {
  switch (size) {
    case 'small':
      return `
        font-family: ${fonts.wigrumLight};
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        color: ${colors.lightBrown};
        `;
    default:
      return `
        font-family: ${fonts.wigrumLight};
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        color: ${colors.lightBrown};
        `;
  }
});

export const ButtonBaseStyles = css`
  cursor: pointer;
  border: 0;
  outline: none;
  background-color: transparent;
  text-decoration: none;
`;
