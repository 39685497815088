// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { DataModelTimeSlot } from './types';

const getAvailableTime = (providerId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`provider/available-times?providerId=${providerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getPrevAvailability = (token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/availability', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const postAvailableTimeSlots = (
  providerId: string,
  availableTimeSlots: DataModelTimeSlot[],
  token: string,
): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    `provider/available-times`,
    { providerId, slots: availableTimeSlots },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const updateAvailableTimeSlots = (
  providerId: string,
  availableTimeSlots: DataModelTimeSlot[],
  token: string,
): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    `provider/update-available-times`,
    { providerId, slots: availableTimeSlots },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const deleteAvailableTimeSlots = (date: string, timeZone: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    `provider/delete-availability`,
    { date, timeZone },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export default {
  getAvailableTime,
  getPrevAvailability,
  postAvailableTimeSlots,
  updateAvailableTimeSlots,
  deleteAvailableTimeSlots,
};
