// libraries
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

// styled components
import { PrimaryButton } from 'shared/styledComponents';
import { Title } from 'shared/typography';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import AppointmentsLayout from './AppointmentsLayout';

// hooks
import useResize from 'hooks/useResize';

const SuccessTitle = styled(Title)`
  margin-bottom: 20px;
`;

const Heading = styled(Title)`
  margin-bottom: 50px;
`;

const AvailabilitySuccess = (): JSX.Element => {
  const { isDesktop } = useResize();

  const history = useHistory();

  const handleAddMoreButtonClick = useCallback(() => {
    history.push('/provider/dashboard/appointments/availability');
  }, [history]);

  return (
    <ProviderDashboardLayout>
      <AppointmentsLayout>
        {isDesktop && <Heading>Input Availability</Heading>}
        <>
          <SuccessTitle>Your appointments have been added successfully.</SuccessTitle>
          <PrimaryButton onClick={handleAddMoreButtonClick}>Add More</PrimaryButton>
        </>
      </AppointmentsLayout>
    </ProviderDashboardLayout>
  );
};

export default AvailabilitySuccess;
