// libraries
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

// custom components
import UpcomingAppointments from './components/UpcomingAppointments';

// api
import api from './api';
import { postCancelBooking } from 'modules/BookingFlow/api';

// selectors
import { selectUser } from 'modules/User/selectors';

// types
import { ConsultationTypes } from 'types';

// constants
import { UserRoles } from 'constants/user';
import useModal from 'hooks/useModal';

// hooks
import useResize from 'hooks/useResize';

const UpcomingAppointmentsContainer = (): JSX.Element => {
  const [isUpcomingAppointmentsLoading, setIsUpcomingAppointmentsLoading] = useState(false);

  const [isPassedAppointmentsLoading, setIsPassedAppointmentsLoading] = useState(false);

  const [upcomingAppointments, setUpcomingAppointments] = useState(undefined);

  const [passedAppointments, setPassedAppointments] = useState(undefined);

  const { handleModalOpen } = useModal();

  const { isDesktop } = useResize();

  const {
    userId,
    token: { accessToken },
    role,
  } = useSelector(selectUser);

  const fetchUpcomingAppointments = useCallback(async () => {
    setIsUpcomingAppointmentsLoading(true);
    try {
      const fechedUpcomingAppointments =
        role === UserRoles.CLIENT
          ? await api.getClientUpcomingAppointments(accessToken)
          : await api.getProviderUpcomingAppointments(userId, accessToken);

      setUpcomingAppointments(fechedUpcomingAppointments.data);
    } catch (error) {
      console.log(error);
    }
    setIsUpcomingAppointmentsLoading(false);
  }, [role, accessToken, userId]);

  const fetchPassedAppointments = useCallback(async () => {
    setIsPassedAppointmentsLoading(true);
    try {
      const fechedPassedAppointments =
        role !== UserRoles.CLIENT ? await api.getProviderPassedAppointments(accessToken) : undefined;

      setPassedAppointments(fechedPassedAppointments?.data);
    } catch (error) {
      console.log(error);
    }
    setIsPassedAppointmentsLoading(false);
  }, [role, accessToken]);

  useEffect(() => {
    fetchUpcomingAppointments();
    fetchPassedAppointments();
  }, [fetchUpcomingAppointments, fetchPassedAppointments]);

  const handleAppointmentCancel = useCallback(
    (
      clientOrProviderId: string,
      day: string,
      time: string,
      timeZone: string,
      consultationType: ConsultationTypes,
    ) => async (): Promise<any> => {
      try {
        if (role === UserRoles.CLIENT) {
          await postCancelBooking(userId, clientOrProviderId, time, timeZone, consultationType, accessToken);
        } else {
          await postCancelBooking(clientOrProviderId, userId, time, timeZone, consultationType, accessToken);
        }
        setUpcomingAppointments((state: any) => {
          const filteredUpcomingAppointments: any = [];

          state.forEach(({ startDate, consultations }: any) => {
            if (moment(startDate).isSame(day, 'day')) {
              const filteredConsultations = consultations.filter(
                (consultation: any) => moment(consultation.time).hour() !== moment(time).hour(),
              );
              if (filteredConsultations.length) {
                filteredUpcomingAppointments.push({
                  startDate: startDate,
                  consultations: filteredConsultations,
                });
              }
            } else {
              filteredUpcomingAppointments.push({ startDate, consultations });
            }
          });
          return filteredUpcomingAppointments;
        });
      } catch (error) {
        console.log(error);
      }
    },
    [role, userId, accessToken],
  );

  return (
    <UpcomingAppointments
      isUpcomingAppointmentsLoading={isUpcomingAppointmentsLoading}
      isPassedAppointmentsLoading={isPassedAppointmentsLoading}
      passedAppointments={passedAppointments}
      upcomingAppointments={upcomingAppointments}
      handleModalOpen={handleModalOpen}
      onAppointmentCancel={handleAppointmentCancel}
      role={role}
      isDesktop={isDesktop}
    />
  );
};

export default UpcomingAppointmentsContainer;
