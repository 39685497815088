// libraries
import React from 'react';
import styled from 'styled-components';
import { FormRenderProps } from 'react-final-form';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import FormSuccess from 'components/Form/FormSuccess';
import Loader from 'components/Loader';

// styled components
import { Text } from 'shared/typography';
import { ColumnedFlex, SubmitButton, Error } from 'shared/styledComponents';

// styles
import { TextStyles } from 'shared/styles';

// types
import { ResetPasswordData } from './types';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const FormContentWrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const LoaderWrapper = styled.div`
  width: fit-content;
  margin: 35px auto;
`;

const InputsWrapper = styled(ColumnedFlex)`
  align-items: flex-end;
  margin-bottom: 15px;
  width: 100%;
  max-width: 280px;
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  outline: none;
  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
  :first-child {
    margin-bottom: 10px;
  }
`;

const StyledSubmitButton = styled(SubmitButton)<any>`
  margin-bottom: 20px;
`;

const CloseExpiredTokenButton = styled(SubmitButton)<any>`
  width: fit-content;
`;

const StyledText = styled(Text)`
  margin-bottom: 35px;
`;

interface ResetPasswordFormProps {
  isTokenExpired: boolean;
  isTokenInfoLoading: boolean;
  onSubmit: (values: ResetPasswordData) => void;
  handleModalClose: VoidFunction;
  formValidation: (values: ResetPasswordData) => any;
}

const ResetPasswordForm = ({
  onSubmit,
  handleModalClose,
  formValidation,
  isTokenExpired,
  isTokenInfoLoading,
}: ResetPasswordFormProps): JSX.Element => (
  <Wrapper>
    <Form onSubmit={onSubmit} validate={formValidation}>
      {({
        submitting,
        submitSucceeded,
        submitFailed,
        submitErrors,
        hasValidationErrors,
        errors,
      }: FormRenderProps): JSX.Element =>
        submitSucceeded ? (
          <FormSuccess heading="Your password has been reseted successfully" onClose={handleModalClose} />
        ) : (
          <FormContentWrapper>
            {isTokenInfoLoading ? (
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            ) : (
              <>
                {isTokenExpired ? (
                  <ColumnedFlex>
                    <StyledText>The link for resetting password has expired or invalid</StyledText>
                    <CloseExpiredTokenButton size="small" onClick={handleModalClose}>
                      close
                    </CloseExpiredTokenButton>
                  </ColumnedFlex>
                ) : (
                  <>
                    <InputsWrapper>
                      <StyledInput name="password" type="password" placeholder="New Password" size="small" />
                      <StyledInput
                        name="confirmPassword"
                        type="password"
                        placeholder="Confirm New Password"
                        size="small"
                      />
                    </InputsWrapper>
                    {submitFailed && hasValidationErrors && (
                      <Error size="small">
                        {!!Object.keys(errors).length &&
                          `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
                      </Error>
                    )}
                    {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
                    <StyledSubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
                      Reset
                    </StyledSubmitButton>
                  </>
                )}
              </>
            )}
          </FormContentWrapper>
        )
      }
    </Form>
  </Wrapper>
);

export default ResetPasswordForm;
