// libraries
import { AxiosResponse } from 'axios';
import qs from 'qs';

// api
import apiRequest from 'api/restapi';

// types
import { ResetPasswordData } from './types';

const postResetPassword = (data: ResetPasswordData): Promise<AxiosResponse<any>> =>
  apiRequest.post(`user/reset-password`, data);

const getIfResetTokenExpired = (email: any, token: any): Promise<AxiosResponse<any>> => {
  const queryParams = qs.stringify({ token, email });

  return apiRequest.get(`user/if-reset-token-expired?${queryParams}`);
};

export default { postResetPassword, getIfResetTokenExpired };
