//libraries
import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

// custom components
import Layout from 'components/Layout';
import LogInForm from 'modules/LogInOrCreateAccount/LogIn/LogInForm';
import Loader from 'components/Loader';

// modules
import CreateProviderAccountForm from 'modules/ProviderOnboarding/CreateProviderAccountForm';
import HipaaForm from 'modules/ProviderOnboarding/Hipaa';
import Docusign from 'modules/ProviderOnboarding/Docusign';
import SubscriptionsForm from 'modules/ProviderOnboarding/Subscriptions';
import StripePayment from 'modules/ProviderOnboarding/Payments/Stripe';

// hooks
import useResize from 'hooks/useResize';

// styled components
import { Flex, Frame, ColumnedFlex, SubmitButton } from 'shared/styledComponents';
import { H2, Title } from 'shared/typography';

// assets
import connectionExamplesImageSrc from 'assets/images/connect-examples-section.svg';

// types
import { ProviderOnboardingStepTypes, ProviderOnboardingStatusData } from './types';

// styles
import devices from 'theme/devices';

const MainSectionWrapper = styled(Flex)`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
  padding: 40px 0;
  margin-bottom: 85px;
`;

const MainSection = styled(Flex)``;

const HeadingWrapper = styled(ColumnedFlex)`
  max-width: 675px;
`;

const Heading = styled(H2)`
  margin-bottom: 25px;
`;

const Subtitle = styled(Title)`
  font-size: 18px;
`;

const ImageWrapper = styled.div`
  position: relative;
`;

const Image = styled.img`
  position: absolute;
  width: 325px;
  top: -25px;
  left: 125px;
`;

const StepWrapper = styled<any>(ColumnedFlex)(
  ({ isStepOpened, theme: { colors } }: any) => `
    width: 100%;
    align-items: flex-start;
    border-bottom: 1px solid ${colors.alto};
    padding-bottom: ${isStepOpened ? '60px' : '45px'};
    margin-bottom: 50px;
    transition: padding-bottom ease 0.3s;
  `,
);

const StepHeading = styled(Title)(
  ({ isStepOpened }: any) => `
    margin-bottom: ${isStepOpened ? '35px' : 0};
  `,
);

const ClickableStepHeading = styled(Title)<any>(
  ({ isStepOpened }: any) => `
    margin-bottom: ${isStepOpened ? '35px' : 0};
    transition: margin-bottom ease 0.3s;
    cursor: pointer
  `,
);

const FormWrapper = styled(ColumnedFlex)<any>(
  ({ isStepOpened }: any) => `
    max-height: ${isStepOpened ? '1000px' : 0};
    overflow: hidden;
    transition: max-height ease 0.3s;

    @media ${devices.mobile} {
      max-height: unset;
    }
`,
);

interface ProviderOnboardingProps {
  tokenStatus: any;
  isTokenStatusLoading: boolean;
  providerOnboardingStatus: ProviderOnboardingStatusData;
  openedStepType: ProviderOnboardingStepTypes;
  setOpenedStepType: Dispatch<SetStateAction<ProviderOnboardingStepTypes>>;
  onStepClick: (stepType: ProviderOnboardingStepTypes) => () => void;
  isHipaaStepSucceeded: boolean;
  setIsHipaaStepSucceeded: Dispatch<SetStateAction<boolean>>;
  isLoggedIn: boolean;
  handleModalOpen: (title: string, data?: object) => () => void;
  handleLoginSubmit: any;
  loginFormValidation: any;
  isResendEmailLoading: boolean;
  isResendEmailSuccess: boolean;
  onResendEmailButtonClick: any;
}

const ProviderOnboarding = ({
  tokenStatus,
  isTokenStatusLoading,
  providerOnboardingStatus,
  openedStepType,
  setOpenedStepType,
  onStepClick,
  isHipaaStepSucceeded,
  setIsHipaaStepSucceeded,
  isLoggedIn,
  handleModalOpen,
  handleLoginSubmit,
  loginFormValidation,
  isResendEmailLoading,
  isResendEmailSuccess,
  onResendEmailButtonClick,
}: ProviderOnboardingProps): JSX.Element => {
  const { isCoveredUnderHIPAA, productSubscription, signedDate } = providerOnboardingStatus;

  const { isExpired, isPasswordCreated } = tokenStatus;

  const { isDesktop } = useResize();

  return (
    <Layout>
      <MainSectionWrapper>
        <Frame>
          <MainSection>
            <HeadingWrapper>
              <Heading>Robyn Provider Onboarding</Heading>
              <Subtitle>
                You are what makes Robyn so special. We are thrilled to partner with you. Please fill out these next
                steps to become a Robyn provider.
              </Subtitle>
            </HeadingWrapper>
            {isDesktop && (
              <ImageWrapper>
                <Image src={connectionExamplesImageSrc} alt="onboarding" />
              </ImageWrapper>
            )}
          </MainSection>
        </Frame>
      </MainSectionWrapper>

      {isExpired && !isPasswordCreated ? (
        <Frame>
          {isResendEmailSuccess ? (
            <Title>Email have been sent</Title>
          ) : (
            <SubmitButton onClick={onResendEmailButtonClick} isLoading={isResendEmailLoading}>
              Please resend create profile email
            </SubmitButton>
          )}
        </Frame>
      ) : (
        <Frame>
          <StepWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.CreateAccount}>
            {!isTokenStatusLoading ? (
              <>
                {!isPasswordCreated && (
                  <StepHeading isStepOpened={openedStepType === ProviderOnboardingStepTypes.CreateAccount}>
                    Sign Up As a Provider
                  </StepHeading>
                )}
                {!isLoggedIn && !isPasswordCreated && (
                  <FormWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.CreateAccount}>
                    {openedStepType === ProviderOnboardingStepTypes.CreateAccount && (
                      <CreateProviderAccountForm setOpenedStepType={setOpenedStepType} />
                    )}
                  </FormWrapper>
                )}

                {isPasswordCreated && !isLoggedIn && (
                  <LogInForm
                    onSubmit={handleLoginSubmit}
                    handleModalOpen={handleModalOpen}
                    formValidation={loginFormValidation}
                  />
                )}
                {isPasswordCreated && isLoggedIn && <Title>Log In As a Provider</Title>}
              </>
            ) : (
              <Loader />
            )}
          </StepWrapper>
          <StepWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Hipaa}>
            {isLoggedIn ? (
              <>
                <ClickableStepHeading
                  isStepOpened={openedStepType === ProviderOnboardingStepTypes.Hipaa}
                  onClick={onStepClick(ProviderOnboardingStepTypes.Hipaa)}
                >
                  HIPAA Eligibility
                </ClickableStepHeading>
                <FormWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Hipaa}>
                  {openedStepType === ProviderOnboardingStepTypes.Hipaa && (
                    <HipaaForm
                      setOpenedStepType={setOpenedStepType}
                      setIsHipaaStepSucceeded={setIsHipaaStepSucceeded}
                      isCoveredUnderHIPAA={isCoveredUnderHIPAA}
                    />
                  )}
                </FormWrapper>
              </>
            ) : (
              <Title>HIPAA Elegibility</Title>
            )}
          </StepWrapper>
          <StepWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.BaaAgreement}>
            {isLoggedIn && isHipaaStepSucceeded ? (
              <>
                <ClickableStepHeading
                  isStepOpened={openedStepType === ProviderOnboardingStepTypes.BaaAgreement}
                  onClick={onStepClick(ProviderOnboardingStepTypes.BaaAgreement)}
                >
                  Sign our Business Associate Agreement (BAA)
                </ClickableStepHeading>
                <FormWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.BaaAgreement}>
                  {openedStepType === ProviderOnboardingStepTypes.BaaAgreement && (
                    <Docusign setOpenedStepType={setOpenedStepType} signedDate={signedDate} />
                  )}
                </FormWrapper>
              </>
            ) : (
              <Title>Sign our Business Associate Agreement (BAA)</Title>
            )}
          </StepWrapper>
          <StepWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Subscription}>
            {isLoggedIn ? (
              <>
                <ClickableStepHeading
                  isStepOpened={openedStepType === ProviderOnboardingStepTypes.Subscription}
                  onClick={onStepClick(ProviderOnboardingStepTypes.Subscription)}
                >
                  Select your Subscription
                </ClickableStepHeading>
                <FormWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Subscription}>
                  {openedStepType === ProviderOnboardingStepTypes.Subscription && (
                    <SubscriptionsForm
                      setOpenedStepType={setOpenedStepType}
                      productSubscriptionInitialId={productSubscription && productSubscription.id}
                    />
                  )}
                </FormWrapper>
              </>
            ) : (
              <Title>Select your Subscription</Title>
            )}
          </StepWrapper>
          {/* <StepWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Payment}>
            {isLoggedIn ? (
              <>
                <ClickableStepHeading
                  isStepOpened={openedStepType === ProviderOnboardingStepTypes.Payment}
                  onClick={onStepClick(ProviderOnboardingStepTypes.Payment)}
                >
                  Payment
                </ClickableStepHeading>
                <FormWrapper isStepOpened={openedStepType === ProviderOnboardingStepTypes.Payment}>
                  {openedStepType === ProviderOnboardingStepTypes.Payment && <StripePayment />}
                </FormWrapper>
              </>
            ) : (
              <Title>Payment</Title>
            )}
          </StepWrapper> */}
        </Frame>
      )}
    </Layout>
  );
};

export default ProviderOnboarding;
