// libraries
import api from './api';

// types
import { AxiosResponse } from 'axios';
import { ConsultationTypes } from 'types';
import { OfferedProduct } from 'pages/ProviderProfile/types';

export const getProductsOffered = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.getProductsOffered(userToken);

export const postProductsOffered = async (
  productsOffered: any,
  formValues: any,
  userToken: string,
): Promise<AxiosResponse<any>> => {
  const data = {
    productsOffered: !!productsOffered.length
      ? productsOffered.map(({ id, productName, consultationType, sortOrder }: OfferedProduct) => ({
          id,
          productName,
          consultationType,
          sortOrder,
          pricing: {
            price:
              consultationType === ConsultationTypes.FreeConsultation
                ? '0'
                : formValues[`${ConsultationTypes[consultationType]}Price`],
            duration: '1:00:00',
          },
        }))
      : undefined,
  };

  return await api.postProductsOffered(data, userToken);
};
