// libraries
import { useState, useCallback } from 'react';

interface UseUploadImage {
  imageData: {
    convertedImage: any;
    uploadedImageName: string | undefined;
    isCorrectFileType: boolean;
    isCorrectFileSize: boolean;
  };
  onChangeUploadInput: (e: any) => void;
}

const useUploadImage = (): UseUploadImage => {
  const [imageData, setImageData] = useState({
    convertedImage: undefined,
    uploadedImageName: undefined,
    isCorrectFileType: true,
    isCorrectFileSize: true,
  });

  const onChangeUploadInput = useCallback((e: any) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    const maxFileSize = 650000;

    if (file) {
      const isCorrectFileType = !!file.type.match('image.*');
      const isCorrectFileSize = file.size <= maxFileSize;

      if (isCorrectFileType && isCorrectFileSize ) {
        reader.onload = (upload: any) => {
          setImageData({
            convertedImage: upload.target.result.split(',')[1],
            uploadedImageName: file.name,
            isCorrectFileType: isCorrectFileType,
            isCorrectFileSize: isCorrectFileSize,
          });
        };

        reader.readAsDataURL(file);
      } else {
        setImageData((prevState) => ({
          ...prevState,
          uploadedImageName: file.name,
          isCorrectFileType: isCorrectFileType,
          isCorrectFileSize: isCorrectFileSize,
        }));
      }
    }
  }, []);

  return { imageData, onChangeUploadInput };
};

export default useUploadImage;
