// libraries
import React, { ReactNode, useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';

// hooks
import useResize from 'hooks/useResize';

// custom components
import AppointmentsLayout from './AppointmentsLayout';

// types
import { typeOfAppointmentSections } from '../types';

// utils
import { getValue } from 'utils/getValue';

interface AppointmentsLayoutContainerProps {
  children: ReactNode;
}

const AppointmentsLayoutContainer = ({ children }: AppointmentsLayoutContainerProps): JSX.Element => {
  const location = useLocation();
  const splittedLocationUrl = location.pathname.split('/');
  const name = splittedLocationUrl[splittedLocationUrl.length - 1] as any;

  const [openedSectionType, setOpenedSectionType] = useState(getValue(name, typeOfAppointmentSections));

  const { isDesktop } = useResize();

  const history = useHistory();

  useEffect(() => {
    const splittedLocationUrl = location.pathname.split('/');
    const name = splittedLocationUrl[splittedLocationUrl.length - 1] as any;
    setOpenedSectionType(getValue(name, typeOfAppointmentSections));
  }, [location.pathname]);

  const handleSectionTypeClick = useCallback(
    (sectionType: any) => () => {
      setOpenedSectionType((state: any) => (state === sectionType ? '' : sectionType));
    },
    [],
  );

  const handleRedirect = useCallback(
    (url: string) => () => {
      history.push(url);
    },
    [history],
  );

  return (
    <AppointmentsLayout
      isDesktop={isDesktop}
      openedSectionType={openedSectionType}
      handleSectionTypeClick={handleSectionTypeClick}
      handleRedirect={handleRedirect}
    >
      {children}
    </AppointmentsLayout>
  );
};

export default AppointmentsLayoutContainer;
