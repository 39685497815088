// libraries
import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';

// styles
import { TitleStyles } from 'shared/styles';

const StyledLabel = styled.label<any>(
  ({ size }) => `
    ${TitleStyles};
    display: block;
    position: relative;
    width:  ${size === 'small' ? '16px' : '26px'};
    height: ${size === 'small' ? '16px' : '26px'};
    padding-left: 35px;
    cursor: pointer;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  `,
);

const StyledInput = styled.input<any>`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked ~ span:hover {
    background-color: transparent;
  }

  :checked ~ span:after {
    display: block;
  }
`;

const StyledCheckmark = styled.span<any>(
  ({ size, theme: { colors } }) => `
    position: absolute;
    top: 0;
    left: 0;
    height: ${size === 'small' ? '16px' : '26px'};
    width: ${size === 'small' ? '16px' : '26px'};
    border: 1px solid ${colors.alto};
    border-radius: 50%;

    :after {
      content: '';
      position: absolute;
      display: none;
      top: ${size === 'small' ? '3px' : '6px'};
      left: ${size === 'small' ? '3px' : '6px'};
      width:${size === 'small' ? '8px' : '12px'};
      height:${size === 'small' ? '8px' : '12px'};
      border-radius: 50%;
      background-color: ${colors.blueBayoux};
    }

    :hover {
      background-color: ${colors.alto};
    }
  `,
);

interface RadioButtonProps {
  name: string;
  value: any;
  checked?: boolean;
  size?: string;
  onClick?: any;
}

const RadioButton = ({ name, value, size, onClick }: RadioButtonProps): JSX.Element => (
  <Field name={name} type="radio" value={value} onClick={onClick}>
    {({ input }) => (
      <StyledLabel size={size}>
        <StyledInput {...input} />
        <StyledCheckmark size={size} />
      </StyledLabel>
    )}
  </Field>
);

export default RadioButton;
