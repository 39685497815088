// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { CreateProviderAccountData } from './types';

const postCreateProviderAccount = (data: CreateProviderAccountData): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/create-account', data);

export default { postCreateProviderAccount };
