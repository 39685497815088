import { useState, useCallback } from 'react';

interface UseCalendar {
  calendarValue: any;
  setCalendarValue: any;
  calendarMultipleValues: any;
  setCalendarMultipleValues: any;
  isCalendarOpened: boolean;
  handleCalendarToggle: VoidFunction;
  handleCalendarOpen: VoidFunction;
  handleCalendarClose: VoidFunction;
}

export const useCalendar = (): UseCalendar => {
  const [isCalendarOpened, setIsCalendarOpened] = useState(false);

  const [calendarValue, setCalendarValue] = useState(undefined);

  const [calendarMultipleValues, setCalendarMultipleValues] = useState([]);

  const handleCalendarToggle = useCallback(() => {
    setIsCalendarOpened((state) => !state);
  }, []);

  const handleCalendarOpen = useCallback(() => {
    setIsCalendarOpened(true);
  }, []);

  const handleCalendarClose = useCallback(() => {
    setIsCalendarOpened(false);
  }, []);

  return {
    calendarValue,
    setCalendarValue,
    calendarMultipleValues,
    setCalendarMultipleValues,
    isCalendarOpened,
    handleCalendarToggle,
    handleCalendarOpen,
    handleCalendarClose,
  };
};
