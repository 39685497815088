// libraries
import React from 'react';
import styled from 'styled-components';
import { Field } from 'react-final-form';

const StyledLabel = styled.label<any>`
  display: block;
  position: relative;
  width: 22px;
  height: 22px;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledInput = styled.input<any>`
  position: absolute;
  opacity: 0;
  cursor: pointer;

  :checked ~ span:after {
    display: block;
  }
`;

const StyledCheckmark = styled.span<any>`
  position: absolute;
  top: 0;
  left: 0;
  width: 22px;
  height: 22px;
  background-color: ${({ theme: { colors } }): string => colors.pampas};
  border-radius: 5px;

  :after {
    content: '';
    position: absolute;
    display: none;
    left: 8px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid #506d8a;
    border-width: 0 2px 2px 0;

    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  :hover {
    background-color: ${({ theme: { colors } }): string => colors.alto};
  }
`;

interface CheckboxProps {
  name: string;
  value?: any;
  onChange?: any;
  withoutForm?: boolean;
  checked?: boolean;
  type?: string;
}

const Checkbox = ({
  name,
  onChange,
  value,
  withoutForm = false,
  checked,
  type = 'checkbox',
}: CheckboxProps): JSX.Element =>
  withoutForm ? (
    <StyledLabel className="container">
      <StyledInput name={name} type="checkbox" value={value} onChange={onChange} checked={checked} />
      <StyledCheckmark />
    </StyledLabel>
  ) : (
    <Field name={name} type={type} value={value}>
      {({ input }) => (
        <StyledLabel className="container">
          <StyledInput
            {...input}
            onChange={(e: any) => {
              input.onChange(e);

              if (onChange) {
                onChange(e);
              }
            }}
            checked={checked}
          />
          <StyledCheckmark />
        </StyledLabel>
      )}
    </Field>
  );

export default Checkbox;
