// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

const postAddExternalAccount = (stripeId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'payment/add-external-account',
    { source: stripeId },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export default { postAddExternalAccount };
