// libraries
import { handleActions } from 'redux-actions';

// actions
import { setModal, clearModal } from './actions';

const defaultState = { title: '', data: undefined };

const modalReducer = handleActions(
  {
    [setModal.toString()]: (_, { payload: { title, data } }: any) => ({
      title,
      data: data ? { ...data } : undefined,
    }),
    [clearModal.toString()]: () => defaultState,
  },
  defaultState,
);

export default modalReducer;
