// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const getProductsOffered = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/products-offered', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postProductsOffered = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/products-offered', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { getProductsOffered, postProductsOffered };
