export const convertAccountValuesToDataLayer = (
  params: any,
  values: any,
  termsOfUse: any,
  privacyPolicy: any,
  providerAgreement: any,
): any => ({
  email: params.email ? params.email : values.email,
  password: values.password,
  token: params.token,
  termsAccepted: values.termsAccepted,
  signedDocuments: values.termsAccepted
    ? [
        {
          name: termsOfUse && termsOfUse?.name,
          version: termsOfUse && termsOfUse?.version,
        },
        {
          name: privacyPolicy && privacyPolicy?.name,
          version: privacyPolicy && privacyPolicy?.version,
        },
        {
          name: providerAgreement && providerAgreement?.name,
          version: providerAgreement && providerAgreement?.version,
        },
      ]
    : undefined,
});
