// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { PrimaryButton, Flex, ColumnedFlex } from 'shared/styledComponents';
import { Title, Text } from 'shared/typography';

// custom components
import Icon from 'components/Icon';

// styles
import devices from 'theme/devices';

const Wrapper = styled.div`
  max-width: 50%;
  background-color: ${({ theme: { colors } }): string => colors.white};
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.06);
  padding: 30px 50px 70px;

  @media ${devices.mobile} {
    max-width: 100%;
    align-self: center;
  }
`;

const ModalInfo = styled(ColumnedFlex)`
  justify-content: center;
`;

const ModalTitle = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-bottom: 20px;
`;

const ModalDescription = styled(Text)`
  margin-bottom: 40px;
`;

const ModalAdditionalInfo = styled(Text)`
  margin-bottom: 40px;
`;

const IconWrapper = styled(Flex)`
  justify-content: flex-end;
  margin-bottom: 60px;
`;

const CloseIcon = styled(Icon)<any>`
  cursor: pointer;
`;

const CancelButton = styled(PrimaryButton)(
  ({ theme: { colors } }) => `
    width: 120px;
    margin-right: 20px;
    color:${colors.primary};
    border: 2px solid ${colors.primary};
    background-color: ${colors.transparent};
  `,
);

const ConfirmButton = styled(PrimaryButton)`
  width: 120px;
`;

interface ConfirmationModalProps {
  title: string;
  description: string;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  additionalInfo?: string;
  onCancelButtonClick: VoidFunction;
  onConfirmButtonClick: VoidFunction;
}

const ConfirmationModal = ({
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  onCancelButtonClick,
  onConfirmButtonClick,
  additionalInfo,
}: ConfirmationModalProps): JSX.Element => (
  <Wrapper>
    <IconWrapper>
      <CloseIcon type="close" width="20px" height="20px" onClick={onCancelButtonClick} />
    </IconWrapper>
    <ModalInfo>
      <ModalTitle>{title}</ModalTitle>
      <ModalDescription>{description}</ModalDescription>
      {additionalInfo && <ModalAdditionalInfo size="small">{additionalInfo}</ModalAdditionalInfo>}
    </ModalInfo>
    <Flex>
      <CancelButton size="small" onClick={onCancelButtonClick}>
        {cancelButtonLabel}
      </CancelButton>
      <ConfirmButton size="small" onClick={onConfirmButtonClick}>
        {confirmButtonLabel}
      </ConfirmButton>
    </Flex>
  </Wrapper>
);

export default ConfirmationModal;
