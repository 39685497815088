import {
  required,
  validateMinLength,
  validateEmail,
  validatePasswordsConfirmation,
  validatePhoneNumber,
  validatePasswordEquality,
  validateWebsiteLinkEquality,
  validateFacebookLinkEquality,
  validateInstagramLinkEquality,
  validateLinkedinLinkEquality,
  validateTimeZone,
  validateDropdown,
} from './helpers';

export const VALIDATION_TYPES = {
  MAX_LENGTH: 'max_length',
  MIN_LENGTH: 'min_length',
  EMAIL: 'email',
  NUMBER: 'number',
  REQUIRED: 'required',
  MIN_VALUE: 'min_value',
  PASSWORD_CONFIRMATION: 'password_confirmation',
  PHONE_NUMBER: 'phone_number',
  PASSWORD_EQUALITY: 'password',
  WEBSITE_LINK_FORMAT: 'website_link',
  FACEBOOK_LINK_FORMAT: 'facebook_link',
  INSTAGRAM_LINK_FORMAT: 'instagram_link',
  LINKEDIN_LINK_FORMAT: 'linkedin_link',
  TIMEZONE: 'time_zone',
  DROPDOWN: 'dropdown',
};

export const VALIDATION_ERRORS = {
  TOO_SHORT: 'Too short, should be greater than',
  TOO_LONG: 'Too long, should be less than',
  INVALID_EMAIL: 'Invalid',
  REQUIRED: 'Required',
  PASSWORD_CONFIRMATION: 'not equal to password',
  INVALID_PHONE_NUMBER: 'Invalid',
  INVALID_PASSWORD: 'Invalid. Password should consist of 6-20 characters, at least 1 digit, and 1 capital letter',
  INVALID_LINK_FORMAT: 'invalid. Please provide a valid URL',
};

export const FIELD_VALIDATIONS = {
  [VALIDATION_TYPES.REQUIRED]: required,
  [VALIDATION_TYPES.MIN_LENGTH]: validateMinLength,
  [VALIDATION_TYPES.EMAIL]: validateEmail,
  [VALIDATION_TYPES.PASSWORD_CONFIRMATION]: validatePasswordsConfirmation,
  [VALIDATION_TYPES.PHONE_NUMBER]: validatePhoneNumber,
  [VALIDATION_TYPES.PASSWORD_EQUALITY]: validatePasswordEquality,
  [VALIDATION_TYPES.WEBSITE_LINK_FORMAT]: validateWebsiteLinkEquality,
  [VALIDATION_TYPES.FACEBOOK_LINK_FORMAT]: validateFacebookLinkEquality,
  [VALIDATION_TYPES.INSTAGRAM_LINK_FORMAT]: validateInstagramLinkEquality,
  [VALIDATION_TYPES.LINKEDIN_LINK_FORMAT]: validateLinkedinLinkEquality,
  [VALIDATION_TYPES.TIMEZONE]: validateTimeZone,
  [VALIDATION_TYPES.DROPDOWN]: validateDropdown,
};

export const EMAIL_FORMAT = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

export const PHONE_NUMBER_FORMAT = /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4}$/im;

export const PASSWORD_FORMAT = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/;

export const WEBSITE_LINK_FORMAT = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;

export const FACEBOOK_LINK_FORMAT = /(http(s)?:\/\/.)?(www\.)?facebook.com\/[a-zA-Z0-9(.?)?]/;

export const INSTAGRAM_LINK_FORMAT = /(http(s)?:\/\/.)?(www\.)?instagram.com\/[a-zA-Z0-9(.?)?]/;

export const LINKEDIN_LINK_FORMAT = /(http(s)?:\/\/.)?(www\.)?linkedin.com\/[a-zA-Z0-9(.?)?]/;
