// libraries
import React, { useCallback, useState, useMemo, useEffect } from 'react';

// custom components
import Email from './Email';

// api
import api from '../api';

// utils
import { validate } from 'utils/validation';
import { VALIDATION_TYPES } from 'utils/validation/types';

interface EmailContainerProps {
  accessToken: string;
}

const EmailContainer = ({ accessToken }: EmailContainerProps): JSX.Element => {
  const [isEmailLoading, setIsEmailLoading] = useState(false);

  const [email, setEmail] = useState(undefined);

  const initialValues = useMemo(() => ({ email }), [email]);

  const getEmail = useCallback(async () => {
    setIsEmailLoading(true);
    try {
      const email = await api.getEmail(accessToken);

      setEmail(email.data.email);
    } catch (err) {
      console.log('error', err);
    }
    setIsEmailLoading(false);
  }, [accessToken]);

  useEffect(() => {
    getEmail();
  }, [getEmail]);

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const onSubmit = useCallback(
    async (values: any) => {
      await api.postEmail(values.email, accessToken);

      setEmail(values.email);
    },
    [accessToken],
  );

  return (
    <Email
      onSubmit={onSubmit}
      isEmailLoading={isEmailLoading}
      initialValues={initialValues}
      formValidation={formValidation}
    />
  );
};

export default EmailContainer;
