// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { Title, Text } from 'shared/typography';
import { ColumnedFlex, PrimaryButton } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';

const DeleteAccountWrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const Heading = styled(Title)`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const StyledText = styled(Text)`
  max-width: 540px;

  @media ${devices.mobile} {
    max-width: 290px;
  }
`;

const DeleteButton = styled(PrimaryButton)`
  margin-top: 30px;
  width: fit-content;

  @media ${devices.mobile} {
    padding: 15px 16px;
    font-size: 10px;
    line-height: 13px;
    white-space: nowrap;
  }
`;

interface ChangePasswordProps {
  onDeleteAccountButtonClick: any;
  handleModalOpen: any;
}

const DeleteAccount = ({ handleModalOpen, onDeleteAccountButtonClick }: ChangePasswordProps): JSX.Element => (
  <DeleteAccountWrapper>
    <Heading>Account</Heading>
    <StyledText>
      You can delete your account. If you delete your account, you will not be able to use the services of our website
      in full measure.
    </StyledText>
    <DeleteButton
      onClick={handleModalOpen('conformationModal', {
        title: 'Delete Account',
        description: 'Are you sure you want to delete your account',
        cancelButtonLabel: 'Cancel',
        confirmButtonLabel: 'Yes',
        onConfirmButtonClick: onDeleteAccountButtonClick,
      })}
      size="small"
    >
      delete account
    </DeleteButton>
  </DeleteAccountWrapper>
);

export default DeleteAccount;
