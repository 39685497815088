// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import Loader from 'components/Loader';
import Checkbox from 'components/Form/Checkbox';
import Input from 'components/Form/Input';

// styled components
import { Text, Title } from 'shared/typography';
import { Flex, ColumnedFlex, SubmitButton, Error } from 'shared/styledComponents';

// styles
import devices from 'theme/devices';
import { TextStyles } from 'shared/styles';

// types
import { ConsultationTypes } from 'types';
import { OfferedProduct } from 'pages/ProviderProfile/types';

export const Wrapper = styled.div`
  border-bottom: 1px solid ${({ theme: { colors } }: any): string => colors.alto};
  margin-bottom: 50px;
  padding-bottom: 50px;

  :last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media ${devices.mobile} {
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`;

export const SubHeading = styled(Text)`
  margin-bottom: 30px;
`;

export const CheckboxesWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;

  @media ${devices.mobile} {
    flex-direction: column;
    align-items: start;
  }
`;

export const CheckboxWrapperAndPriceInputWrapper = styled(ColumnedFlex)`
  align-items: center;

  @media ${devices.mobile} {
    align-items: start;
    margin-bottom: 30px;
  }
`;

export const CheckboxWrapper = styled(Flex)`
  align-items: center;
`;

export const CheckboxLabel = styled(Text)`
  margin-left: 14px;
  cursor: pointer;
`;

export const Pricelabel = styled(CheckboxLabel)`
  @media ${devices.mobile} {
    margin-left: 0px;
    white-space: nowrap;
  }
`;

export const ZeroPricelabel = styled(CheckboxLabel)`
  margin-top: 7px;
`;

export const PriceInputWrapper = styled(Flex)`
  align-items: center;
  margin-top: 10px;
`;

export const PriceInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  max-width: 100px;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  padding: 7px 10px;
  margin-right: 10px;
  margin-left: 10px;
  outline: none;

  line-height: 1;

  :last-child {
    margin-right: 0;
  }

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }

  @media ${devices.mobile} {
    max-width: 310px;
    margin-right: 0;
    margin-bottom: 15px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const SuccessTitle = styled(Title)`
  margin-top: 20px;
`;

interface ProductsOfferedFormProps {
  onSubmit: (values: any) => void;
  initialValues: any;
  isProductsOfferedLoading: boolean;
  productsOffered: OfferedProduct[] | undefined;
  onProductOfferedChange: (selectedProduct: OfferedProduct) => () => void;
}

const ProductsOfferedForm = ({
  onSubmit,
  initialValues,
  productsOffered,
  onProductOfferedChange,
  isProductsOfferedLoading,
}: ProductsOfferedFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ submitting, submitSucceeded, submitFailed, submitErrors }): JSX.Element => (
      <>
        <Wrapper>
          <SubHeading size="medium">
            Select which products you’d like to offer and assign pricing to appointment types.
          </SubHeading>
          {isProductsOfferedLoading ? (
            <Loader />
          ) : (
            <CheckboxesWrapper>
              {!!productsOffered?.length &&
                productsOffered.map((product: any) => (
                  <CheckboxWrapperAndPriceInputWrapper key={product.id}>
                    <CheckboxWrapper>
                      <Checkbox
                        name={ConsultationTypes[product.consultationType]}
                        onChange={onProductOfferedChange(product)}
                        checked={product.checked}
                        withoutForm
                      />
                      {product.consultationType === ConsultationTypes.FreeConsultation ? (
                        <CheckboxLabel onClick={onProductOfferedChange(product)}>
                          {product.productName} (20-min)
                        </CheckboxLabel>
                      ) : (
                        <CheckboxLabel onClick={onProductOfferedChange(product)}>
                          {product.productName} (1-Hour)
                        </CheckboxLabel>
                      )}
                    </CheckboxWrapper>
                    {product.checked && (
                      <PriceInputWrapper>
                        {product.consultationType !== ConsultationTypes.FreeConsultation ? (
                          <>
                            <Pricelabel>Price</Pricelabel>
                            <PriceInput
                              name={`${ConsultationTypes[product.consultationType]}Price`}
                              type="number"
                              placeholder="$"
                              min={10}
                              size="small"
                            />
                          </>
                        ) : (
                          <ZeroPricelabel>Price $0</ZeroPricelabel>
                        )}
                      </PriceInputWrapper>
                    )}
                  </CheckboxWrapperAndPriceInputWrapper>
                ))}
            </CheckboxesWrapper>
          )}
        </Wrapper>
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <SubmitButton type="submit" disabled={submitting} isLoading={submitting}>
          save
        </SubmitButton>
        {submitSucceeded && <SuccessTitle>Saved</SuccessTitle>}
      </>
    )}
  </Form>
);

export default ProductsOfferedForm;
