// libraries
import React from 'react';
import styled from 'styled-components';
import { FormRenderProps } from 'react-final-form';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';

// styled components
import { ColumnedFlex, SubmitButton, Error, Link } from 'shared/styledComponents';
import { Title } from 'shared/typography';

// styles
import { TextStyles } from 'shared/styles';
import devices from 'theme/devices';

// types
import { LogInData } from './types';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from '../CreateAccount/types';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const Heading = styled(Title)`
  margin-bottom: 35px;

  @media ${devices.mobile} {
    margin-bottom: 25px;
  }
`;

const FormContentWrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;

  @media ${devices.mobile} {
    margin-bottom: 55px;
    padding-bottom: 35px;
    border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  }
`;

const InputsWrapper = styled(ColumnedFlex)`
  align-items: flex-end;
  margin-bottom: 15px;
  width: 100%;
  max-width: 280px;

  @media ${devices.mobile} {
    max-width: 310px;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 13px 15px;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
`;

const ForgotPasswordLink = styled(Link)`
  font-size: 14px;
  margin-top: 15px;

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

interface LogInFormProps {
  onSubmit: (values: LogInData) => void;
  handleModalOpen: (title: string, data?: object) => () => void;
  formValidation: (values: LogInData) => any;
}

const LogInForm = ({ onSubmit, handleModalOpen, formValidation }: LogInFormProps): JSX.Element => (
  <Wrapper>
    <Heading>Already a member?</Heading>
    <Form onSubmit={onSubmit} validate={formValidation}>
      {({ submitting, submitFailed, submitErrors, hasValidationErrors, errors }: FormRenderProps): JSX.Element => (
        <FormContentWrapper>
          <InputsWrapper>
            <StyledInput name="email" type="email" placeholder="Email" size="small" />
            <StyledInput name="password" type="password" placeholder="Password" size="small" />
            <ForgotPasswordLink size="small" onClick={handleModalOpen('forgotPasswordModal')}>
              Forgot your password?
            </ForgotPasswordLink>
          </InputsWrapper>
          {submitFailed && hasValidationErrors && (
            <Error size="small">
              {!!Object.keys(errors).length &&
                `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
            </Error>
          )}
          {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
          <SubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
            Sign In
          </SubmitButton>
        </FormContentWrapper>
      )}
    </Form>
  </Wrapper>
);

export default LogInForm;
