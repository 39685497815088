// libraries
import { css, FlattenSimpleInterpolation } from 'styled-components';

// styles
import colors from 'theme/colors';
import fonts from 'theme/fonts';
import devices from 'theme/devices';

const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.lightBrown};
`;

export const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: 270px;
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: 270px;
    min-height: 50px;
    border: 1px solid ${colors.alto};
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0 14px;
    margin-right: 10px;
    box-shadow: none;
    cursor: pointer;

    :hover {
      border: 1px solid ${colors.alto};
    }

    @media ${devices.mobile} {
      width: 310px;
      margin-bottom: 15px;
    }
  `,
  option: (provided: FlattenSimpleInterpolation, state: any) => css`
    ${provided};
    background-color: ${state.isSelected ? colors.pampas : colors.white};
    color: ${colors.primary};
    cursor: pointer;

    &:hover {
      background-color: ${state.isSelected ? colors.pampas : colors.primary};
      color: ${state.isSelected ? colors.primary : colors.white};
    }
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      background-color: unset;
    `,
  multiValueLabel: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  multiValueRemove: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    :hover {
      background-color: unset;
      color: currentColor;
    }
  `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};
