// libraries
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

// custom components
import Loader from 'components/Loader';
import Icon from 'components/Icon';

// styled components
import { PrimaryButton, Link, Flex } from 'shared/styledComponents';
import { Text, Title } from 'shared/typography';

const Wrapper = styled.div`
  max-width: 550px;
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const StyledLink = styled(Link)`
  font-size: 16px;
  line-height: 24px;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledIcon = styled(Icon)`
  margin-right: 20px;
`;

const SuccessWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 20px;
`;

interface DocusignProps {
  onNextButtonClick: VoidFunction;
  isDocusignLoading: boolean;
  docusignLink: string;
  isBaaAgreementStepSucceeded: boolean;
  signedDate: string | undefined;
}

const Docusign = ({
  onNextButtonClick,
  isDocusignLoading,
  docusignLink,
  isBaaAgreementStepSucceeded,
  signedDate,
}: DocusignProps): JSX.Element =>
  isDocusignLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <StyledText>
        A Business Associate Agreement (BAA) is a written arrangement that specifies each party’s responsibilities when
        it comes to Protected Health Information (PHI).&nbsp;
        <StyledLink as="a" href={docusignLink}>
          Sign here.
        </StyledLink>
      </StyledText>
      {isBaaAgreementStepSucceeded && (
        <SuccessWrapper>
          <StyledIcon type="ListIcon" width="28px" height="28px" />
          <Title size="small">
            {signedDate
              ? `Documents signed (${moment(signedDate as string).format('MMM DD, YYYY')})`
              : 'Documents signed'}
          </Title>
        </SuccessWrapper>
      )}
      <PrimaryButton type="submit" onClick={onNextButtonClick} disabled={!isBaaAgreementStepSucceeded}>
        next
      </PrimaryButton>
    </Wrapper>
  );

export default Docusign;
