// types
import { LogInData } from './types';
import { UserData } from 'types';

export const convertLogInValuesToDataModel = (values: LogInData): any => ({
  email: values.email || undefined,
  password: values.password || undefined,
});

export const convertUserDataToViewModel = (values: UserData): any => ({
  userId: values.userId || undefined,
  role: values.role || undefined,
  firstName: values.firstName || undefined,
  slug: values.slug || undefined,
  lastName: values.lastName || undefined,
  token: values.token
    ? { accessToken: values.token.accessToken || undefined, refreshToken: values.token.refreshToken || undefined }
    : undefined,
});
