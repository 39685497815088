// libraries
import { combineReducers } from 'redux';

// reducers
import modalReducer from 'components/Modals/reducer';
import providerCriteriaReducer from 'modules/ProviderCriteria/reducer';
import userReducer from 'modules/User/reducer';
import paginationReducer from 'modules/FindProviders/reducer';

const rootReducer = combineReducers({
  user: userReducer,
  modal: modalReducer,
  providerCriteria: providerCriteriaReducer,
  pagination: paginationReducer,
});

export default rootReducer;
