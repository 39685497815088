// libraries
import { handleActions } from 'redux-actions';

// actions
import { providerCriteriaRequest, providerCriteriaSuccesed, providerCriteriaFailed } from './actions';

const defaultState = {
  isProviderCriteriaLoading: false,
  isProviderCriteriaSuccesed: false,
  isProviderCriteriaFailed: false,
  stages: [],
  services: [],
  specializations: [],
  identities: [],
};

const providerCriteriaReducer = handleActions(
  {
    [providerCriteriaRequest.toString()]: (state) => ({
      ...state,
      isProviderCriteriaLoading: true,
    }),
    [providerCriteriaSuccesed.toString()]: (state, { payload: { stages, services, specializations, identities } }) => ({
      ...state,
      isProviderCriteriaLoading: false,
      isProviderCriteriaSuccesed: true,
      stages,
      services,
      specializations,
      identities,
    }),
    [providerCriteriaFailed.toString()]: (state) => ({
      ...state,
      isProviderCriteriaLoading: false,
      isProviderCriteriaFailed: true,
    }),
  },
  defaultState,
);

export default providerCriteriaReducer;
