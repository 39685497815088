// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

export const postPassword = (oldPassword: string, newPassword: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    `user/update-password`,
    {
      oldPassword,
      newPassword,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const postEmail = (email: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'client/update-email',
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export const getEmail = (token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('user/email-address', {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export const deleteClientAccount = (clientId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.delete(`client/${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

export default { getEmail, postEmail, postPassword, deleteClientAccount };
