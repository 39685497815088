// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { SubmitButton, Flex, ColumnedFlex } from 'shared/styledComponents';

// shared styles
import { Title, Text } from 'shared/typography';

// styles
import devices from 'theme/devices';

// types
import Icon from 'components/Icon';

export const StepNumber = styled(Title)<any>`
  width: 28px;
  height: 28px;
  border: 2px solid ${({ theme: { colors } }): string => colors.bismark};
  border-radius: 50%;
  font-size: 20px;
  text-align: center;
  padding-top: 4px;
  padding-left: 1px;
  margin-right: 15px;
  line-height: 1;

  @media ${devices.mobile} {
    padding-top: 2px;
  }
`;

export const StyledIcon = styled(Icon)`
  margin-right: 15px;
`;

export const Heading = styled(Title)<any>(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
    margin-bottom: 15px;
  `,
);

export const SubHeading = styled(Text)`
  margin-bottom: 25px;
`;

export const StyledSubmitButton = styled(SubmitButton)`
  max-width: 300px;
`;

interface PaymentStepProps {
  stepNumber: string | number;
  heading: string;
  subHeading: string;
  submitButtonName: string;
  onSubmitButtonClick: VoidFunction;
  isSubmitButtonDisabled?: boolean;
}

const PaymentStep = ({
  stepNumber,
  heading,
  subHeading,
  submitButtonName,
  onSubmitButtonClick,
  isSubmitButtonDisabled,
}: PaymentStepProps): JSX.Element => (
  <Flex>
    <StepNumber>{stepNumber}</StepNumber>
    <ColumnedFlex>
      <Heading size="medium">{heading}</Heading>
      <SubHeading>{subHeading}</SubHeading>
      <StyledSubmitButton onClick={onSubmitButtonClick} disabled={isSubmitButtonDisabled}>
        {submitButtonName}
      </StyledSubmitButton>
    </ColumnedFlex>
  </Flex>
);
export default PaymentStep;
