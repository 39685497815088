// libraries
import React, { useCallback } from 'react';

// custom components
import ConfirmationModal from './ConfirmationModal';

// hooks
import useModal from 'hooks/useModal';

interface ConfirmationModalContainerProps {
  title: string;
  description: string;
  onModalClose: VoidFunction;
  cancelButtonLabel: string;
  confirmButtonLabel: string;
  additionalInfo?: string;
  onCancelButtonClick: VoidFunction;
  onConfirmButtonClick: VoidFunction;
}

const ConfirmationModalContainer = ({
  title,
  description,
  cancelButtonLabel,
  confirmButtonLabel,
  onCancelButtonClick,
  onConfirmButtonClick,
  additionalInfo,
}: ConfirmationModalContainerProps): JSX.Element => {
  const { handleModalClose } = useModal();

  const handleCancelButtonClick = useCallback(() => {
    if (onCancelButtonClick) {
      onCancelButtonClick();
    }
    handleModalClose();
  }, [onCancelButtonClick, handleModalClose]);

  const handleConfirmButtonClick = useCallback(() => {
    onConfirmButtonClick();
    handleModalClose();
  }, [onConfirmButtonClick, handleModalClose]);

  return (
    <ConfirmationModal
      title={title}
      description={description}
      cancelButtonLabel={cancelButtonLabel}
      confirmButtonLabel={confirmButtonLabel}
      onCancelButtonClick={handleCancelButtonClick}
      onConfirmButtonClick={handleConfirmButtonClick}
      additionalInfo={additionalInfo}
    />
  );
};

export default ConfirmationModalContainer;
