// libraries
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

// styled components
import { Title, Text } from 'shared/typography';
import { Flex, ColumnedFlex, SecondaryButton } from 'shared/styledComponents';

// types
import { ConsultationTypes } from 'types';
import { CONSULTATION_TYPE } from 'constants/booking';
import { UserRoles } from 'constants/user';

// styles
import devices from 'theme/devices';

const Wrapper = styled(Flex)`
  justify-content: space-between;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  :last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`;

const DateWrapper = styled.div`
  @media ${devices.mobile} {
    margin-bottom: 25px;
  }
`;

const StyledDate = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
`;

const TimeSlotsWrapper = styled(ColumnedFlex)`
  width: 100%;
  max-width: ${({ role }): string => (role === UserRoles.CLIENT ? '640px' : '750px')};
`;

const TimeSlotWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  padding-bottom: 25px;
  margin-bottom: 25px;
  :last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 35px;
    padding-bottom: 35px;
  }
`;

const Time = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  line-height: 1;
  @media ${devices.mobile} {
    margin-bottom: 15px;
  }
`;

const UserName = styled(Text)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  line-height: 1;
  @media ${devices.mobile} {
    margin-bottom: 15px;
  }
`;

const ConsultationType = styled(Text)`
  line-height: 1;
  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;

const ButtonsWrapper = styled(ColumnedFlex)`
  align-items: flex-start;
  @media ${devices.mobile} {
    flex-direction: row;
  }
`;
const UserNameMargin = styled(UserName)`
  margin-bottom: 15px;
`;

interface AppointmentsDayProps {
  day: string;
  role: any;
  consultations: any;
  handleModalOpen: (title: string, data: any) => void;
  onAppointmentCancel: (
    clientId: string,
    day: string,
    time: string,
    timeZone: string,
    consultationType: ConsultationTypes,
  ) => () => void;
}

const AppointmentsDay = ({
  day,
  consultations,
  handleModalOpen,
  onAppointmentCancel,
  role,
}: AppointmentsDayProps): JSX.Element => (
  <Wrapper>
    <DateWrapper>
      <StyledDate size="small">{moment(day).format('MMMM D, yyyy')}</StyledDate>
    </DateWrapper>
    <TimeSlotsWrapper role={role}>
      {consultations.map(
        ({
          time,
          timeZone,
          clientName,
          clientEmail,
          clientPhone,
          clientCity,
          clientState,
          consultationType,
          clientId,
          providerName,
          providerId,
          range,
        }: any) => (
          <TimeSlotWrapper key={time}>
            <Time size="small">{`${moment(time).format('h:mm a')} - ${momentTimeZone
              .tz(time, timeZone)
              .add(range, 'h')
              .format('h:mm a (z)')}`}</Time>

            <ColumnedFlex>
              <UserNameMargin>{role === UserRoles.CLIENT ? providerName : clientName}</UserNameMargin>
              <UserName as="a" href={`mailto: ${clientEmail}`}>
                {clientEmail}
              </UserName>
            </ColumnedFlex>

            <ColumnedFlex>
              {clientCity && clientState && <UserNameMargin>{`${clientCity}, ${clientState}`}</UserNameMargin>}
              {clientPhone && (
                <UserName as="a" href={`tel: ${clientPhone}`}>
                  {clientPhone}
                </UserName>
              )}
            </ColumnedFlex>
            <ConsultationType>{CONSULTATION_TYPE[consultationType as ConsultationTypes]}</ConsultationType>
            <ButtonsWrapper>
              {/* <AddButton size="small">+ calendar</AddButton> */}
              <SecondaryButton
                size="small"
                onClick={handleModalOpen('conformationModal', {
                  title: 'Cancel Appointment',
                  description:
                    role === UserRoles.CLIENT
                      ? 'Are you sure you want to cancel this appointment?'
                      : 'Are you sure you want to cancel this appointment? Repeat cancellations from a provider may result in removal from the platform.',
                  cancelButtonLabel: 'No',
                  confirmButtonLabel: 'Cancel',
                  additionalInfo:
                    role === UserRoles.CLIENT
                      ? 'Cancellation Policy: Robyn requires 24 hours’ notice for cancellations. You will be charged for all appointments (1) that are not cancelled and (2) that are cancelled less than 24 hours before the appointment is scheduled.'
                      : undefined,
                  onConfirmButtonClick: onAppointmentCancel(
                    role === UserRoles.CLIENT ? providerId : clientId,
                    day,
                    time,
                    timeZone,
                    consultationType,
                  ),
                })}
              >
                - Cancel
              </SecondaryButton>
            </ButtonsWrapper>
          </TimeSlotWrapper>
        ),
      )}
    </TimeSlotsWrapper>
  </Wrapper>
);

export default AppointmentsDay;
