// libraries
import { createActions } from 'redux-actions';

export const { setUser, setUserToken, setUserRole, setUserImage, setUserName, clearUser } = createActions(
  'SET_USER',
  'SET_USER_TOKEN',
  'SET_USER_ROLE',
  'SET_USER_IMAGE',
  'SET_USER_NAME',
  'CLEAR_USER',
);
