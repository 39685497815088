export const REDIRECTS = [
  { currentURL: '/maternal-specialists/', newURL: '/find-providers/' },
  { currentURL: '/maternal-specialists/acupuncture/', newURL: '/service/acupuncture/' },
  { currentURL: '/maternal-specialists/nutrition/', newURL: '/service/nutrition/' },
  { currentURL: '/maternal-specialists/mental-health/', newURL: '/service/mental-health/' },
  { currentURL: '/maternal-specialists/exercise/', newURL: '/service/exercise/' },
  { currentURL: '/maternal-specialists/fertility-coaches/', newURL: '/service/fertility-coaching/' },
  { currentURL: '/maternal-specialists/massage-therapy/', newURL: '/service/massage-therapy/' },
  { currentURL: '/maternal-specialists/birth-education/', newURL: '/service/birth-education/' },
  { currentURL: '/maternal-specialists/doula/', newURL: '/service/doula/' },
  { currentURL: '/maternal-specialists/chiropractic/', newURL: '/service/chiropractic/' },
  { currentURL: '/maternal-specialists/physical-therapy/', newURL: '/service/physical-therapy/' },
  { currentURL: '/maternal-specialists/lactation/', newURL: '/service/lactation/' },
  { currentURL: '/maternal-specialists/infant-sleep/', newURL: '/service/infant-sleep/' },
  { currentURL: '/maternal-specialists/coaching/', newURL: '/service/coaching/' },
  { currentURL: '/maternal-specialists/occupational-therapy/', newURL: '/service/occupational-therapy/' },
  { currentURL: '/learn/', newURL: '/' },
  { currentURL: '/financial-resources/', newURL: '/' },
  { currentURL: '/maternal-education-courses/', newURL: 'https://classes.wearerobyn.co/' },
  { currentURL: '/products-perks/', newURL: '/' },
  { currentURL: '/category_learn/movement-body-physical-fitness-exercise/', newURL: '/service/exercise/' },
  { currentURL: '/category_learn/food-function-nutrition/', newURL: '/service/nutrition/' },
  {
    currentURL: '/category_learn/health-wellness-western-medicine-eastern-medicine-acupuncture-herbs/',
    newURL: '/service/acupuncture/',
  },
  { currentURL: '/category_learn/mind-soul-mental-health-relationships/', newURL: '/service/mental-health/' },
  { currentURL: '/join-our-community/', newURL: '/' },
  { currentURL: '/trying-to-conceive-class/', newURL: ' https://classes.wearerobyn.co/' },
  { currentURL: '/childbirth-class/', newURL: 'https://classes.wearerobyn.co/' },
  { currentURL: '/feeding-your-baby-101-class/', newURL: 'https://classes.wearerobyn.co/' },
  { currentURL: '/about-us/', newURL: '/' },
  { currentURL: '/about-us/#mystory/', newURL: '/' },
  { currentURL: '/contact/', newURL: '/' },
  { currentURL: '/adoption/', newURL: '/find-providers/' },
  { currentURL: '/aerobic/', newURL: '/find-providers/' },
  { currentURL: '/autoimmune/', newURL: '/find-providers/' },
  { currentURL: '/baby-gear/', newURL: '/find-providers/' },
  { currentURL: '/baby-led-weaning/', newURL: '/find-providers/' },
  { currentURL: '/babywearing/', newURL: '/find-providers/' },
  { currentURL: '/back-to-work/', newURL: '/find-providers/' },
  { currentURL: '/body-image/', newURL: '/find-providers/' },
  { currentURL: '/breath-meditation/', newURL: '/find-providers/' },
  { currentURL: '/breech/', newURL: '/find-providers/' },
  { currentURL: '/breech-presentation/', newURL: '/find-providers/' },
  { currentURL: '/child-development/', newURL: '/find-providers/' },
  { currentURL: '/chronic-inflammation/', newURL: '/find-providers/' },
  { currentURL: '/developmental-play/', newURL: '/find-providers/' },
  { currentURL: '/diastasis-core/', newURL: '/find-providers/' },
  { currentURL: '/sperm-or-egg-donor/', newURL: '/find-providers/' },
  { currentURL: '/early-parenthood/', newURL: '/find-providers/' },
  { currentURL: '/eastern-medicine/', newURL: '/find-providers/' },
  { currentURL: '/eating-disorders/', newURL: '/find-providers/' },
  { currentURL: '/egg-freezing/', newURL: '/find-providers/' },
  { currentURL: '/endometriosis/', newURL: '/find-providers/' },
  { currentURL: '/family-nutrition/', newURL: '/find-providers/' },
  { currentURL: '/family-planning/', newURL: '/find-providers/' },
  { currentURL: '/food-allergies-intolerances/', newURL: '/find-providers/' },
  { currentURL: '/genetics/', newURL: '/find-providers/' },
  { currentURL: '/grief/', newURL: '/find-providers/' },
  { currentURL: '/herbal-medicine/', newURL: '/find-providers/' },
  { currentURL: '/high-risk-pregnancy/', newURL: '/find-providers/' },
  { currentURL: '/hormone-imbalance/', newURL: '/find-providers/' },
  { currentURL: '/hypnobirthing/', newURL: '/find-providers/' },
  { currentURL: '/infant-development/', newURL: '/find-providers/' },
  { currentURL: '/infant-feeding/', newURL: '/find-providers/' },
  { currentURL: '/infant-sleep/', newURL: '/find-providers/' },
  { currentURL: '/ivf/', newURL: '/find-providers/' },
  { currentURL: '/journaling/', newURL: '/find-providers/' },
  { currentURL: '/labor-childbirth/', newURL: '/find-providers/' },
  { currentURL: '/lgbtq/', newURL: '/find-providers/' },
  { currentURL: '/low-milk-supply/', newURL: '/find-providers/' },
  { currentURL: '/male-factor-infertility/', newURL: '/find-providers/' },
  { currentURL: '/maternal-nutrition/', newURL: '/find-providers/' },
  { currentURL: '/miscarriage-loss/', newURL: '/find-providers/' },
  { currentURL: '/newborn-care/', newURL: '/find-providers/' },
  { currentURL: '/pcos/', newURL: '/find-providers/' },
  { currentURL: '/pelvic-floor/', newURL: '/find-providers/' },
  { currentURL: '/perinatal-mood-and-anxiety-disorder/', newURL: '/find-providers/' },
  { currentURL: '/period-health/', newURL: '/find-providers/' },
  { currentURL: '/pilates/', newURL: '/find-providers/' },
  { currentURL: '/postnatal-depletion/', newURL: '/find-providers/' },
  { currentURL: '/postpartum-depression/', newURL: '/find-providers/' },
  { currentURL: '/post-partum-nutrition/', newURL: '/find-providers/' },
  { currentURL: '/postpartum-support/', newURL: '/find-providers/' },
  { currentURL: '/prenatal-nutrition/', newURL: '/find-providers/' },
  { currentURL: '/relationship-couples/', newURL: '/find-providers/' },
  { currentURL: '/reproductive-psychiatry/', newURL: '/find-providers/' },
  { currentURL: '/reproductive-psychology/', newURL: '/find-providers/' },
  { currentURL: '/sex-health/', newURL: '/find-providers/' },
  { currentURL: '/sexual-assault-recovery/', newURL: '/find-providers/' },
  { currentURL: '/strength-training/', newURL: '/find-providers/' },
  { currentURL: '/surrogacy/', newURL: '/find-providers/' },
  { currentURL: '/tongue-and-lip-ties/', newURL: '/find-providers/' },
  { currentURL: '/trauma/', newURL: '/find-providers/' },
  { currentURL: '/twins-multiples/', newURL: '/find-providers/' },
  { currentURL: '/weight-management/', newURL: '/find-providers/' },
  { currentURL: '/yoga/', newURL: '/find-providers/' },
  { currentURL: '/blog/', newURL: '/' },
  { currentURL: '/success/', newURL: '/' },
  { currentURL: '/learn-blog/', newURL: '/' },
  { currentURL: '/migrating-your-practice-to-digital/', newURL: '/' },
  { currentURL: '/journey_category/acupuncture/', newURL: '/' },
  { currentURL: '/journey_category/chinese-herbs/', newURL: '/' },
  { currentURL: '/journey_category/exercise-yoga/', newURL: '/' },
  { currentURL: '/journey_category/financial-challenges/', newURL: '/' },
  { currentURL: '/journey_category/massage/', newURL: '/' },
  { currentURL: '/journey_category/meditation/', newURL: '/' },
  { currentURL: '/journey_category/mental-health/', newURL: '/' },
  { currentURL: '/journey_category/non-toxic-products/', newURL: '/' },
  { currentURL: '/journey_category/nutrition/', newURL: '/' },
  { currentURL: '/journey_category/relationship-support/', newURL: '/' },
  { currentURL: '/journey_category/supplements/', newURL: '/' },
  { currentURL: '/journey_category/support-groups/', newURL: '/' },
  { currentURL: '/journey_category/taking-a-break/', newURL: '/' },
  { currentURL: '/path_to_parenthood/adoption/', newURL: '/' },
  { currentURL: '/path_to_parenthood/amniocentesis/', newURL: '/' },
  { currentURL: '/path_to_parenthood/autoimmune-protocol/', newURL: '/' },
  { currentURL: '/path_to_parenthood/bed-rest/', newURL: '/' },
  { currentURL: '/path_to_parenthood/birth-stories/', newURL: '/' },
  { currentURL: '/path_to_parenthood/c-section/', newURL: '/' },
  { currentURL: '/path_to_parenthood/cervical-cerclage/', newURL: '/' },
  { currentURL: '/path_to_parenthood/clomid/', newURL: '/' },
  { currentURL: '/path_to_parenthood/coculture/', newURL: '/' },
  { currentURL: '/path_to_parenthood/cvs/', newURL: '/' },
  { currentURL: '/path_to_parenthood/dc/', newURL: '/' },
  { currentURL: '/path_to_parenthood/donor-egg/', newURL: '/' },
  { currentURL: '/path_to_parenthood/egg-freezing/', newURL: '/' },
  { currentURL: '/path_to_parenthood/high-risk-pregnancy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/hyperemesis-gravidarum/', newURL: '/' },
  { currentURL: '/path_to_parenthood/hysteroscopy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/icsi/', newURL: '/' },
  { currentURL: '/path_to_parenthood/intrauterine-growth-restriction/', newURL: '/' },
  { currentURL: '/path_to_parenthood/iui/', newURL: '/' },
  { currentURL: '/path_to_parenthood/ivf/', newURL: '/' },
  { currentURL: '/path_to_parenthood/laporoscopy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/letrozole/', newURL: '/' },
  { currentURL: '/path_to_parenthood/lgbtq/', newURL: '/' },
  { currentURL: '/path_to_parenthood/living-childfree/', newURL: '/' },
  { currentURL: '/path_to_parenthood/methotrexate/', newURL: '/' },
  { currentURL: '/path_to_parenthood/mucolytics/', newURL: '/' },
  { currentURL: '/path_to_parenthood/natural-fet/', newURL: '/' },
  { currentURL: '/path_to_parenthood/natural-pregnancy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/nicu/', newURL: '/' },
  { currentURL: '/path_to_parenthood/pgd-pgs-testing/', newURL: '/' },
  { currentURL: '/path_to_parenthood/preterm-labor/', newURL: '/' },
  { currentURL: '/path_to_parenthood/rainbow-baby/', newURL: '/' },
  { currentURL: '/path_to_parenthood/second-opinions/', newURL: '/' },
  { currentURL: '/path_to_parenthood/selective-reduction/', newURL: '/' },
  { currentURL: '/path_to_parenthood/self-advocacy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/surrogacy/', newURL: '/' },
  { currentURL: '/path_to_parenthood/timed-ovulation/', newURL: '/' },
  { currentURL: '/path_to_parenthood/twins-and-multiples/', newURL: '/' },
  { currentURL: '/postpartum_and_motherhood/breastfeeding/', newURL: '/' },
  { currentURL: '/postpartum_and_motherhood/postpartum-anxiety/', newURL: '/' },
  { currentURL: '/postpartum_and_motherhood/postpartum-depression/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/advanced-maternal-age/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/amenorrhea/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/autoimmune-diseases/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/blocked-tubes/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/cancer/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/cervical-weakness/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/chemical-pregnancy/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/crohns-disease/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/eating-disorders/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/ectopic-pregnancy/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/egg-quality/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/endometriosis/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/endometritis/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/fibroids/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/genetic-considerations/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/hostile-cervical-mucus/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/infection/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/inflammation/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/injury-acccident/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/intrauterine-growth-restriction/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/irregular-cycles/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/late-term-pregnancy-loss/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/male-factor-infertility/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/menstrual-health/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/miscarriage/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/mthfr-gene-variant/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/natural-killer-cells/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/ovarian-hyperstimulation/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/ovarian-reserve/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/over-exercising/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/pcos/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/placenta-previa/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/preeclampsia/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/recurrent-pregnancy-loss/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/secondary-infertility/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/septate-uterus/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/subchorionic-hemorrhage/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/thyroid-issues/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/tube-removal/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/type-1-diabetic/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/unexplained-infertility/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/uterine-septum/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/varicocele/', newURL: '/' },
  { currentURL: '/reproductive_health_challenges/vasectomy/', newURL: '/' },
  {
    currentURL: '/maternal-specialist/Samara-Robertson,-CNM,-MSN,-APRN',
    newURL: '/maternal-specialist/samara-robertson-cnm-msn-aprn-cfd845be',
  },
  {
    currentURL: '/maternal-specialist/kiley-hanish/',
    newURL: '/maternal-specialist/return-to-zero-hope-cd43e5ac',
  },
  {
    currentURL: '/maternal-specialist/princess-mckinney-kirk/',
    newURL: '/maternal-specialist/origin-physical-therapy-cd99df2a',
  },
  {
    currentURL: '/maternal-specialist/Oluwayeni-Abraham-PT,-DPT',
    newURL: '/maternal-specialist/oluwayeni-abraham-pt-dpt-ce86b43a',
  },
  {
    currentURL: '/maternal-specialist/michal-t-margolese/',
    newURL: '/maternal-specialist/michal-t-margolese-cda206c2',
  },
  {
    currentURL: '/maternal-specialist/-Kate-Hoppock',
    newURL: '/maternal-specialist/kate-hoppock-cff4e0a5',
  },
  {
    currentURL: '/maternal-specialist/Jennifer-Jolorte-Doro',
    newURL: '/maternal-specialist/jennifer-jolorte-doro-cd4700ce',
  },
  {
    currentURL: '/city/na/maternal-specialist/elyse-kupperman-chaifetz/privacy-policy',
    newURL: '/maternal-specialist/gabrielle-kaufman-cd9fe00e',
  },
  {
    currentURL: '/maternal-specialist/klara-brown/trackback/',
    newURL: '/maternal-specialist/dr-klara-brown-cdb2a7f0',
  },
  {
    currentURL: '/dalia-sabari/',
    newURL: '/maternal-specialist/dalia-sabari-cd8e5e4b',
  },
  {
    currentURL: '/maternal-specialist/Carly-Snyder,-M.D.',
    newURL: '/maternal-specialist/carly-snyder-md-cd90fa7e',
  },
  {
    currentURL: '/maternal-specialist/ashlee-b/',
    newURL: '/maternal-specialist/ashlee-binns-cd4c8dc7',
  },
];
