// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';

// styled components
import { CentredFlex, DropdownButton } from 'shared/styledComponents';
import { Text } from 'shared/typography';

// hooks
import ClickOutside from '../ClickOutside';

import { ProviderCriteria } from 'modules/ProviderCriteria/types';

const ButtonWrapper = styled(CentredFlex)`
  cursor: pointer;
`;

const DropdownIcon = styled(Icon)`
  margin-left: 10px;
`;

const ServicesListBackground = styled.div<any>(
  ({ theme: { colors }, servicesListHeight }) => `
    position: absolute;
    width: 100%;
    height: ${servicesListHeight}px;
    margin-top: 25px;
    left: 0;
    background-color: ${colors.secondary};
    z-index: 1;
    box-shadow: 0 10px 12px -12px #c6c6c6;
  `,
);

const ServicesListWrapper = styled.div`
  position: relative;
  z-index: 1;
`;

const ServicesList = styled.ul<any>`
  display: flex;
  flex-direction: column;
  align-items: end;
  position: absolute;
  padding-top: 30px;
`;

const ListItem = styled(Text)<any>`
  list-style-type: none;
  margin-bottom: 10px;
  color: ${({ theme: { colors } }): string => colors.primary};
  white-space: nowrap;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

interface ServicesDropdownProps {
  isOpened: boolean;
  services: ProviderCriteria[];
  onDropdownClose: VoidFunction;
  onDropdownOpen: VoidFunction;
  setServicesListHeightOnMount: (element: HTMLElement) => void;
  servicesListHeight: number;
  handleRedirectToServiceLanding: any;
  iswhitecolor?: boolean;
}

const ServicesDropdown = ({
  isOpened,
  onDropdownClose,
  onDropdownOpen,
  setServicesListHeightOnMount,
  servicesListHeight,
  services,
  handleRedirectToServiceLanding,
  iswhitecolor,
}: ServicesDropdownProps): JSX.Element => (
  <ClickOutside onOutsideClick={onDropdownClose}>
    <ButtonWrapper onMouseEnter={onDropdownOpen}>
      <DropdownButton size="small" iswhitecolor={iswhitecolor ? 1 : 0}>
        Services
      </DropdownButton>
      <DropdownIcon type="downArrow" width="11px" height="7px" color={iswhitecolor ? '#fff' : undefined} />
    </ButtonWrapper>
    {isOpened && (
      <div onMouseLeave={onDropdownClose}>
        <ServicesListBackground servicesListHeight={servicesListHeight} />
        <ServicesListWrapper>
          <ServicesList ref={setServicesListHeightOnMount}>
            {services.map(({ id, value }) => (
              <ListItem as="li" key={id} onClick={handleRedirectToServiceLanding({ value })}>
                {value}
              </ListItem>
            ))}
          </ServicesList>
        </ServicesListWrapper>
      </div>
    )}
  </ClickOutside>
);

export default ServicesDropdown;
