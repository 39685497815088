// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

const getPersonalDetails = (clientId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`client/profile?id=${clientId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const postPersonalDetailStage = (id: string, stage: any, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'client/profile',
    {
      id,
      stage,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const postPersonalDetailInterests = (id: string, interests: any, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'client/profile',
    {
      id,
      interests,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

const postClientName = (firstName: string, lastName: any, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'client/name',
    {
      firstName,
      lastName,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export default { getPersonalDetails, postPersonalDetailInterests, postPersonalDetailStage, postClientName };
