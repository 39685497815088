// api
import api from './api';

// types
import { AxiosResponse } from 'axios';

export const getProviderPaymentInfo = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.getPaymentInfo(userToken);

export const createPaymentAccount = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.postPaymentAccount(userToken);

export const getPaymentVerifyAccountLink = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.postGenerateAccountVerificationLink(userToken);

// export const getPlaidPublicKey = async (userToken: string): Promise<AxiosResponse<any>> =>
//   await api.getPlaidPublicKey(userToken);

export const getPlaidLinkToken = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.getPlaidLinkToken(userToken);
