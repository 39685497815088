// libraries
import React, { useCallback, useMemo } from 'react';

// custom components
import ForgotPasswordForm from './ForgotPasswordForm';

// hooks
import useModal from 'hooks/useModal';

// services
import { convertForgotPasswordValuesToDataModel } from './services';

// api
import api from './api';

// types
import { ForgotPasswordData } from './types';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

const ResetPasswordFormContainer = (): JSX.Element => {
  const { handleModalClose } = useModal();

  const handleSubmit = useCallback(async (values: ForgotPasswordData) => {
    const convertedValues = convertForgotPasswordValuesToDataModel(values);

    await api.postForgotPassword(convertedValues);
  }, []);

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  return (
    <ForgotPasswordForm onSubmit={handleSubmit} handleModalClose={handleModalClose} formValidation={formValidation} />
  );
};

export default ResetPasswordFormContainer;
