// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

const getProviderUpcomingAppointments = (providerId: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`provider/upcoming-appointments?id=${providerId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getClientUpcomingAppointments = (token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`client/upcoming-appointments`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

const getProviderPassedAppointments = (token: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`provider/passed-appointments`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
export default { getProviderUpcomingAppointments, getClientUpcomingAppointments, getProviderPassedAppointments };
