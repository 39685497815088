// libraries
import React, { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// custom components
import Header from './Header';

// selectors
import { selectServices } from 'modules/ProviderCriteria/selectors';
import { selectUser } from 'modules/User/selectors';

// hooks
import useModal from 'hooks/useModal';

// thunk
import logOutThunk from 'modules/User/thunk';

// hooks
import useResize from 'hooks/useResize';

type HeaderContainerProps = {
  istransparent?: boolean;
};

const HeaderContainer = ({ istransparent }: HeaderContainerProps): JSX.Element => {
  const dispatch = useDispatch();

  const history = useHistory();

  const { handleModalOpen } = useModal();

  const services = useSelector(selectServices);

  const [isMenuOpened, setIsMenuOpened] = useState(false);

  const { isDesktop } = useResize();

  const {
    isLoggedIn,
    role,
    firstName,
    lastName,
    token: { accessToken },
  } = useSelector(selectUser);

  const handleLogOut = useCallback(() => {
    dispatch(logOutThunk(accessToken));
    history.push('/');
  }, [dispatch, accessToken, history]);

  const handleMenuOpen = useCallback(() => setIsMenuOpened(true), []);

  const handleMenuClose = useCallback(() => setIsMenuOpened(false), []);

  useEffect(() => {
    if (isDesktop && isMenuOpened) {
      handleMenuClose();
    }
  }, [handleMenuClose, isMenuOpened, isDesktop]);

  const handleModalOpenAndMenuClose = (title: string) => (): void => {
    handleModalOpen(title)();

    handleMenuClose();
  };

  return (
    <Header
      services={services}
      isUserLoggedIn={isLoggedIn}
      userRole={role}
      userFirstName={firstName}
      userLastName={lastName}
      onModalOpen={handleModalOpenAndMenuClose}
      onLogOut={handleLogOut}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      isMenuOpened={isMenuOpened}
      istransparent={istransparent}
    />
  );
};

export default React.memo(HeaderContainer);
