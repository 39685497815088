// libraries
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { NavLink as ReactRouterLink } from 'react-router-dom';

// custom components
import Header from 'components/Header';
import Footer from 'components/Footer';

// styled components
import { ColumnedFlex, Frame, Link } from 'shared/styledComponents';
import { H2 } from 'shared/typography';

// styles
import devices from 'theme/devices';

const Wrapper = styled.div``;

const InfoBlockWrapper = styled.div`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
`;

const InfoBlock = styled(Frame)`
  display: flex;
  align-items: center;
  padding: 95px 0 85px;

  @media ${devices.mobile} {
    padding-top: 0;
    padding-bottom: 25px;
  }
`;

const ContentWrapper = styled(Frame)`
  display: flex;
  min-height: 100vh;
  padding-top: 105px;

  @media ${devices.mobile} {
    flex-direction: column;
    height: fit-content;
    margin-bottom: 70px;
    padding-top: 35px;
  }
`;

const Navigation = styled(ColumnedFlex)`
  width: 100%;
  max-width: 190px;
  margin-right: 100px;
  margin-top: 10px;

  @media ${devices.mobile} {
    flex-direction: row;
    max-width: 310px;
    margin: 0;
    padding-bottom: 20px;
    overflow-x: auto;
  }
`;

const NavigationLinkWrapper = styled.div`
  padding: 30px 0;
  border-bottom: ${({ theme: { colors } }): string => `1px solid ${colors.alto}`};

  :first-child {
    padding-top: 0;
  }

  :last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  @media ${devices.mobile} {
    min-height: 45px;
    padding: 10px 0;
    padding-right: 25px;
    white-space: nowrap;
    border-bottom: 0;

    :first-child {
      padding-top: 10px;
    }

    :last-child {
      padding-right: 0;
      padding-bottom: 10px;
    }
  }
`;

const ActiveNavLinkStyles = {
  opacity: '60%',
};

interface ClientDashboardLayoutProps {
  children: ReactNode;
  firstName: string;
  lastName: string;
}

const ClientDashboardLayout = ({ children, firstName, lastName }: ClientDashboardLayoutProps): JSX.Element => (
  <Wrapper>
    <Header />
    <InfoBlockWrapper>
      <InfoBlock>
        <H2>
          {firstName} {lastName}
        </H2>
      </InfoBlock>
    </InfoBlockWrapper>
    <ContentWrapper>
      <Navigation>
        <NavigationLinkWrapper>
          <Link
            as={ReactRouterLink}
            to="/client/dashboard/personal-details"
            activeStyle={ActiveNavLinkStyles}
            size="small"
          >
            Personal Details
          </Link>
        </NavigationLinkWrapper>
        <NavigationLinkWrapper>
          <Link
            as={ReactRouterLink}
            to="/client/dashboard/upcoming-appointments"
            activeStyle={ActiveNavLinkStyles}
            size="small"
          >
            Upcoming Appointments
          </Link>
        </NavigationLinkWrapper>
        <NavigationLinkWrapper>
          <Link
            as={ReactRouterLink}
            to="/client/dashboard/payment-method"
            activeStyle={ActiveNavLinkStyles}
            size="small"
          >
            Payment Method
          </Link>
        </NavigationLinkWrapper>
        <NavigationLinkWrapper>
          <Link as={ReactRouterLink} to="/client/dashboard/settings" activeStyle={ActiveNavLinkStyles} size="small">
            Settings
          </Link>
        </NavigationLinkWrapper>
      </Navigation>
      {children}
    </ContentWrapper>
    <Footer />
  </Wrapper>
);

export default ClientDashboardLayout;
