// libraries
import { useCallback, Dispatch } from 'react';
import { useDispatch } from 'react-redux';

// actions
import { setModal, clearModal } from 'components/Modals/actions';

interface UseModal {
  dispatch: Dispatch<any>;
  handleModalOpen: (title: string, data?: object) => () => void;
  handleModalClose: VoidFunction;
}

const useModal = (): UseModal => {
  const dispatch = useDispatch();

  const handleModalOpen = useCallback(
    (title: string, data?: object) => (): void => {
      dispatch(setModal({ title, data }));
    },
    [dispatch],
  );

  const handleModalClose = useCallback(() => dispatch(clearModal()), [dispatch]);

  return { dispatch, handleModalOpen, handleModalClose };
};

export default useModal;
