// libraries
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

// custom components
import Interests from './Interests';

// api
import api from '../api';

// selectors
import { selectProviderCriteria } from 'modules/ProviderCriteria/selectors';

interface InterestsContainerProps {
  interests: any[];
  setInterestDetails: any;
  userId: string;
  accessToken: string;
}

const InterestsContainer = ({
  interests,
  setInterestDetails,
  userId,
  accessToken,
}: InterestsContainerProps): JSX.Element => {
  const [isPersonalDetailsLoading, setIsPersonalDetailsLoading] = useState(false);

  const { services } = useSelector(selectProviderCriteria);

  const selectInterest = useCallback(
    (selectedInterest: any) => () => {
      const isItemSelected =
        !!interests.length && !!interests.find((interest: any) => interest.id === selectedInterest.id);

      setInterestDetails((state: any): any => {
        if (isItemSelected) {
          return state.filter((interest: any) => interest.id !== selectedInterest.id);
        }

        return [...state, selectedInterest];
      });
    },
    [interests, setInterestDetails],
  );

  const onSubmit = useCallback(async () => {
    setIsPersonalDetailsLoading(true);
    try {
      await api.postPersonalDetailInterests(userId, interests, accessToken);
    } catch (err) {
      console.log('error', err);
    }
    setIsPersonalDetailsLoading(false);
  }, [accessToken, interests, userId]);

  return (
    <Interests
      services={services}
      interests={interests}
      selectInterest={selectInterest}
      onSubmit={onSubmit}
      isPersonalDetailsLoading={isPersonalDetailsLoading}
    />
  );
};

export default InterestsContainer;
