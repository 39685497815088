// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { Title } from 'shared/typography';

// custom components
import ProviderDashboardLayout from '../ProviderDashboardLayout';
import AccountSettings from 'modules/Dashboard/ProviderDashboard/AccountSettings';
import { ColumnedFlex } from 'shared/styledComponents';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
`;

const Heading = styled(Title)`
  margin-bottom: 50px;
`;

const Settings = (): JSX.Element => (
  <ProviderDashboardLayout>
    <Wrapper>
      <Heading>Settings</Heading>
      <AccountSettings />
    </Wrapper>
  </ProviderDashboardLayout>
);

export default Settings;
