// libraries
import React from 'react';
import styled from 'styled-components';
import { FormRenderProps } from 'react-final-form';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import FormSuccess from 'components/Form/FormSuccess';

// styled components
import { ColumnedFlex, SubmitButton, Error } from 'shared/styledComponents';

// styles
import { TextStyles } from 'shared/styles';

// types
import { ForgotPasswordData } from './types';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const FormContentWrapper = styled(ColumnedFlex)`
  width: 100%;
  align-items: flex-start;
`;

const InputsWrapper = styled(ColumnedFlex)`
  margin-bottom: 15px;
  width: 100%;
  max-width: 280px;
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  outline: none;
  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }
  :first-child {
    margin-bottom: 10px;
  }
`;

interface ForgotPasswordFormProps {
  onSubmit: (values: ForgotPasswordData) => void;
  formValidation: (values: ForgotPasswordData) => any;
  handleModalClose: VoidFunction;
}

const ForgotPasswordForm = ({ onSubmit, handleModalClose, formValidation }: ForgotPasswordFormProps): JSX.Element => (
  <Wrapper>
    <Form onSubmit={onSubmit} validate={formValidation}>
      {({
        submitting,
        submitSucceeded,
        submitFailed,
        submitErrors,
        hasValidationErrors,
        errors,
      }: FormRenderProps): JSX.Element =>
        submitSucceeded ? (
          <FormSuccess heading="Your email has been sent successfully" isEmailCheck onClose={handleModalClose} />
        ) : (
          <FormContentWrapper>
            <InputsWrapper>
              <StyledInput name="email" type="email" placeholder="Email" size="small" />
            </InputsWrapper>
            {submitFailed && hasValidationErrors && (
              <Error size="small">
                {!!Object.keys(errors).length &&
                  `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
              </Error>
            )}
            {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
            <SubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
              Send
            </SubmitButton>
          </FormContentWrapper>
        )
      }
    </Form>
  </Wrapper>
);

export default ForgotPasswordForm;
