// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const getProviderSubscription = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/subscription', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postChangeProvidersSubscription = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('payment/change-subscription', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postUnsubscribe = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/cancel-subscription', undefined, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postEmail = (email: string, token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post(
    'provider/update-email',
    {
      email,
    },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );

export default {
  getProviderSubscription,
  postChangeProvidersSubscription,
  postUnsubscribe,
  postEmail,
};
