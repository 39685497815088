// libraries
import React, { useCallback, Dispatch, SetStateAction, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import qs from 'qs';

// custom components
import Docusign from './Docusign';

// selectors
import { selectUser } from 'modules/User/selectors';

// types
import { ProviderOnboardingStepTypes } from 'pages/ProviderOnboarding/types';

// services
import { getDocusignLink, postDocusignLink } from './services';

interface DocusignContainerProps {
  setOpenedStepType: Dispatch<SetStateAction<ProviderOnboardingStepTypes>>;
  signedDate: string | undefined;
}

const DocusignContainer = ({ setOpenedStepType, signedDate }: DocusignContainerProps): JSX.Element => {
  const location = useLocation();

  const queryParams = useMemo(() => qs.parse(location.search, { ignoreQueryPrefix: true, decoder: (c) => c }), [
    location.search,
  ]);

  const {
    token: { accessToken },
  } = useSelector(selectUser);

  const [isDocusignLoading, setIsDocusignLoading] = useState(false);

  const [docusignLink, setDocusignLink] = useState('');

  const [isBaaAgreementStepSucceeded, setIsBaaAgreementStepSucceeded] = useState(false);

  useEffect(() => {
    if (signedDate) {
      setIsBaaAgreementStepSucceeded(true);
    } else {
      (async () => {
        setIsDocusignLoading(true);
        if (queryParams.hasOwnProperty('envelopeId')) {
          try {
            await postDocusignLink(queryParams.providerId, queryParams.envelopeId, queryParams.event, accessToken);

            setIsBaaAgreementStepSucceeded(true);
          } catch (err) {
            console.log(err);
          }
        } else {
          const docusignLink = await getDocusignLink(accessToken);

          setDocusignLink(docusignLink.data.link);
        }
        setIsDocusignLoading(false);
      })();
    }
  }, [accessToken, queryParams, signedDate]);

  const handleNextButtonClick = useCallback(() => {
    setOpenedStepType(ProviderOnboardingStepTypes.Subscription);
  }, [setOpenedStepType]);

  return (
    <Docusign
      onNextButtonClick={handleNextButtonClick}
      isDocusignLoading={isDocusignLoading}
      docusignLink={docusignLink}
      isBaaAgreementStepSucceeded={isBaaAgreementStepSucceeded}
      signedDate={signedDate}
    />
  );
};

export default DocusignContainer;
