// libraries
import React from 'react';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

// custom components
import Form from 'components/Form';
import RadioButton from 'components/Form/RadioButton';
import Checkbox from 'components/Form/Checkbox';

// styled components
import { SubmitButton, Error, Label, Flex } from 'shared/styledComponents';
import { Text, Title } from 'shared/typography';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const FormContentWrapper = styled.div`
  max-width: 550px;
`;

const SubHeading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-top: 15px;
  margin-bottom: 20px;
`;

const StyledText = styled(Text)`
  margin-bottom: 20px;
`;

const RadioButtonsWrapper = styled.div`
  max-width: 70px;
  margin-bottom: 40px;
`;

const RadioButtonWrapper = styled(Flex)`
  margin-bottom: 20px;
`;

const RadioButtonLabel = styled(Label)(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
  `,
);

const CheckboxSubHeading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-bottom: 20px;
`;

const CheckboxWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 50px;
`;

const CheckboxLabel = styled(Label)`
  font-size: 12px;
  margin-left: 10px;
`;

const StyledLink = styled(Text)`
  font-size: 12px;
  line-height: 1;
  text-decoration: underline;
`;

const StyledTitleLink = styled(Title)`
  text-decoration: underline;
`;

interface HipaaFormProps {
  onSubmit: (values: any) => void;
  initialValues: any;
}

const HipaaForm = ({ onSubmit, initialValues }: HipaaFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} initialValues={initialValues}>
    {({ values, submitting, submitFailed, submitErrors, hasValidationErrors, errors }): JSX.Element => (
      <FormContentWrapper>
        <SubHeading size="medium">
          Are you considered a{' '}
          <StyledTitleLink
            as="a"
            href="https://www.hhs.gov/hipaa/for-professionals/covered-entities/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            Covered Entity
          </StyledTitleLink>{' '}
          under HIPAA?
        </SubHeading>
        <StyledText>
          “Physicians, Chiropractors, Psychologists, Physical Therapists, and Registered Dietitians are generally
          considered to be Covered Entities, while Doulas (and other Birth Consultants), Yoga Instructors, Exercise
          Coaches, Nutritionists, Personal Coaches and Massage Therapists are generally not considered Covered Entities.
          This is for informational purposes only and is not considered legal advice. Please consult your attorney or
          HIPAA specialist if you have further questions.”
        </StyledText>
        <RadioButtonsWrapper>
          <RadioButtonWrapper>
            <RadioButton name="hipaa" value="yes" />
            <RadioButtonLabel>Yes</RadioButtonLabel>
          </RadioButtonWrapper>
          <RadioButtonWrapper>
            <RadioButton name="hipaa" value="no" />
            <RadioButtonLabel>No</RadioButtonLabel>
          </RadioButtonWrapper>
        </RadioButtonsWrapper>
        {values.hipaa === 'no' && (
          <>
            <CheckboxSubHeading size="medium">If no, please agree to the below</CheckboxSubHeading>
            <CheckboxWrapper>
              <Checkbox name="certificationAccepted" />
              <CheckboxLabel>
                <StyledLink as={ReactRouterLink} to="/non-covered-entity-certification" size="small" target="_blank">
                  Confirm I am not a Covered Entity
                </StyledLink>
              </CheckboxLabel>
            </CheckboxWrapper>
          </>
        )}
        {submitFailed && hasValidationErrors && (
          <Error size="small">
            {!!Object.keys(errors).length &&
              `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
          </Error>
        )}
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <SubmitButton type="submit" disabled={submitting} isLoading={submitting}>
          next
        </SubmitButton>
      </FormContentWrapper>
    )}
  </Form>
);

export default HipaaForm;
