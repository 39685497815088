// api
import api from './api';

export const setHipaaElegibility = async (values: any, nonCoveredEntityCertification: any, userToken: string) => {
  if (values.hipaa === 'yes') {
    return await api.postHipaaElegibility({ value: true }, userToken);
  }

  if (values.hipaa === 'no') {
    return await api.postHipaaElegibility(
      {
        value: false,
        certificationAccepted: values.certificationAccepted,
        signedDocuments: values.certificationAccepted
          ? [
              {
                name: nonCoveredEntityCertification && nonCoveredEntityCertification?.name,
                version: nonCoveredEntityCertification && nonCoveredEntityCertification?.version,
              },
            ]
          : undefined,
      },
      userToken,
    );
  }
};
