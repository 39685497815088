// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const getProvidersProfileInfo = (providerId: string, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get(`provider/profile?id=${providerId}`, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postProvidersProfileInfo = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/profile', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const getCredentials = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('referencedata/credential', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { getProvidersProfileInfo, postProvidersProfileInfo, getCredentials };
