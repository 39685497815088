// libraries
import React, { useCallback, useMemo, useState } from 'react';

// custom components
import EmailForm from './components/EmailForm';
import EmailFormSuccess from './components/EmailFormSuccess';

//hooks
import useConvertedProviderCriteria from 'hooks/useConvertedProviderCriteria';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

// api
import api from './api';

// mappers
import { convertPopUpInfoToDataLayer } from './mappers';

const EmailFormContainer = (): JSX.Element => {
  const { serviceOptions, stageOptions } = useConvertedProviderCriteria(true);
  const [submitSucceeded, setSubmitSucceeded] = useState(false);
  const [submitError, setSubmitError] = useState(null);

  const handleSubmit = useCallback(async (values: any) => {
    try {
      const convertedValues = convertPopUpInfoToDataLayer(values);

      await api.postSignUp(convertedValues);
      setSubmitSucceeded(true);
    } catch (error) {
      const errMessage = error.response?.data?.message || 'Unable to submit info';
      setSubmitError(errMessage);
      setSubmitSucceeded(false);
    }
  }, []);

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
      firstName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      lastName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  return submitSucceeded ? (
    <EmailFormSuccess />
  ) : (
    <EmailForm
      submitError={submitError}
      onSubmit={handleSubmit}
      stageOptions={stageOptions}
      serviceOptions={serviceOptions}
      formValidation={formValidation}
    />
  );
};

export default EmailFormContainer;
