// libraries
import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

// custom components
import LogInForm from './LogInForm';

// hooks
import useModal from 'hooks/useModal';

// services
import { convertLogInValuesToDataModel, convertUserDataToViewModel } from './services';

// actions
import { setUser } from 'modules/User/actions';

// api
import { postLogIn } from 'modules/User/api';

// types
import { LogInData } from './types';
import { useHistory } from 'react-router-dom';

// utils
import { validate } from 'utils/validation';
import { VALIDATION_TYPES } from 'utils/validation/types';

// constants
import { UserRoles } from 'constants/user';

const LogInFormContainer = ({ fromRedirect, locationRedirectPath, locationRedirectSearch }: any): JSX.Element => {
  const history = useHistory();

  const dispatch = useDispatch();

  const { handleModalOpen, handleModalClose } = useModal();

  const validationSchema = useMemo(
    () => ({
      email: {
        [VALIDATION_TYPES.REQUIRED]: true,
        [VALIDATION_TYPES.EMAIL]: true,
      },
      password: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const handleSubmit = useCallback(
    async (values: LogInData) => {
      const convertedValues = convertLogInValuesToDataModel(values);

      const user = await postLogIn(convertedValues);

      const convertedUser = convertUserDataToViewModel(user.data);

      dispatch(setUser(convertedUser));

      if (fromRedirect) {
        if (locationRedirectSearch) {
          history.push(locationRedirectPath + locationRedirectSearch);
        } else {
          history.push(locationRedirectPath);
        }
      } else {
        if (convertedUser.role === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION) {
          history.push('/provider/dashboard/settings');
        }

        if (convertedUser.role === UserRoles.PROVIDER_BASIC || convertedUser.role === UserRoles.PROVIDER_PRO) {
          history.push('/provider/dashboard/edit-profile');
        }

        if (convertedUser.role === UserRoles.PROVIDER_APPLICANT) {
          history.push('/create-provider-account');
        }
      }

      handleModalClose();
    },
    [dispatch, fromRedirect, handleModalClose, locationRedirectSearch, locationRedirectPath, history],
  );
  return <LogInForm onSubmit={handleSubmit} handleModalOpen={handleModalOpen} formValidation={formValidation} />;
};

export default LogInFormContainer;
