// api
import api from './api';

// types
import { AxiosResponse } from 'axios';

export const getDocusignLink = async (userToken: string): Promise<AxiosResponse<any>> =>
  await api.getDocusignLink(userToken);

export const postDocusignLink = async (
  providerId: any,
  envelopeId: any,
  event: any,
  userToken: string,
): Promise<AxiosResponse<any>> => await api.postDocusignLink({ providerId, envelopeId, event }, userToken);
