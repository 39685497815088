// libraries
import { handleActions } from 'redux-actions';

// actions
import { setUser, setUserToken, setUserRole, setUserImage, setUserName, clearUser } from './actions';

const defaultState = {
  userId: '',
  role: '',
  firstName: '',
  lastName: '',
  slug: '',
  image: '',
  token: { accessToken: '', refreshToken: '' },
  isLoggedIn: false,
};

const userReducer = handleActions(
  {
    [setUser.toString()]: (
      _,
      {
        payload: {
          userId,
          role,
          firstName,
          lastName,
          slug,
          token: { accessToken, refreshToken },
        },
      }: any,
    ) => ({
      userId,
      role,
      firstName,
      lastName,
      slug,
      token: { accessToken, refreshToken },
      isLoggedIn: true,
      image: '',
    }),
    [setUserToken.toString()]: (state, { payload: { accessToken, refreshToken } }: any) => ({
      ...state,
      token: { accessToken, refreshToken },
      isLoggedIn: true,
    }),
    [setUserRole.toString()]: (state, { payload }: any) => ({
      ...state,
      role: payload,
    }),
    [setUserImage.toString()]: (state, { payload }: any) => ({
      ...state,
      image: payload,
    }),
    [setUserName.toString()]: (state, { payload: { firstName, lastName } }: any) => ({
      ...state,
      firstName,
      lastName,
    }),
    [clearUser.toString()]: () => defaultState,
  },
  defaultState,
);

export default userReducer;
