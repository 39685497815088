// libraries
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';
import Checkbox from 'components/Form/Checkbox';
import FormSuccess from 'components/Form/FormSuccess';

// styled components
import { Flex, ColumnedFlex, SubmitButton, Error, Label } from 'shared/styledComponents';
import { Title, Text } from 'shared/typography';

// styles
import { TextStyles } from 'shared/styles';
import devices from 'theme/devices';

// types
import { CreateAccountData, validationMessages } from './types';

// utils
import { getValue } from 'utils/getValue';

const Wrapper = styled(ColumnedFlex)`
  width: 100%;
  max-width: 580px;
  align-items: flex-start;
`;

const Heading = styled(Title)`
  margin-bottom: 35px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const FormContentWrapper = styled(ColumnedFlex)`
  min-height: 300px;
  align-items: flex-start;
  justify-content: center;

  @media ${devices.mobile} {
    height: fit-content;
  }
`;

export const PairedInputs = styled(Flex)`
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;

  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

const InputsWrapper = styled(ColumnedFlex)`
  width: 100%;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: calc(50% - 10px);
  border: 1px solid ${({ theme: { colors } }): string => colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  outline: none;

  ::placeholder {
    color: ${({ theme: { colors } }): string => colors.lightBrown};
  }

  @media ${devices.mobile} {
    width: 100%;
    margin-bottom: 15px;
  }
`;

const CheckboxWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 30px;

  @media ${devices.mobile} {
    margin-bottom: 0;
  }
`;

const CheckboxLabel = styled(Label)`
  font-size: 10px;
  line-height: 15px;
  margin-left: 20px;

  @media ${devices.mobile} {
    margin-left: 15px;
  }
`;

const StyledLink = styled(Text)`
  font-size: 10px;
  line-height: 15px;
  text-decoration: underline;
`;

interface CreateAccountFormProps {
  onSubmit: (values: CreateAccountData) => void;
  onSuccessComponentButtonClick: (formResetHandler: any) => () => void;
  formValidation: (values: CreateAccountData) => any;
}

const CreateAccountForm = ({
  onSubmit,
  onSuccessComponentButtonClick,
  formValidation,
}: CreateAccountFormProps): JSX.Element => (
  <Form onSubmit={onSubmit} validate={formValidation}>
    {({
      form: { reset },
      submitting,
      submitSucceeded,
      submitFailed,
      submitErrors,
      hasValidationErrors,
      errors,
    }: FormRenderProps): JSX.Element => (
      <Wrapper>
        <FormContentWrapper>
          {submitSucceeded ? (
            <FormSuccess
              heading="Your account has been created successfully"
              isEmailCheck
              onClose={onSuccessComponentButtonClick(reset)}
            />
          ) : (
            <>
              <Heading>Create an Account</Heading>
              <InputsWrapper>
                <PairedInputs>
                  <StyledInput name="firstName" type="text" placeholder="First Name" size="small" />
                  <StyledInput name="lastName" type="text" placeholder="Last Name" size="small" />
                </PairedInputs>
                <PairedInputs>
                  <StyledInput name="email" type="email" placeholder="Email" size="small" autoComplete="false" />
                  <StyledInput name="password" type="password" placeholder="Create a Password" size="small" />
                </PairedInputs>
                <CheckboxWrapper>
                  <Checkbox name="termsAccepted" />
                  <CheckboxLabel>
                    I have read the terms and conditions for&nbsp;
                    <StyledLink as={ReactRouterLink} to="/terms-of-use" size="small" target="_blank">
                      Terms of Use
                    </StyledLink>
                    ,&nbsp;
                    <StyledLink as={ReactRouterLink} to="/privacy-policy" size="small" target="_blank">
                      Privacy Policy
                    </StyledLink>
                    ,&nbsp;and&nbsp;
                    <StyledLink as={ReactRouterLink} to="/hipaa-authorization" size="small" target="_blank">
                      HIPAA Authorization
                    </StyledLink>
                  </CheckboxLabel>
                </CheckboxWrapper>
              </InputsWrapper>
              {submitFailed && hasValidationErrors && (
                <Error size="small">
                  {!!Object.keys(errors).length &&
                    `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
                </Error>
              )}
              {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
              <SubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
                Create Account
              </SubmitButton>
            </>
          )}
        </FormContentWrapper>
      </Wrapper>
    )}
  </Form>
);

export default CreateAccountForm;
