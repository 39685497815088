// libraries
import React from 'react';
import { Field, FieldProps, FieldRenderProps } from 'react-final-form';

const Input = (props: FieldProps<string, FieldRenderProps<string, HTMLElement>, HTMLInputElement>): JSX.Element => (
  <Field {...props}>
    {({ input }: any) => (
      <input
        className={props.className}
        {...input}
        {...props}
        onChange={(e: any) => {
          input.onChange(e);

          if (props.onChange) {
            props.onChange(e);
          }
        }}
      />
    )}
  </Field>
);

export default Input;
