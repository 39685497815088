// libraries
import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import momentTimeZone from 'moment-timezone';

// styled components
import { Title, Text } from 'shared/typography';
import { Flex, ColumnedFlex } from 'shared/styledComponents';

// types
import { ConsultationTypes } from 'types';
import { CONSULTATION_TYPE } from 'constants/booking';
import { UserRoles } from 'constants/user';

// styles
import devices from 'theme/devices';

const Wrapper = styled(Flex)`
  justify-content: space-between;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  opacity: 0.6;

  :last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }

  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 40px;
    padding-bottom: 40px;
  }
`;

const DateWrapper = styled.div`
  @media ${devices.mobile} {
    margin-bottom: 25px;
  }
`;

const StyledDate = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
`;

const TimeSlotsWrapper = styled(ColumnedFlex)`
  width: 100%;
  max-width: ${({ role }): string => (role === UserRoles.CLIENT ? '640px' : '750px')};
`;

const TimeSlotWrapper = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme: { colors } }): string => colors.alto};
  padding-bottom: 25px;
  margin-bottom: 25px;

  :last-child {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media ${devices.mobile} {
    flex-direction: column;
    margin-bottom: 35px;
    padding-bottom: 35px;
  }
`;

const Time = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  line-height: 1;

  @media ${devices.mobile} {
    margin-bottom: 15px;
  }
`;

const UserName = styled(Text)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  line-height: 1;

  @media ${devices.mobile} {
    margin-bottom: 15px;
  }
`;

const ConsultationType = styled(Text)`
  line-height: 1;

  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;
const UserNameMargin = styled(UserName)`
  margin-bottom: 15px;
`;
interface PassedAppointmentsDayProps {
  day: string;
  role: any;
  consultations: any;
}

const PassedAppointmentsDay = ({ day, consultations, role }: PassedAppointmentsDayProps): JSX.Element => (
  <Wrapper>
    <DateWrapper>
      <StyledDate size="small">{moment(day).format('MMMM D, yyyy')}</StyledDate>
    </DateWrapper>
    <TimeSlotsWrapper role={role}>
      {consultations.map(
        ({
          time,
          timeZone,
          clientName,
          clientEmail,
          clientPhone,
          clientCity,
          clientState,
          consultationType,
          providerName,
          range,
        }: any) => (
          <TimeSlotWrapper key={time}>
            <Time size="small">{`${moment(time).format('h:mm a')} - ${momentTimeZone
              .tz(time, timeZone)
              .add(range, 'h')
              .format('h:mm a (z)')}`}</Time>
            <ColumnedFlex>
              <UserNameMargin>{role === UserRoles.CLIENT ? providerName : clientName}</UserNameMargin>
              <UserName as="a" href={`mailto: ${clientEmail}`}>
                {clientEmail}
              </UserName>
            </ColumnedFlex>
            <ColumnedFlex>
              {clientCity && clientState && <UserNameMargin>{`${clientCity}, ${clientState}`}</UserNameMargin>}
              {clientPhone && (
                <UserName as="a" href={`tel: ${clientPhone}`}>
                  {clientPhone}
                </UserName>
              )}
            </ColumnedFlex>
            <ConsultationType>{CONSULTATION_TYPE[consultationType as ConsultationTypes]}</ConsultationType>
          </TimeSlotWrapper>
        ),
      )}
    </TimeSlotsWrapper>
  </Wrapper>
);

export default PassedAppointmentsDay;
