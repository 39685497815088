// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Email from './Email';
import ChangePassword from './ChangePassword';

// styled components
import { ColumnedFlex } from 'shared/styledComponents';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';
import DeleteAccount from './DeleteAccount';

const PersonalDetailsWrapper = styled(ColumnedFlex)`
  width: 100%;

  @media ${devices.mobile} {
    margin-top: 20px;
  }
`;

const EmailWrapper = styled.div`
  width: 100%;
  padding-bottom: 70px;
  border-bottom: 1px solid ${colors.alto};

  @media ${devices.mobile} {
    padding-bottom: 45px;
  }
`;

const PasswordWrapper = styled(ColumnedFlex)`
  margin-top: 55px;
  padding-bottom: 70px;
  border-bottom: 1px solid ${colors.alto};

  @media ${devices.mobile} {
    margin-top: 35px;
  }
`;

const DeleteAccountWrapper = styled(ColumnedFlex)`
  margin-top: 55px;
  padding-bottom: 70px;

  @media ${devices.mobile} {
    margin-top: 35px;
  }
`;

interface SettingsProps {
  accessToken: string;
  handleModalOpen: any;
  onDeleteAccountButtonClick: any;
}

const Settings = ({ accessToken, handleModalOpen, onDeleteAccountButtonClick }: SettingsProps): JSX.Element => (
  <PersonalDetailsWrapper>
    <EmailWrapper>
      <Email accessToken={accessToken} />
    </EmailWrapper>
    <PasswordWrapper>
      <ChangePassword accessToken={accessToken} />
    </PasswordWrapper>
    <DeleteAccountWrapper>
      <DeleteAccount handleModalOpen={handleModalOpen} onDeleteAccountButtonClick={onDeleteAccountButtonClick} />
    </DeleteAccountWrapper>
  </PersonalDetailsWrapper>
);

export default Settings;
