import colors from './colors';
import fonts from './fonts';
import devices from './devices';

const theme = {
  colors,
  fonts,
  devices,
};

export default theme;
