export enum ConsultationTypes {
  Available = 0,
  InPerson = 1,
  Online = 2,
  FreeConsultation = 4,
}

export interface UserData {
  userId: string;
  role: string;
  firstName: string;
  lastName: string;
  slug: string;
  token: { accessToken: string; refreshToken: string };
}
