// libraries
import { useEffect, useState } from 'react';

// api
import api from 'modules/ProviderOnboarding/Subscriptions/api';

// types
import { ProductSubscription } from 'modules/ProviderOnboarding/Subscriptions/types';

interface UseProductSubscriptions {
  productSubscriptions: ProductSubscription[];
}

const useProductSubscriptions = (): UseProductSubscriptions => {
  const [productSubscriptions, setProductSubscriptions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const productSubscriptions = await api.getProductSubscriptions();

        setProductSubscriptions(productSubscriptions.data);
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  return { productSubscriptions };
};

export default useProductSubscriptions;
