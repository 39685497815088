// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';

// styled components
import { SubmitButton, Error, Flex } from 'shared/styledComponents';
import { Title } from 'shared/typography';
import { TextStyles } from 'shared/styles';

// styles
import devices from 'theme/devices';
import colors from 'theme/colors';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

interface EditProfileProps {
  onSubmit: (values: any) => void;
  formValidation: (values: any) => void;
  initialValues: any;
}

const Heading = styled(Title)`
  margin-bottom: 40px;

  @media ${devices.mobile} {
    margin-bottom: 30px;
  }
`;

const PairedInputs = styled(Flex)`
  justify-content: space-between;
  max-width: 565px;
  margin-bottom: 20px;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  max-width: 270px;
  border: 1px solid ${colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  margin-right: 10px;
  outline: none;

  :last-child {
    margin-right: 0;
  }

  ::placeholder {
    color: ${colors.lightBrown};
  }

  @media ${devices.mobile} {
    max-width: 310px;
    margin-right: 0;
    margin-bottom: 15px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledSubmitButton = styled(SubmitButton)`
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 19px 30px;

  @media ${devices.mobile} {
    margin-top: 45px;
    margin-bottom: 0;
    padding: 16px 21px;
  }
`;

const SuccessTitle = styled(Title)`
  margin-top: 20px;
`;

const EditProfile = ({ onSubmit, initialValues, formValidation }: EditProfileProps) => (
  <Form onSubmit={onSubmit} initialValues={initialValues} validate={formValidation}>
    {({ submitting, submitSucceeded, submitFailed, submitErrors, hasValidationErrors, errors }): JSX.Element => (
      <>
        <Heading>Edit Profile</Heading>
        <PairedInputs>
          <StyledInput name="firstName" type="text" placeholder="First Name" size="small" />
          <StyledInput name="lastName" type="text" placeholder="Last Name" size="small" />
        </PairedInputs>
        {submitFailed && hasValidationErrors && (
          <Error size="small">
            {!!Object.keys(errors).length &&
              `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
          </Error>
        )}
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <StyledSubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
          update
        </StyledSubmitButton>
        {submitSucceeded && <SuccessTitle>Updated</SuccessTitle>}
      </>
    )}
  </Form>
);

export default EditProfile;
