// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from './restapi';

const getTermsContent = (name: string, onlyMetadata = true): Promise<AxiosResponse<any>> =>
  apiRequest.get(`/content?name=${name}&onlyMetadata=${onlyMetadata}`);

export default { getTermsContent };
