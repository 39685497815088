/* eslint-disable */
// types
import { FIELD_VALIDATIONS } from './types';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';

const getValidationError = (key: any, value: any, obj: any) => (obj[key] = value); //

export const validate = (values: any, validationSchema: any) => {
  const errors = {};

  for (const [validationFieldName, validationRules] of Object.entries(validationSchema)) {
    if (!Object.keys(validationRules as any).length) return;

    let resultOfFieldValidation: undefined | string;

    Object.entries(validationRules as any).some(([typeOfValidation, validationParams]) => {
      if (resultOfFieldValidation) return;
      let params = validationParams as any;

      if (typeOfValidation === VALIDATION_TYPES.PASSWORD_CONFIRMATION) {
        params = values.password;
      }

      resultOfFieldValidation = FIELD_VALIDATIONS[typeOfValidation](values[validationFieldName], params);
    });

    if (resultOfFieldValidation) getValidationError(validationFieldName, resultOfFieldValidation, errors);
  }

  return errors;
};
