// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import Form from 'components/Form';
import Input from 'components/Form/Input';

// styled components
import { SubmitButton, Error, Flex, ColumnedFlex } from 'shared/styledComponents';
import { Title } from 'shared/typography';
import { TextStyles } from 'shared/styles';

// styles
import devices from 'theme/devices';
import colors from 'theme/colors';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 330px;
`;

const StepWrapper = styled.div``;

export const Heading = styled(Title)<any>(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
    margin-bottom: 25px;
  `,
);

const InputsWrapper = styled(ColumnedFlex)`
  justify-content: space-between;
  margin-bottom: 20px;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const StyledInput = styled(Input)`
  ${TextStyles};
  width: 100%;
  max-width: 270px;
  border: 1px solid ${colors.alto};
  border-radius: 4px;
  padding: 13px 15px;
  margin-bottom: 15px;
  outline: none;

  :last-child {
    margin-bottom: 0;
  }

  ::placeholder {
    color: ${colors.lightBrown};
  }

  @media ${devices.mobile} {
    max-width: 310px;
    margin-right: 0;
    margin-bottom: 15px;

    :last-child {
      margin-bottom: 0;
    }
  }
`;

const SuccessTitle = styled(Title)`
  margin-top: 20px;
`;

interface EditProfileFormProps {
  onSubmit: (values: any) => void;
}

const ManualBankAccountConnectionForm = ({ onSubmit }: EditProfileFormProps): JSX.Element => (
  <Wrapper>
    <Form onSubmit={onSubmit}>
      {({ submitting, submitSucceeded, submitFailed, submitErrors, hasValidationErrors, errors }): JSX.Element => (
        <>
          <StepWrapper>
            <Heading size="medium">Or user can use manual conection</Heading>
            <InputsWrapper>
              <StyledInput name="routingNumber" type="text" placeholder="Routing Number" size="small" />
              <StyledInput name="accountNumber" type="text" placeholder="Account Number" size="small" />
              <StyledInput name="accountHolderName" type="text" placeholder="Account Holder Name" size="small" />
            </InputsWrapper>
          </StepWrapper>
          {submitFailed && hasValidationErrors && (
            <Error size="small">
              {!!Object.keys(errors).length &&
                `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
            </Error>
          )}
          {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
          <SubmitButton type="submit" disabled={submitting} isLoading={submitting}>
            save
          </SubmitButton>
          {submitSucceeded && <SuccessTitle>Saved</SuccessTitle>}
        </>
      )}
    </Form>
  </Wrapper>
);

export default ManualBankAccountConnectionForm;
