// libraries
import React from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled, { css } from 'styled-components';

// styles
import { Flex, Frame, ColumnedFlex, Link } from 'shared/styledComponents';
import { Title } from 'shared/typography';
import devices from 'theme/devices';

// assets
import robynLogo from 'assets/images/robyn-logo.png';

const FooterWrapper = styled(ColumnedFlex)<any>`
  width: 100%;
  background-color: ${({ theme: { colors } }): string => colors.secondary};

  padding-top: ${({ isAfterForms }) => (isAfterForms ? '0' : '130px')};
`;

const LogoImage = styled.img`
  width: 100px;
  height: 45px;
`;

const ServicesWrapper = styled(Flex)`
  margin: 100px 0 45px;
  flex-wrap: wrap;

  @media ${devices.mobile} {
    margin: 95px 0 0;
  }
`;

const Service = styled(ColumnedFlex)`
  &:not(:last-child) {
    margin-right: 90px;
  }

  @media ${devices.mobile} {
    margin-bottom: 55px;

    &:nth-of-type(even) {
      margin-right: 40px;
    }

    &:nth-child(odd) {
      margin-right: 15px;
    }
  }
`;

const ServiceTitle = styled(Title)`
  font-size: 14px;
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  font-weight: bold;
  padding-bottom: 25px;
`;

const ServiceItemsWrapper = styled.ul<any>`
  display: grid;
  grid-template-columns: ${({ colums }): string => `repeat(${colums}, 1fr)`};
  column-gap: 85px;
  row-gap: 15px;
  white-space: nowrap;

  @media ${devices.mobile} {
    column-gap: 45px;
  }
`;

const ServiceItemWrapper = styled.li`
  list-style-type: none;
`;

const ServiceItem = styled(Link)`
  font-size: 14px;
  text-decoration: underline;
  line-height: 30px;
  list-style: none;
  color: ${({ theme: { colors } }): string => colors.bismark};

  @media ${devices.mobile} {
    min-width: 120px;
  }
`;

const SubFooterWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 60px;

  @media ${devices.mobile} {
    flex-direction: column-reverse;
    margin-bottom: 30px;
  }
`;

const FooterText = css`
  font-size: 12px !important;
  line-height: 19px !important;
  color: ${({ theme: { colors } }): string => colors.blue};
`;

const TextWrapper = styled(Title)`
  ${FooterText};
  width: 430px;
  margin-bottom: 60px;

  @media ${devices.mobile} {
    max-width: 290px;
    margin-bottom: 40px;
    ${FooterText};
  }
`;

const CopyRightText = styled(Title)`
  height: fit-content;
  align-self: center;
  margin-right: 80px;
  margin-left: 15px;
  margin-top: 10px;
  ${FooterText};
`;

const LinkItem = styled(Link)`
  ${FooterText};
  margin-top: 10px;
  text-decoration: underline;

  &:not(:last-child) {
    width: fit-content;
    margin-right: 15px;
  }
`;
const LinksWrapper = styled(Flex)`
  flex-wrap: wrap;

  @media ${devices.mobile} {
    margin-bottom: 40px;
  }
`;

const LogoWrapper = styled(Flex)`
  @media ${devices.mobile} {
    align-self: start;
  }
`;

const ImagesWrapper = styled.div`
  display: flex;
  margin-left: 1px;

  @media ${devices.mobile} {
    display: none;
  }
`;

const Image = styled.a<any>`
  flex: 1;
  height: 287px;
  margin-right: 1px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  :hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

interface FooterProps {
  services: any;
  isAfterForms: boolean;
  instagramMedia: any;
}

const Footer = ({ services, isAfterForms, instagramMedia }: FooterProps): JSX.Element => (
  <FooterWrapper as="footer" isAfterForms={isAfterForms}>
    <Frame>
      <ServicesWrapper>
        <Service>
          <ServiceTitle size="small">Services</ServiceTitle>

          <ServiceItemsWrapper colums={2}>
            {!!services?.length &&
              services.map(({ id, value }: any) => (
                <ServiceItemWrapper key={id}>
                  <ServiceItem
                    as={ReactRouterLink}
                    to={`/service/${value.split(' ').join('-').toLowerCase()}`}
                    size="small"
                  >
                    {value}
                  </ServiceItem>
                </ServiceItemWrapper>
              ))}
          </ServiceItemsWrapper>
        </Service>
        <Service>
          <ServiceTitle size="small">Community</ServiceTitle>
          <ServiceItemsWrapper>
            <ServiceItemWrapper>
              <ServiceItem as={ReactRouterLink} to={'/'} size="small">
                Real Stories
              </ServiceItem>
            </ServiceItemWrapper>
            <ServiceItemWrapper>
              <ServiceItem as={ReactRouterLink} to={'/learn'} size="small">
                Articles
              </ServiceItem>
            </ServiceItemWrapper>
            <ServiceItemWrapper>
              <ServiceItem as={ReactRouterLink} to={'/share-your-journey'} size="small">
                Share Your Journey
              </ServiceItem>
            </ServiceItemWrapper>
          </ServiceItemsWrapper>
        </Service>
        <Service>
          <ServiceItemsWrapper>
            <ServiceItemWrapper>
              <ServiceItem as={ReactRouterLink} to={'/classes'} size="small">
                Classes
              </ServiceItem>
            </ServiceItemWrapper>
            <ServiceItemWrapper>
              <ServiceItem as={ReactRouterLink} to={'/for-providers'} size="small">
                For Providers
              </ServiceItem>
            </ServiceItemWrapper>
          </ServiceItemsWrapper>
        </Service>
        <Service>
          <ServiceTitle size="small">Support</ServiceTitle>
          <ServiceItemsWrapper>
            <ServiceItemWrapper>
              <ServiceItem href="mailto: hello@wearerobyn.co" size="small">
                Contact
              </ServiceItem>
            </ServiceItemWrapper>
          </ServiceItemsWrapper>
        </Service>
      </ServicesWrapper>
      <TextWrapper>
        The information on wearerobyn.co is not intended or implied to be a substitute for professional medical advice,
        diagnosis or treatment.
      </TextWrapper>
      <SubFooterWrapper>
        <LogoWrapper>
          <ReactRouterLink to="/">
            <LogoImage data-src={robynLogo} alt="Robyn logo" className="lazyload" />
          </ReactRouterLink>
          <CopyRightText>©2020 Robyn</CopyRightText>
        </LogoWrapper>
        <LinksWrapper>
          <LinkItem as={ReactRouterLink} to="/terms-of-use" size="small" target="_blank">
            Terms of Use
          </LinkItem>
          <LinkItem as={ReactRouterLink} to="/privacy-policy" size="small" target="_blank">
            Privacy Policy
          </LinkItem>
          <LinkItem as={ReactRouterLink} to="/dmca-copyright-policy" size="small" target="_blank">
            DMCA Copyright Policy
          </LinkItem>
        </LinksWrapper>
      </SubFooterWrapper>
    </Frame>
    <ImagesWrapper>
      {!!instagramMedia.length &&
        instagramMedia
          .slice(0, 5)
          .map(({ id, media_url, permalink, thumbnail_url }: any) => (
            <Image
              key={id}
              href={permalink}
              target="_blank"
              rel="noopener noreferrer"
              data-bg={thumbnail_url ? thumbnail_url : media_url}
              className="lazyload"
            />
          ))}
    </ImagesWrapper>
  </FooterWrapper>
);

export default Footer;
