// api
import apiRequest from 'api/restapi';
import axios, { AxiosResponse } from 'axios';
import { convertProviderCriteriaToViewModel } from './services';

const getStages = (): Promise<AxiosResponse<any>> => apiRequest.get(`ReferenceData/client-stage`);

const getServices = (): Promise<AxiosResponse<any>> => apiRequest.get(`ReferenceData/service`);

const getSpecialzations = (): Promise<AxiosResponse<any>> => apiRequest.get(`ReferenceData/specialization`);

const getIdenities = (): Promise<AxiosResponse<any>> => apiRequest.get(`ReferenceData/race-identities`);

const getProviderCriteria = (): any =>
  axios
    .all([getStages(), getServices(), getSpecialzations(), getIdenities()])
    .then(axios.spread((...values) => convertProviderCriteriaToViewModel(...values)));

export const getProductOffered = (): Promise<AxiosResponse<any>> => apiRequest.get('referencedata/product-offered');

export default { getStages, getServices, getSpecialzations, getProviderCriteria, getIdenities };
