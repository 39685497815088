import { BaseTimeOption, TimeSlotOption } from '../types';

export const getConvertedTimeOptions = (
  id: number,
  baseTimeOptions: BaseTimeOption[],
  consultationType: string | number,
  isFromRange: boolean,
): TimeSlotOption[] =>
  baseTimeOptions.map((option: BaseTimeOption) => ({
    value: { id, time: option.value, consultationType: consultationType, isFromRange },
    label: option.label,
  }));
