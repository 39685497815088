// libraries
import React from 'react';
import styled from 'styled-components';

// styled components
import { CentredFlex, PrimaryButton } from 'shared/styledComponents';
import { Title, H4 } from 'shared/typography';

// styles
import devices from 'theme/devices';

const Wrapper = styled(CentredFlex)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;

const StyledTitle = styled(Title)`
  margin-bottom: 20px;
`;

const CloseButton = styled(PrimaryButton)<any>`
  padding: 10px 25px;
`;

const Heading = styled(H4)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;

  @media ${devices.mobile} {
    font-size: 20px;
  }
`;

interface CreateAccountSuccessProps {
  heading: string;
  isEmailCheck?: boolean;
  onClose: VoidFunction;
}

const FormSuccess = ({ heading, isEmailCheck = false, onClose }: CreateAccountSuccessProps): JSX.Element => (
  <Wrapper>
    <Heading>{heading}</Heading>
    {isEmailCheck && <StyledTitle>Check your email for verification</StyledTitle>}
    <CloseButton size="small" onClick={onClose}>
      ok
    </CloseButton>
  </Wrapper>
);

export default FormSuccess;
