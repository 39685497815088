// libraries
import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

// custom components
import Select from 'components/Form/Select/Select';
import Icon from 'components/Icon';

// styled components
import { Title } from 'shared/typography';
import { Flex, ColumnedFlex, SecondaryButton } from 'shared/styledComponents';

// styles
import colors from 'theme/colors';
import devices from 'theme/devices';
import fonts from 'theme/fonts';

// types
import { TimeSlot, TimeSlotOption } from '../types';

// constants
import { timeSlotSelectTypes } from '../constants';

const Wrapper = styled(ColumnedFlex)`
  align-items: flex-start;
  margin-bottom: 40px;

  :last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  margin-bottom: 20px;
`;

const SelectsWrapper = styled(Flex)`
  align-items: center;
  margin-bottom: 25px;
`;

const AddOneMoreButton = styled(SecondaryButton)``;

const SelectHeading = styled(Heading)`
  margin: 0 40px;

  @media ${devices.mobile} {
    margin: 0 10px;
  }
`;

const SelectTextStyles = css`
  font-family: ${fonts.wigrumLight};
  font-size: 14px;
  color: ${colors.lightBrown};
`;

const SelectStyles = {
  container: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    width: 176px;
    border: 1px solid ${colors.alto};

    @media ${devices.mobile} {
      width: 122px;
    }
  `,
  control: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0 14px;
    box-shadow: none;
    cursor: pointer;

    @media ${devices.mobile} {
      padding: 0 10px;
    }
  `,
  input: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  valueContainer: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      padding: 0;
    `,
  placeholder: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation => css`
    ${provided};
    ${SelectTextStyles};
  `,
  singleValue: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
    `,
  menuList: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      ${SelectTextStyles};
      color: ${colors.bismark};
    `,
  indicatorSeparator: (provided: FlattenSimpleInterpolation): FlattenSimpleInterpolation =>
    css`
      ${provided};
      display: none;
    `,
};

const StyledCloseIcon = styled(Icon)<any>`
  margin-left: 15px;
  cursor: pointer;
`;

interface TimeSlotsProps {
  title: string;
  timeSlots: TimeSlot[];
  onTimeSlotAdd: VoidFunction;
  onTimeSlotDelete: (id: number) => void;
  handleSelectChange: (id: number, type: string) => (selectedOption: TimeSlotOption) => void;
}

const TimeSlots = ({
  title,
  timeSlots,
  onTimeSlotAdd,
  onTimeSlotDelete,
  handleSelectChange,
}: TimeSlotsProps): JSX.Element => (
  <Wrapper>
    <Heading size="medium">{title}</Heading>
    {timeSlots.map(({ id, timeOptionsFrom, timeOptionsTo, consultationType }: any) => (
      <SelectsWrapper key={id}>
        <Select
          name={`${consultationType}-${timeSlotSelectTypes.FROM}-${id}`}
          options={timeOptionsFrom}
          customStyles={SelectStyles}
          dropdownIndicatorColor={colors.bismark}
          dropdownIndicatorWidth="15px"
          dropdownIndicatorHeight="9px"
          onSelectChange={handleSelectChange(id, timeSlotSelectTypes.FROM)}
        />
        <SelectHeading>to</SelectHeading>
        <Select
          name={`${consultationType}-${timeSlotSelectTypes.TO}-${id}`}
          options={timeOptionsTo}
          customStyles={SelectStyles}
          dropdownIndicatorWidth="15px"
          dropdownIndicatorHeight="9px"
          dropdownIndicatorColor={colors.bismark}
          onSelectChange={handleSelectChange(id, timeSlotSelectTypes.TO)}
        />
        <StyledCloseIcon type="close" width="10px" height="10px" onClick={onTimeSlotDelete(id)} />
      </SelectsWrapper>
    ))}

    <AddOneMoreButton type="button" onClick={onTimeSlotAdd} size="small">
      + add another time slot
    </AddOneMoreButton>
  </Wrapper>
);

export default TimeSlots;
