/* eslint-disable @typescript-eslint/camelcase */
// libraries
import React, { useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// custom components
import Form from 'components/Form';
import RadioButton from 'components/Form/RadioButton';

// styled components
import { SubmitButton, Error, Label, Flex } from 'shared/styledComponents';
import { Text, Title } from 'shared/typography';

// styles
import { TextStyles } from 'shared/styles';

// types
import { ProductSubscription, ListingType, PricingTypeText } from './types';

// api
import api from './api';

// selectors
import { selectUser } from 'modules/User/selectors';

// actions
import { setUserRole } from 'modules/User/actions';

const SubHeading = styled(Title)`
  font-family: ${({ theme: { fonts } }): string => fonts.wigrumRegular};
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-bottom: 40px;
`;

const StyledLink = styled(Title)`
  text-decoration: underline;
`;

const FormContentWrapper = styled.div`
  max-width: 550px;
`;

const SubscriptionWrapper = styled.div`
  margin-bottom: 50px;
`;

const Description = styled(Text)`
  margin-left: 35px;
`;

const RadioButtonWrapper = styled(Flex)`
  margin-bottom: 15px;
`;

const RadioButtonLabel = styled(Label)(
  ({ theme: { colors, fonts } }) => `
    font-family: ${fonts.wigrumRegular};
    color: ${colors.lightBrown};
  `,
);

const StyledText = styled.span`
  ${TextStyles};
  text-decoration: underline;
`;

interface SubscriptionsFormProps {
  productSubscriptions: ProductSubscription[];
  initialValues: any;
}

const SubscriptionsForm = ({ productSubscriptions, initialValues }: SubscriptionsFormProps): JSX.Element => {
  const {
    userId,
    token: { accessToken },
  } = useSelector(selectUser);

  const history = useHistory();
  const dispatch = useDispatch();

  const handleSubmit = useCallback(
    async (values) => {
      const selectedProductSubscription = productSubscriptions
        ? productSubscriptions.find(
            (productSubscription: ProductSubscription) => productSubscription.id === values.productSubscription,
          )
        : {};
      await api.postProductSubscription(
        {
          providerId: userId,
          productSubscription: { ...selectedProductSubscription },
        },
        accessToken,
      );

      const result = await api.postStripePaySubscription(
        {
          providerId: userId,
          paymentMethodId: null,
          paymentType: values.paymentType,
          coupon: values.coupon,
        },
        accessToken,
      );
      dispatch(setUserRole(result.data.providerRole));
      history.push('/create-provider-account/complete');
    },
    [productSubscriptions, userId, accessToken],
  );

  return (
    <>
      <SubHeading>
        Your Subscription is governed by the{' '}
        <StyledLink as={ReactRouterLink} to="/provider-agreement" size="small" target="_blank">
          Provider Agreement
        </StyledLink>
        .
      </SubHeading>
      <Form onSubmit={handleSubmit} initialValues={initialValues}>
        {({ submitting, submitFailed, submitErrors }): JSX.Element => (
          <FormContentWrapper>
            {productSubscriptions.map(({ id, name, price, description }) => (
              <SubscriptionWrapper key={id}>
                <RadioButtonWrapper>
                  <RadioButton name="productSubscription" value={id} />
                  <RadioButtonLabel>{`${name} - $${price}`}</RadioButtonLabel>
                </RadioButtonWrapper>
                <Description>{description ? description : ''}</Description>
              </SubscriptionWrapper>
            ))}
            <SubmitButton type="submit" disabled={submitting} isLoading={submitting}>
              submit
            </SubmitButton>
            {submitFailed && <Error size="small">{submitErrors.error}</Error>}
          </FormContentWrapper>
        )}
      </Form>
    </>
  );
};

export default SubscriptionsForm;
