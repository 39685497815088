// libraries
import React from 'react';
import styled from 'styled-components';
import { FormRenderProps } from 'react-final-form';

// custom components
import Form from 'components/Form';
import Calendar from 'components/Calendar';
import Icon from 'components/Icon';
import TimeSlots from './TimeSlots';
import Select from 'components/Form/Select/Select';

// styled components
import { Title, Text } from 'shared/typography';
import { Flex, ColumnedFlex, SubmitButton, Error } from 'shared/styledComponents';
import { SelectStyles } from 'components/Form/Select/defaultSelectStyles';

// styles
import devices from 'theme/devices';
import colors from 'theme/colors';

// types
import { ConsultationTypes } from 'types';

// utils
import { getValue } from 'utils/getValue';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

//constants
import { TIME_ZONE_OPTIONS } from './constants';

const BlockHeading = styled(Title)`
  font-family: Wigrum-Medium;
  color: ${({ theme: { colors } }): string => colors.lightBrown};
  margin-right: 70px;

  @media ${devices.mobile} {
    margin-right: 0;
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 23px;
  }
`;

const BlockWrapper = styled.div`
  border-bottom: ${({ theme: { colors } }): string => `1px solid ${colors.alto}`};
  padding-bottom: 50px;
  margin-bottom: 45px;
`;

export const SubHeading = styled(Text)`
  margin-bottom: 30px;
`;

const DateAndCalendarWrapper = styled(ColumnedFlex)`
  position: relative;

  .DayPicker {
    position: static;
    max-width: 100%;
    margin-top: 20px;
    z-index: 1;
  }
  .DayPicker-NavButton--prev {
    left: 40px;
  }

  .DayPicker-NavButton--next {
    right: 40px;
  }

  .DayPicker-Months {
    justify-content: space-around;
  }
`;

const DateWrapper = styled(Flex)`
  align-items: center;
`;

const CalendarBlock = styled(Flex)`
  width: 100%;

  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const TimeSlotsBlock = styled(Flex)`
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const TimeZoneBlock = styled(TimeSlotsBlock)`
  align-items: center;
`;

const CalendarIcon = styled(Icon)<any>`
  cursor: pointer;
`;

interface AvailabilityProps {
  onSubmit: (values: any) => Promise<void>;
  formValidation: (values: any) => void;
  isTodaySelected: boolean;
  calendarModifiers: any;
  calendarModifiersStyles: any;
  calendarValues: Date[];
  disabledDays: any;
  onCalendarChange: (change: any, reset: any) => (day: Date, { selected }: any) => void;
  isPrevAvailabilitiesDateSelected: boolean;
  numberOfInPersonPrevTimeSlots: any;
  numberOfOnLinePrevTimeSlots: any;
  numberOfFreePrevTimeSlots: any;
}

const AvailabilityForm = ({
  onSubmit,
  formValidation,
  isTodaySelected,
  calendarModifiers,
  calendarModifiersStyles,
  calendarValues,
  disabledDays,
  onCalendarChange,
  isPrevAvailabilitiesDateSelected,
  numberOfInPersonPrevTimeSlots,
  numberOfOnLinePrevTimeSlots,
  numberOfFreePrevTimeSlots,
}: AvailabilityProps): JSX.Element => (
  <Form onSubmit={onSubmit} validate={formValidation}>
    {({
      form: { change, reset },
      submitting,
      submitFailed,
      submitErrors,
      hasValidationErrors,
      errors,
    }: FormRenderProps): JSX.Element => (
      <>
        <BlockWrapper>
          <SubHeading size="medium">
            Select available dates and time ranges below to book appointments directly through Robyn.
          </SubHeading>
          <CalendarBlock>
            <BlockHeading size="medium">Select Day/s</BlockHeading>
            <DateAndCalendarWrapper>
              <DateWrapper>
                <CalendarIcon type="calendar" width="32px" height="32px" />
              </DateWrapper>
              <Calendar
                selectedDays={calendarValues}
                onDayClick={onCalendarChange(change, reset)}
                disabledDays={disabledDays}
                numberOfMonths={2}
                modifiers={calendarModifiers}
                modifiersStyles={calendarModifiersStyles}
              />
            </DateAndCalendarWrapper>
          </CalendarBlock>
        </BlockWrapper>
        <BlockWrapper>
          <TimeZoneBlock>
            <BlockHeading size="medium">Time Zone</BlockHeading>
            <Select
              name="timeZone"
              options={TIME_ZONE_OPTIONS}
              customStyles={SelectStyles}
              dropdownIndicatorColor={colors.bismark}
              dropdownIndicatorWidth="15px"
              dropdownIndicatorHeight="9px"
            />
          </TimeZoneBlock>
        </BlockWrapper>
        <BlockWrapper>
          <TimeSlotsBlock>
            <BlockHeading size="medium">Time Slots</BlockHeading>
            <ColumnedFlex>
              <TimeSlots
                title="In Person (1-Hour)"
                consultationType={ConsultationTypes[1]}
                onFormchange={change}
                isTodaySelected={isTodaySelected}
                isPrevAvailabilitiesDateSelected={isPrevAvailabilitiesDateSelected}
                numberOfTimeSlots={numberOfInPersonPrevTimeSlots}
              />
              <TimeSlots
                title="Virtual (1-Hour)"
                consultationType={ConsultationTypes[2]}
                onFormchange={change}
                isTodaySelected={isTodaySelected}
                isPrevAvailabilitiesDateSelected={isPrevAvailabilitiesDateSelected}
                numberOfTimeSlots={numberOfOnLinePrevTimeSlots}
              />
              <TimeSlots
                title="Free Consultations (20-min)"
                consultationType={ConsultationTypes[4]}
                onFormchange={change}
                isTodaySelected={isTodaySelected}
                isPrevAvailabilitiesDateSelected={isPrevAvailabilitiesDateSelected}
                numberOfTimeSlots={numberOfFreePrevTimeSlots}
              />
            </ColumnedFlex>
          </TimeSlotsBlock>
        </BlockWrapper>
        {submitFailed && hasValidationErrors && (
          <Error size="small">
            {!!Object.keys(errors).length &&
              `${getValue(Object.keys(errors)[0], validationMessages)} ${errors[Object.keys(errors)[0]]}`}
          </Error>
        )}
        {submitFailed && submitErrors?.error && <Error size="small">{submitErrors?.error}</Error>}
        <SubmitButton type="submit" size="small" disabled={submitting} isLoading={submitting}>
          Submit
        </SubmitButton>
      </>
    )}
  </Form>
);

export default AvailabilityForm;
