// libraries
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import styled from 'styled-components';

// custom components
import PaymentStep from './PaymentStep';
import ManualBankAccountConnection from '../components/ManualBankAccountConnection';

// styled components
import { Flex, ColumnedFlex } from 'shared/styledComponents';
import { Heading, SubHeading, StyledIcon } from './CreatePaymentAccount';

// api
import api from '../api';

// services
import { getPlaidLinkToken } from '../services';

const Wrapper = styled(Flex)`
  width: 100%;
  max-width: 830px;
  justify-content: space-between;
`;

interface ConnectBankAccountProps {
  accessToken: string;
}

const ConnectBankAccount = ({ accessToken }: ConnectBankAccountProps): JSX.Element => {
  const [isBankAccountConnected, setIsBankAccountConnected] = useState(false);

  const [plaidLinkToken, setPlaidLinkToken] = useState('');

  const fetchLinkToken = useCallback(async () => {
    try {
      const plaidLinkToken = await getPlaidLinkToken(accessToken);

      setPlaidLinkToken(plaidLinkToken.data.linkToken);
    } catch (err) {
      console.log(err, 'error');
    }
  }, [accessToken]);

  useEffect(() => {
    fetchLinkToken();
  }, [accessToken, fetchLinkToken]);

  const onSuccess = useCallback(
    async (publikToken, metadata) => {
      try {
        await api.postBankAccount(publikToken, metadata.account_id, accessToken);

        setIsBankAccountConnected(true);
      } catch (err) {
        console.log(err, 'error');
      }
    },
    [accessToken],
  );

  const onExit = useCallback(
    async (err) => {
      if (err != null && err.error_code === 'INVALID_LINK_TOKEN') {
        fetchLinkToken();
      }

      if (err != null) {
        console.log(err.error_message);
      }
    },
    [fetchLinkToken],
  );

  const config: any = useMemo(
    () => ({
      token: plaidLinkToken,
      onSuccess,
      onExit,
    }),
    [onExit, onSuccess, plaidLinkToken],
  );

  const { open, ready, error } = usePlaidLink(config);

  if (error) {
    console.log(error, 'error');
  }

  const handleSubmitButtonClick = useCallback(() => {
    open();
  }, [open]);

  return !isBankAccountConnected ? (
    <Wrapper>
      <PaymentStep
        stepNumber="2"
        heading="Connect Account"
        subHeading="Then you need to connect your bank account."
        submitButtonName="Connect a bank account"
        onSubmitButtonClick={handleSubmitButtonClick}
        isSubmitButtonDisabled={!ready}
      />
      <ManualBankAccountConnection />
    </Wrapper>
  ) : (
    <Flex>
      <StyledIcon type="ListIcon" width="28px" height="28px" />
      <ColumnedFlex>
        <Heading size="medium">Connect Account</Heading>
        <SubHeading>Your bank account have been connected successfully</SubHeading>
      </ColumnedFlex>
    </Flex>
  );
};
export default ConnectBankAccount;
