// libraries
import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';

// custom components
import TimeSlots from './TimeSlots';

// constants
import { TIME_OPTIONS, timeSlotSelectTypes } from '../constants';

// utils
import { getConvertedTimeOptions } from './utils';

// types
import { TimeSlotOption, TimeSlot } from '../types';

interface TimeSlotsProps {
  title: string;
  consultationType: string | number;
  onFormchange: (name: string, value?: any) => void;
  isTodaySelected: boolean;
  isPrevAvailabilitiesDateSelected: boolean;
  numberOfTimeSlots?: any;
}

const TimeSlotsContainer = ({
  title,
  consultationType,
  onFormchange,
  isTodaySelected,
  isPrevAvailabilitiesDateSelected,
  numberOfTimeSlots,
}: TimeSlotsProps): JSX.Element => {
  const [timeSlots, setTimeSlots] = useState([
    {
      id: 0,
      timeOptionsFrom: getConvertedTimeOptions(0, TIME_OPTIONS, consultationType, true),
      timeOptionsTo: getConvertedTimeOptions(0, TIME_OPTIONS, consultationType, false),
      consultationType,
    },
  ]);

  const handleTimeSlotAdd = useCallback(() => {
    let timeOptionsFrom: any;
    let timeOptionsTo: any;

    if (isTodaySelected) {
      timeOptionsFrom = TIME_OPTIONS.filter(({ value }) => value > moment(new Date()).hour() + 1);
      timeOptionsTo = TIME_OPTIONS.filter(({ value }) => value > moment(new Date()).hour() + 2);
    } else {
      timeOptionsFrom = [...TIME_OPTIONS];
      timeOptionsTo = [...TIME_OPTIONS];
    }

    setTimeSlots((state: TimeSlot[]) => {
      if (state.length) {
        return [
          ...state,
          {
            id: state[state.length - 1].id + 1,
            timeOptionsFrom: getConvertedTimeOptions(
              state[state.length - 1].id + 1,
              timeOptionsFrom,
              consultationType,
              true,
            ),
            timeOptionsTo: getConvertedTimeOptions(
              state[state.length - 1].id + 1,
              timeOptionsTo,
              consultationType,
              false,
            ),
            consultationType,
          },
        ];
      } else {
        return [
          {
            id: 0,
            timeOptionsFrom: getConvertedTimeOptions(0, timeOptionsFrom, consultationType, true),
            timeOptionsTo: getConvertedTimeOptions(0, timeOptionsTo, consultationType, false),
            consultationType,
          },
        ];
      }
    });
  }, [consultationType, isTodaySelected]);

  useEffect(() => {
    if (!!numberOfTimeSlots.length) {
      numberOfTimeSlots.forEach(() => {
        handleTimeSlotAdd();
      });
    }

    if (numberOfTimeSlots.length === 0 && isPrevAvailabilitiesDateSelected) {
      setTimeSlots([
        {
          id: 0,
          timeOptionsFrom: getConvertedTimeOptions(0, TIME_OPTIONS, consultationType, true),
          timeOptionsTo: getConvertedTimeOptions(0, TIME_OPTIONS, consultationType, false),
          consultationType,
        },
      ]);
    }

    if (isTodaySelected) {
      setTimeSlots([
        {
          id: 0,
          timeOptionsFrom: getConvertedTimeOptions(
            0,
            TIME_OPTIONS.filter(({ value }) => value > moment(new Date()).hour() + 1),
            consultationType,
            true,
          ),
          timeOptionsTo: getConvertedTimeOptions(
            0,
            TIME_OPTIONS.filter(({ value }) => value > moment(new Date()).hour() + 2),
            consultationType,
            false,
          ),
          consultationType,
        },
      ]);
    }
  }, [consultationType, handleTimeSlotAdd, isPrevAvailabilitiesDateSelected, isTodaySelected, numberOfTimeSlots]);

  const { FROM, TO } = timeSlotSelectTypes;

  const handleTimeSlotDelete = useCallback(
    (id: number) => (): void => {
      setTimeSlots((state: TimeSlot[]) => state.filter((timeSlot: any) => timeSlot.id !== id));
      onFormchange(`${consultationType}-${FROM}-${id}`, undefined);
      onFormchange(`${consultationType}-${TO}-${id}`, undefined);
    },
    [onFormchange, consultationType, FROM, TO],
  );

  const handleSelectChange = useCallback(
    (id, type) => (selectedOption: TimeSlotOption): void => {
      setTimeSlots((state) =>
        state.map((slot: TimeSlot) =>
          slot.id === id
            ? {
                ...slot,
                [`timeOptions${type === FROM ? TO : FROM}`]: getConvertedTimeOptions(
                  id,
                  TIME_OPTIONS,
                  consultationType,
                  true,
                ).filter((defaultOption: TimeSlotOption) =>
                  type === FROM
                    ? defaultOption.value.time > selectedOption.value.time
                    : defaultOption.value.time < selectedOption.value.time,
                ),
              }
            : { ...slot },
        ),
      );
      onFormchange(`${consultationType}-${type}-${id}`, selectedOption);
    },
    [onFormchange, consultationType, FROM, TO],
  );

  return (
    <TimeSlots
      timeSlots={timeSlots}
      title={title}
      onTimeSlotAdd={handleTimeSlotAdd}
      onTimeSlotDelete={handleTimeSlotDelete}
      handleSelectChange={handleSelectChange}
    />
  );
};

export default TimeSlotsContainer;
