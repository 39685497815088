// libraries
import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

// custom components
import ClientDashboardLayout from './ClientDashboardLayout';

// selectors
import { selectUser } from 'modules/User/selectors';

interface ClientDashboardContainerProps {
  children: ReactNode;
}

const ClientDashboardContainer = ({ children }: ClientDashboardContainerProps): JSX.Element => {
  const { firstName, lastName } = useSelector(selectUser);

  return (
    <ClientDashboardLayout firstName={firstName} lastName={lastName}>
      {children}
    </ClientDashboardLayout>
  );
};

export default ClientDashboardContainer;
