// libraries
import { AxiosResponse } from 'axios';

// api
import apiRequest from 'api/restapi';

// types
import { LogInData } from 'modules/LogInOrCreateAccount/LogIn/types';

export const postLogIn = (data: LogInData): Promise<AxiosResponse<any>> => apiRequest.post('user/login', data);

export const postLogOut = (token: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('user/logout', undefined, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
