// libraries
import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

//custom components
import CreatePaymentAccount, { StepNumber, Heading, SubHeading, StyledIcon } from './components/CreatePaymentAccount';
import ConnectBankAccount from './components/ConnectBankAccount';

// styled components
import { ColumnedFlex, Flex, SubmitButton, PrimaryButton } from 'shared/styledComponents';

// services
import { getProviderPaymentInfo, createPaymentAccount, getPaymentVerifyAccountLink } from './services';

// selectors
import { selectUser } from 'modules/User/selectors';

// api
import api from './api';

const ConnectAccountWrapper = styled(Flex)`
  max-width: 420px;
  opacity: 0.5;
  cursor: not-allowed;
`;

const StyledConnectAccountSubmitButton = styled(SubmitButton)`
  cursor: not-allowed;
`;

const CreatePaymentAccountWrapper = styled.div`
  max-width: 420px;
  margin-bottom: 60px;
`;

const DisconnectAccountLink = styled(PrimaryButton)`
  width: fit-content;
  margin-top: 50px;
`;

const PaymentsContainer = (): JSX.Element => {
  const [providerPaymentInfo, setProviderPaymentInfo] = useState({
    stripeAccountId: undefined,
    payoutsEnabled: undefined,
    transfersEnabled: undefined,
  });

  const {
    token: { accessToken },
  } = useSelector(selectUser);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const providerPaymentInfo = await getProviderPaymentInfo(accessToken);

        setProviderPaymentInfo(providerPaymentInfo.data);
      } catch (err) {
        console.log(err, 'error');
      }
    })();
  }, [accessToken]);

  const handlePaymentAccountCreation = useCallback(async () => {
    try {
      const paymentAccount = await createPaymentAccount(accessToken);

      window.location.assign(paymentAccount.data.verificationLink);
    } catch (err) {
      console.log(err, 'error');
    }
  }, [accessToken]);

  const handlePaymentAccountVerification = useCallback(async () => {
    try {
      const verificationLink = await getPaymentVerifyAccountLink(accessToken);

      window.location.assign(verificationLink.data.verificationLink);
    } catch (err) {
      console.log(err, 'error');
    }
  }, [accessToken]);

  const handleDisconnectBankAccount = useCallback(async () => {
    try {
      await api.deleteBankAccount(accessToken);

      window.location.reload(false);
    } catch (err) {
      console.log(err, 'error');
    }
  }, [accessToken]);

  return (
    <ColumnedFlex>
      <CreatePaymentAccountWrapper>
        <CreatePaymentAccount
          providerPaymentInfo={providerPaymentInfo}
          onCreatePaymentAccountButtonClick={handlePaymentAccountCreation}
          onVerifyPaymentAccountButtonClick={handlePaymentAccountVerification}
        />
      </CreatePaymentAccountWrapper>

      {providerPaymentInfo.transfersEnabled === false ? (
        <ConnectAccountWrapper>
          <StepNumber>2</StepNumber>
          <ColumnedFlex>
            <Heading size="medium">Connect Account</Heading>
            <SubHeading>Then you need to connect your bank account.</SubHeading>
            <StyledConnectAccountSubmitButton disabled={true}>Connect a bank account</StyledConnectAccountSubmitButton>
          </ColumnedFlex>
        </ConnectAccountWrapper>
      ) : null}

      {!providerPaymentInfo.payoutsEnabled && providerPaymentInfo.transfersEnabled ? (
        <ConnectBankAccount accessToken={accessToken} />
      ) : null}

      {providerPaymentInfo.payoutsEnabled && (
        <Flex>
          <StyledIcon type="ListIcon" width="28px" height="28px" />
          <ColumnedFlex>
            <Heading size="medium">Connect Account</Heading>
            <SubHeading>Your bank account have been connected successfully</SubHeading>
          </ColumnedFlex>
        </Flex>
      )}

      {providerPaymentInfo.payoutsEnabled && (
        <DisconnectAccountLink onClick={handleDisconnectBankAccount}>Disconnect My Bank Account</DisconnectAccountLink>
      )}
    </ColumnedFlex>
  );
};
export default PaymentsContainer;
