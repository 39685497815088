import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// api
import api from '../api';

// selectors
import { selectUser } from 'modules/User/selectors';

// actions
import { setUserName } from 'modules/User/actions';

import EditProfile from './EditProfile';

// utils
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validate } from 'utils/validation';

const EditProfileContainer = () => {
  const {
    firstName,
    lastName,
    token: { accessToken },
  } = useSelector(selectUser);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => ({ firstName, lastName }), [firstName, lastName]);

  const validationSchema = useMemo(
    () => ({
      firstName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
      lastName: {
        [VALIDATION_TYPES.REQUIRED]: true,
      },
    }),
    [],
  );

  const formValidation = useCallback((values: any) => validate(values, validationSchema), [validationSchema]);

  const onSubmit = useCallback(
    async (values: any) => {
      const personalDetails = await api.postClientName(firstName, lastName, accessToken);

      if (personalDetails.data.result && values.firstName && values.lastName) {
        dispatch(setUserName({ firstName: values.firstName, lastName: values.lastName }));
      }
    },
    [accessToken, dispatch, firstName, lastName],
  );

  return <EditProfile initialValues={initialValues} onSubmit={onSubmit} formValidation={formValidation} />;
};

export default EditProfileContainer;
