//libraries
import React, { useState } from 'react';
import styled from 'styled-components';
import { Link as ReactRouterLink } from 'react-router-dom';

// styled components
import { Frame, Flex, ColumnedFlex, Error, SubmitButton, Link } from 'shared/styledComponents';
import devices from 'theme/devices';
import { H1, Text } from 'shared/typography';
import { TextStyles } from 'shared/styles';

// custom components
import Layout from 'components/Layout';
import Form from 'components/Form';
import Input from 'components/Form/Input';

// custom components
import Loader from 'components/Loader';
import Success from './Success';

// assets
import mainSectionImageSrc from 'assets/images/main-section.svg';

// types
import { VALIDATION_TYPES } from 'utils/validation/types';
import { validationMessages } from 'modules/LogInOrCreateAccount/CreateAccount/types';

// utils
import { validate } from 'utils/validation';
import { getValue } from 'utils/getValue';
import submitToGADataLayer from 'utils/submitToGADataLayer';

// api
import api from './api';

const MainSectionWrapper = styled(Flex)`
  background-color: ${({ theme: { colors } }): string => colors.secondary};
`;

const MainSection = styled(Frame)`
  position: relative;
  flex-direction: column;
  padding-top: 80px;
  min-height: calc(65vh);
  @media ${devices.mobile} {
    min-height: 500px;
    padding-top: 0;
  }
`;

const MainSectionTitle = styled(H1)`
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 20px;
  @media ${devices.mobile} {
    font-size: 24px;
    line-height: 32px;
  }
`;

const MainSectionImage = styled.img`
  position: absolute;
  width: 500px;
  top: -30px;
  right: -200px;

  @media ${devices.mobile} {
    bottom: 20px;
    width: 200px;
    top: inherit;
    right: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledInput = styled(Input)(
  ({ theme: { colors, fonts } }) => `
    ${TextStyles};
    width: 100%;
    margin-right: 0px;
    padding: 30px 20px;
    border-radius: 0px;
    border: none;
    font-size: 16px;
    background-color: ${colors.white};
    color: ${colors.bismark};
    outline: none;
    font-family: ${fonts.wigrumLight};
    ::placeholder {
      color: ${colors.primary};
      font-family: ${fonts.wigrumLight};
    }

    :last-child {
      margin-right: 0;
      margin-left: 20px;
    }

    @media ${devices.mobile} {
      max-width: 310px;
      margin-bottom: 15px;
      margin-right: 0;

      :last-of-type {
        margin-bottom: 0;
        margin-left: 0;
      }
    }
  `,
);

const StyledButton = styled(SubmitButton)`
  min-width: 200px;
  justify-self: flex-end;
`;

const StyledFlex = styled(Flex)`
  justify-content: space-between;
  @media ${devices.mobile} {
    flex-direction: column;
  }
`;

const DisclaimerText = styled(Text)`
  margin-top: 8px;
  font-size: 14px;
`;

const LinkItem = styled(Link)`
  font-size: 14px;
  text-decoration: underline;
`;

const initialValues = {
  email: '',
};

const validationSchema = {
  email: {
    [VALIDATION_TYPES.REQUIRED]: true,
    [VALIDATION_TYPES.EMAIL]: true,
  },
};

const formValidation = (values: any) => validate(values, validationSchema);

const Subscribe = (): JSX.Element => {
  const [isSuccess, setIsSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: any) => {
    setIsLoading(true);
    const { email } = values;
    try {
      await api.subscribeUserToNewsletter(email, 'https://wearerobyn.co/subscribe');

      submitToGADataLayer('Newsletter Signup');
      setIsSuccess(true);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout isContactFormShown={false}>
      <ColumnedFlex>
        <MainSectionWrapper>
          <MainSection>
            <Flex>
              {isSuccess && !isLoading ? (
                <Success />
              ) : (
                <ColumnedFlex>
                  <MainSectionTitle>Sign up to receive the latest in parental wellness.</MainSectionTitle>
                  <Form onSubmit={handleSubmit} initialValues={initialValues} validate={formValidation}>
                    {({ submitFailed, hasValidationErrors, submitErrors, errors }): JSX.Element => (
                      <>
                        <StyledFlex>
                          {isLoading ? (
                            <Loader />
                          ) : (
                            <StyledInput name="email" type="text" placeholder="Enter your email" size="small" />
                          )}

                          <StyledButton size="small" type="submit">
                            Submit
                          </StyledButton>
                        </StyledFlex>
                        {submitFailed && hasValidationErrors && (
                          <Error size="small">
                            {!!Object.keys(errors).length &&
                              `${getValue(Object.keys(errors)[0], validationMessages)} ${
                                errors[Object.keys(errors)[0]]
                              }`}
                          </Error>
                        )}
                      </>
                    )}
                  </Form>
                  <DisclaimerText>
                    By providing your email address you agree to our&nbsp;
                    <LinkItem as={ReactRouterLink} to={'/privacy-policy'}>
                      Privacy Policy
                    </LinkItem>
                    &nbsp;and{' '}
                    <LinkItem as={ReactRouterLink} to={'/terms-of-use'}>
                      Terms of Service.
                    </LinkItem>
                  </DisclaimerText>
                </ColumnedFlex>
              )}
              <MainSectionImage data-src={mainSectionImageSrc} alt="main section" className="lazyload" />
            </Flex>
          </MainSection>
        </MainSectionWrapper>
      </ColumnedFlex>
    </Layout>
  );
};

export default Subscribe;
