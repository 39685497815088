// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const postHipaaElegibility = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/hipaa-elegibility', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { postHipaaElegibility };
