/* eslint-disable @typescript-eslint/ban-ts-ignore */
/* eslint-disable @typescript-eslint/camelcase */

// libraries
import React, { useState, useEffect, useCallback } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from 'react-router';
import styled from 'styled-components';

// custom components
import Icon from 'components/Icon';

// styled componentns
import {
  Flex,
  Frame,
  PrimaryButton,
  SecondaryButton,
  Link,
  CentredFlex,
  DropdownButton,
} from 'shared/styledComponents';
import { Text, Title } from 'shared/typography';

// styles
import { ButtonBaseStyles, TitleStyles } from 'shared/styles';
import devices from 'theme/devices';

// types
import { ProviderCriteria } from 'modules/ProviderCriteria/types';

// constants
import { UserRoles } from 'constants/user';

const StyledMenu = styled.div<any>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  padding-top: 60px;
  padding-bottom: 40px;
  text-align: left;
  background: ${({ theme: { colors } }): string => colors.secondary};
  transform: ${({ isMenuOpened }: any): any => (isMenuOpened ? 'translateX(0)' : 'translateX(-100%)')};
  z-index: 3;
  transition: transform 0.3s ease-in-out;
`;

const HeadingWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ListPracticeButton = styled(PrimaryButton)(
  ({ theme: { colors }, isMenuOpened }) => `
    padding: 13px 16px;
    background-color: ${colors.transparent};
    border: 2px solid ${colors.primary};
    color:${colors.primary};

    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'inline-block' : 'none'};
      margin-left: ${isMenuOpened ? '0px' : '10px'};
      font-size: 10px !important;
      line-height: 13px;
      white-space: nowrap;
    }
  `,
);

const BookButton = styled(PrimaryButton)`
  margin-left: 30px;

  @media ${devices.mobile} {
    margin-left: 10px;
    padding: 15px 16px;
    font-size: 10px;
    line-height: 13px;
    white-space: nowrap;
  }
`;

const StyledIcon = styled(Icon)<any>`
  display: ${({ isMenuOpened }): any => (isMenuOpened ? 'inline-block' : 'none')};
  margin-left: 20px;
  cursor: pointer;

  @media ${devices.mobile} {
    display: inline-block;
  }
`;

const SignBlock = styled.div<any>(
  ({ isMenuOpened, theme: { colors } }) => `
  display: flex;
  
  @media ${devices.mobile} {
    ${!isMenuOpened ? 'display: none;' : ''};
    padding: 25px 0 16px;
    border-bottom: 1px solid ${colors.mercury};
  }
`,
);

const StyledSeparator = styled.span<any>`
  ${TitleStyles};
  user-select: none;
`;

const LogOutButton = styled(SecondaryButton)<any>`
  ${TitleStyles};
  letter-spacing: 0;
  text-transform: capitalize;
  cursor: pointer;
`;

const LogInOrSignUpButton = styled(Title)<any>(
  ({ theme: { colors }, isMenuOpened }) => `
    ${ButtonBaseStyles};
    background-color: ${colors.transparent};

    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'block' : 'none'};
      margin-top: ${isMenuOpened ? '25px' : '0'};
    }
  `,
);

const NavigationBlock = styled(Flex)<any>(
  ({ isMenuOpened }) => `
    justify-content: space-between;
    width: 100%;
    max-width: 365px;
    margin-top: 50px;

    & > a, div:first-child {
        margin-bottom: 40px
    } 

    @media ${devices.mobile} {
      display: ${isMenuOpened ? 'flex' : 'none'};
      flex-direction: column;
      align-items: start;
      justify-content: start;
      min-height: 200px;
    }
  `,
);

const ServicesWrapper = styled.div``;

const ButtonWrapper = styled(CentredFlex)<any>(
  ({ servicesListHeight }) => `
    height: ${servicesListHeight ? servicesListHeight + 'px' : 'fit-content'};
    cursor: pointer;
    justify-content: start;
  `,
);

const DropdownIcon = styled(Icon)`
  margin-left: 10px;
`;

const ListItem = styled(Text)<any>`
  list-style-type: none;
  margin-bottom: 15px;
  color: ${({ theme: { colors } }): string => colors.primary};
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const ServicesList = styled.ul<any>`
  padding-top: 45px;
  padding-left: 55px;
`;

interface BurgerMenuProps {
  services: ProviderCriteria[];
  isUserLoggedIn: boolean;
  userRole: string;
  userFirstName: string;
  userLastName: string;
  onModalOpen: (title: string, data?: object | undefined) => () => void;
  onLogOut: VoidFunction;
  onMenuClose: VoidFunction;
  isMenuOpened: boolean;
  istransparent?: boolean;
}

const BurgerMenu = ({
  isMenuOpened,
  onMenuClose,
  isUserLoggedIn,
  userRole,
  userFirstName,
  userLastName,
  onLogOut,
  onModalOpen,
  services,
  istransparent,
}: BurgerMenuProps): any => {
  const [isServicesDropdownOpened, setIsServicesDropdownOpened] = useState(false);
  document.body.style.overflow = 'hidden';

  const history = useHistory();

  const handleRedirect = useCallback(
    (url: string) => () => {
      history.push(url);

      onMenuClose();
    },
    [history, onMenuClose],
  );

  useEffect(
    (): any => (): any => {
      document.body.style.overflow = '';
    },
    [],
  );
  return (
    <StyledMenu isMenuOpened={isMenuOpened as any}>
      <Frame>
        <HeadingWrapper>
          <Flex>
            <ListPracticeButton
              as={HashLink}
              to="#listYourPractice"
              size="small"
              smooth
              onClick={() => {
                onMenuClose();
                // @ts-ignore
                return gtag('event', 'List Practice Click', {
                  event_category: 'Header',
                  event_label: 'Button Click',
                });
              }}
              isMenuOpened
            >
              List Your Practice
            </ListPracticeButton>
            <BookButton as={ReactRouterLink} to="/find-providers" size="small">
              Book Now
            </BookButton>
          </Flex>
          <StyledIcon type="openedBurgerMenu" isMenuOpened={isMenuOpened} onClick={onMenuClose} />
        </HeadingWrapper>

        <SignBlock isMenuOpened={isMenuOpened}>
          {isUserLoggedIn ? (
            <>
              {userRole === UserRoles.PROVIDER_APPLICANT && (
                <Title size="small">
                  {userFirstName} {userLastName}
                </Title>
              )}
              {userRole === UserRoles.PROVIDER_WITHOUT_SUBSCRIPTION && (
                <Link as={ReactRouterLink} to={'/provider/dashboard/settings'} size="small">
                  My Dashboard
                </Link>
              )}
              {(userRole === UserRoles.PROVIDER_BASIC || userRole === UserRoles.PROVIDER_PRO) && (
                <Link as={ReactRouterLink} to={'/provider/dashboard/edit-profile'} size="small">
                  My Dashboard
                </Link>
              )}
              {userRole === UserRoles.CLIENT && (
                <Link as={ReactRouterLink} to={'/client/dashboard/personal-details'} size="small">
                  My profile
                </Link>
              )}
              <StyledSeparator size="small">&nbsp;/&nbsp;</StyledSeparator>
              <LogOutButton size="small" onClick={onLogOut} isMenuOpened={true}>
                Log Out
              </LogOutButton>
            </>
          ) : (
            <LogInOrSignUpButton
              isMenuOpened={isMenuOpened}
              as="button"
              size="small"
              onClick={onModalOpen('logInOrCreateAccount')}
            >
              Log In / Sign Up
            </LogInOrSignUpButton>
          )}
        </SignBlock>

        <NavigationBlock isMenuOpened isServicesDropdownOpened={isServicesDropdownOpened}>
          <Link href="https://www.parentbirth.com/" target="_blank" size="small" iswhitecolor={istransparent ? 1 : 0}>
            Virtual Doula
          </Link>
          <Link
            href="https://outreach.fertilityoutloud.com"
            target="_blank"
            size="small"
            iswhitecolor={istransparent ? 1 : 0}
          >
            Fertility Outreach
          </Link>
          <Link as={ReactRouterLink} to="/journeys" size="small">
            Real Stories
          </Link>
          <Link as={ReactRouterLink} to="/learn" size="small">
            Articles
          </Link>
          <Link as={ReactRouterLink} to="/classes" size="small">
            Classes
          </Link>
          <ServicesWrapper>
            <ButtonWrapper onClick={() => setIsServicesDropdownOpened(!isServicesDropdownOpened)}>
              <DropdownButton size="small">Services</DropdownButton>
              <DropdownIcon type="downArrow" width="11px" height="7px" />
            </ButtonWrapper>
            {isServicesDropdownOpened && (
              <ServicesList>
                {services.map(({ id, value }) => (
                  <ListItem key={id} onClick={handleRedirect(`/service/${value.split(' ').join('-').toLowerCase()}`)}>
                    {value}
                  </ListItem>
                ))}
              </ServicesList>
            )}
          </ServicesWrapper>
          <Link as={ReactRouterLink} to="/for-providers" size="small">
            For Providers
          </Link>
        </NavigationBlock>
      </Frame>
    </StyledMenu>
  );
};

export default BurgerMenu;
