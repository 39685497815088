// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const postUploadProviderImage = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('provider/upload-image', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const getProviderImage = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.get('provider/image', {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { postUploadProviderImage, getProviderImage };
