// api
import apiRequest from 'api/restapi';
import { AxiosResponse } from 'axios';

const getDocusignLink = (userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('docusign/initiate-signing', undefined, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

const postDocusignLink = (data: any, userToken: string): Promise<AxiosResponse<any>> =>
  apiRequest.post('docusign/process-embedded-signing', data, {
    headers: {
      Authorization: `Bearer ${userToken}`,
    },
  });

export default { getDocusignLink, postDocusignLink };
