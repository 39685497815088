// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import AppointmentsLayout from './AppointmentsLayout';

// styled components
import { Title } from 'shared/typography';

// modules
import Availability from 'modules/Dashboard/ProviderDashboard/Appointments/Availability';

// hooks
import useResize from 'hooks/useResize';

const Heading = styled(Title)`
  margin-bottom: 50px;
`;

const AvailabilityPage = (): JSX.Element => {
  const { isDesktop } = useResize();

  return (
    <ProviderDashboardLayout>
      <AppointmentsLayout>
        {isDesktop && <Heading>Input Availability</Heading>}
        <Availability />
      </AppointmentsLayout>
    </ProviderDashboardLayout>
  );
};

export default AvailabilityPage;
