// libraries
import React from 'react';
import styled from 'styled-components';

// custom components
import ProviderDashboardLayout from '../../ProviderDashboardLayout';
import AppointmentsLayout from './AppointmentsLayout';

// modules
import Payments from 'modules/Dashboard/ProviderDashboard/Appointments/Payments';

// styled components
import { Title, Text } from 'shared/typography';

// hooks
import useResize from 'hooks/useResize';

const Heading = styled(Title)`
  margin-bottom: 10px;
`;

const SubHeading = styled(Text)`
  margin-bottom: 60px;
`;

const PaymentInfoPage = (): JSX.Element => {
  const { isDesktop } = useResize();

  return (
    <ProviderDashboardLayout>
      <AppointmentsLayout>
        {isDesktop && <Heading>Payment Info</Heading>}
        <SubHeading>In order for you to receive money for consultations, you need to take two steps.</SubHeading>
        <Payments />
      </AppointmentsLayout>
    </ProviderDashboardLayout>
  );
};

export default PaymentInfoPage;
