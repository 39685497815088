// libraries
import { useState, useEffect } from 'react';

// api
import api from 'api/termsContent';

// CONSTANTS
import { Terms } from 'constants/terms';

interface UseTerms {
  termsOfUse: any;
  privacyPolicy: any;
  providerAgreement: any;
  hipaaAuthorization: any;
  nonCoveredEntityCertification: any;
  dmcaCopyrightPolicy: any;
}

interface RequestedDocuments {
  isPrivacyPolicy?: boolean;
  isTermsOfUse?: boolean;
  isProviderAgreement?: boolean;
  isHipaaAuthorization?: boolean;
  isNonCoveredEntityCertification?: boolean;
  isDmcaCopyrightPolicy?: boolean;
}

const useTerms = (
  {
    isPrivacyPolicy,
    isTermsOfUse,
    isProviderAgreement,
    isHipaaAuthorization,
    isNonCoveredEntityCertification,
    isDmcaCopyrightPolicy,
  }: RequestedDocuments,
  onlyMetadata?: boolean,
): UseTerms => {
  const [termsOfUse, setTermsOfUse] = useState([]);

  const [privacyPolicy, setPrivacyPolicy] = useState([]);

  const [providerAgreement, setProviderAgreement] = useState([]);

  const [hipaaAuthorization, setHipaaAuthorization] = useState([]);

  const [nonCoveredEntityCertification, setNonCoveredEntityCertification] = useState([]);

  const [dmcaCopyrightPolicy, setDmcaCopyrightPolicy] = useState([]);

  useEffect(() => {
    (async (): Promise<any> => {
      try {
        if (isTermsOfUse) {
          const istermsOfUse = await api.getTermsContent(Terms.TERMS_OF_USE, onlyMetadata);

          istermsOfUse.data && setTermsOfUse(istermsOfUse.data[0]);
        }
        if (isPrivacyPolicy) {
          const privacyPolicy = await api.getTermsContent(Terms.PRIVACY_POLICY, onlyMetadata);

          privacyPolicy.data && setPrivacyPolicy(privacyPolicy.data[0]);
        }
        if (isProviderAgreement) {
          const providerAgreement = await api.getTermsContent(Terms.PROVIDER_AGREEMENT, onlyMetadata);

          providerAgreement.data && setProviderAgreement(providerAgreement.data[0]);
        }
        if (isHipaaAuthorization) {
          const hipaaAuthorization = await api.getTermsContent(Terms.HIPAA_AUTHORIZATION, onlyMetadata);

          hipaaAuthorization.data && setHipaaAuthorization(hipaaAuthorization.data[0]);
        }
        if (isNonCoveredEntityCertification) {
          const nonCoveredEntityCertification = await api.getTermsContent(
            Terms.NON_COVERED_ENTITY_CERTIFICATION,
            onlyMetadata,
          );

          nonCoveredEntityCertification.data && setNonCoveredEntityCertification(nonCoveredEntityCertification.data[0]);
        }
        if (isDmcaCopyrightPolicy) {
          const dmcaCopyrightPolicy = await api.getTermsContent(Terms.DMCA_COPYRIGHT_POLICY, onlyMetadata);

          dmcaCopyrightPolicy.data && setDmcaCopyrightPolicy(dmcaCopyrightPolicy.data[0]);
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, [
    isPrivacyPolicy,
    isTermsOfUse,
    isProviderAgreement,
    isHipaaAuthorization,
    isNonCoveredEntityCertification,
    isDmcaCopyrightPolicy,
    onlyMetadata,
  ]);

  return {
    termsOfUse,
    privacyPolicy,
    providerAgreement,
    hipaaAuthorization,
    nonCoveredEntityCertification,
    dmcaCopyrightPolicy,
  };
};

export default useTerms;
