// libraries
import { handleActions } from 'redux-actions';

// actions
import { setPagination, clearPagination } from './actions';

// constants
import { PROVIDERS_AMOUNT } from './constants';

const defaultState = {
  paginationAmount: PROVIDERS_AMOUNT,
  paginationScrollTop: 0,
};

const paginationReducer = handleActions(
  {
    [setPagination.toString()]: (state, { payload }: any) => ({ ...payload }),
    [clearPagination.toString()]: () => defaultState,
  },
  defaultState,
);

export default paginationReducer;
