// libraries
import React, { ReactNode, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

// custom components
import ProviderDashboardLayout from './ProviderDashboardLayout';

// selectors
import { selectUser } from 'modules/User/selectors';

// hooks
import useResize from 'hooks/useResize';

// api
import api from 'pages/ProviderDashboard/ProviderDashboardLayout/api';

// actions
import { setUserImage } from 'modules/User/actions';

const SCROLL_ARROW_STEP = 150;

interface ProviderDashboardContainerProps {
  children: ReactNode;
}

const ProviderDashboardContainer = ({ children }: ProviderDashboardContainerProps): JSX.Element => {
  const {
    firstName,
    lastName,
    slug,
    role,
    token: { accessToken },
    image,
  } = useSelector(selectUser);

  const history = useHistory();

  const dispatch = useDispatch();

  const { isDesktop } = useResize();

  const handleProButtonClick = useCallback(() => {
    history.push('/provider/dashboard/settings');
  }, [history]);

  const handleRightArrowClick = useCallback(() => {
    const navigationBlock = document.getElementById('navigation-block') as any;
    navigationBlock.scrollTo({
      behavior: 'smooth',
      left: navigationBlock.scrollLeft + SCROLL_ARROW_STEP,
    });
  }, []);

  const handlLeftArrowClick = useCallback(() => {
    const navigationBlock = document.getElementById('navigation-block') as any;

    navigationBlock.scrollTo({
      behavior: 'smooth',
      left: navigationBlock.scrollLeft - SCROLL_ARROW_STEP,
    });
  }, []);

  useEffect(() => {
    !image &&
      (async (): Promise<any> => {
        try {
          const image = await api.getProviderImage(accessToken);

          dispatch(setUserImage(image.data.url));
        } catch (err) {
          console.log(err);
        }
      })();

    const activeLink = document.getElementsByClassName('active')[0];

    const elementOffsetLeft = document.getElementById(activeLink.id)?.offsetLeft || 0;
    const navigationBlock = document.getElementById('navigation-block') as any;

    navigationBlock.scrollTo({
      behavior: 'smooth',
      left: elementOffsetLeft - 320,
    });
  }, [accessToken, dispatch, image]);

  return (
    <ProviderDashboardLayout
      firstName={firstName}
      lastName={lastName}
      slug={slug}
      role={role}
      onProButtonClick={handleProButtonClick}
      handleRightArrowClick={handleRightArrowClick}
      handlLeftArrowClick={handlLeftArrowClick}
      isDesktop={isDesktop}
      providerImage={image}
    >
      {children}
    </ProviderDashboardLayout>
  );
};

export default ProviderDashboardContainer;
