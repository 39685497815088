// libraries
import React from 'react';

// custom components
import ProviderDashboardLayout from '../ProviderDashboardLayout';

// modules
import PrivateProviderNetwork from 'modules/Dashboard/ProviderDashboard/PrivateProviderNetwork';

const PrivateProviderNetworkPage = (): JSX.Element => (
  <ProviderDashboardLayout>
    <PrivateProviderNetwork />
  </ProviderDashboardLayout>
);

export default PrivateProviderNetworkPage;
